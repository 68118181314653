import "core-js";
import "regenerator-runtime/runtime";

// Useful to debug setTimeouts and setIntervals
// require("./utils/debug/timeouts.js");

require("./vendor/object-assign-polyfill");
require("./lib/jquery_extensions/placeholder");
require("./lib/jquery_extensions/initials");

require("./utils/grammarly_bugsnag_fix");

require("./views/modal");
const StickiesRouter = require("./router");

require("../css/application.less");

window.$ = $;
window.stickies = {
  models: require("./models"),
  views: require("./views"),
  utils: require("./utils"),
  pages: require("./pages"),
  Handler: require("./handler"),
  Router: require("./router"),
};

$(function() {
  window.router = new StickiesRouter();
  Backbone.history.start({ pushState: true });
});
