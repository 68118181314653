/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
require("../lib/jquery_extensions/droppable");
require("../lib/jquery_extensions/hammer");

const async = require("async");

const Base = require("./base");
const Group = require("./group");
const SheetState = require("./sheet_state");
const Modal = require("./modal");
const SheetClipboardDataProcessor = require("./sheet_clipboard_data_processor");
const Flash = require("./flash");

class Sheet extends Base {
  get className() {
    return "sheet";
  }

  get clipboardEventAnchorName() {
    return "clipboard-event-anchor";
  }

  get nameDecorated() {
    return false;
  }

  get template() {
    return _.template(`
      <div class="sheet-state"></div>
      <div class="sheet-flash">
        <div class="sheet-flash__icon svg-icon-check-circle"></div>
        <div class="sheet-flash__message"></div>
      </div>
      <textarea id="${this.clipboardEventAnchorName}" />
      <div class="children"></div>
    `);
  }

  get events() {
    // Base.js merges touchEvents into events. So if `events` doesn't return a
    // consistent reference, the touch events are lost.
    this._events = this._events || {
      click: "hiDeselect",
      dblclick: "hiRequestNewCard",
    };
    return this._events;
  }

  get touchEvents() {
    this._touchEvents = this._touchEvents || {
      doubletap: "hiRequestNewCard",
    };
    return this._touchEvents;
  }

  attributes() {
    return {
      id: `sheet-${this.model.id}`,
      "data-id": this.model.id,
    };
  }

  initialize(attributes) {
    super.initialize(attributes);
    this.listenTo(this, "attach", this.onAttach, this);

    this.hiCopy = this.hiCopy.bind(this);
    this.hiPaste = this.hiPaste.bind(this);

    this._childViews = [];
  }

  finalize() {
    // Deselect cards on this sheet before moving to another one.
    this.model.deselectAll();
    // Cleanup
    this.undelegateEvents();
    this.stopListening();
  }

  startListenting() {
    this.listenTo(this.model, "change:displayState", this.updateDisplayState, this);
    this.listenTo(this.model, "change:background", this.updateBackground, this);
    this.listenTo(this.model.groups(), "add", this.addGroup, this);
    this.listenTo(this.model.groups(), "change:deleted", this.softDeleteGroup, this);
    document.addEventListener("copy", this.hiCopy);
    document.addEventListener("paste", this.hiPaste);
  }

  stopListening() {
    super.stopListening.call(this);
    document.removeEventListener("copy", this.hiCopy);
    document.removeEventListener("paste", this.hiPaste);
  }

  onAttach() {
    this.render();
    this.initializeGroups();
    this.initializeDroppable();
    this.initializeSheetClipboardDataProcessor();
    this.startListenting();
  }

  dispose() {
    if (this.flashView) {
      this.flashView.remove();
    }
    this.remove();
    this._disposeChildViews();
  }

  initializeGroups() {
    const threshold = 50;
    const total = this.model.groups().reduce((total, group) => total + group.cards().length, 0);
    if (total < threshold) {
      this.model.groups().each(group => this.addGroup(group), this);
      this.model.trigger("rendered");
      return;
    }

    let count = 0;
    this.$el.addClass("loading");

    const loadingTemplate = _.template("Loading cards... (<%- count %>/<%- total %>)");

    const modal = new Modal({
      title: "Loading Sheet",
      closeAction() {
        return false;
      },
    });

    const updateMessage = () => {
      return modal.$(".message").html(loadingTemplate({ count, total }));
    };

    const groupFn = group => {
      return next => {
        const addGroupToBoard = () => {
          this.addGroup(group);
          count += group.cards().length;
          updateMessage();
          next();
        };

        setTimeout(addGroupToBoard, 0);
      };
    };

    return async.series(this.model.groups().map(groupFn), () => {
      modal.remove();
      this.$el.removeClass("loading");
      this.model.trigger("rendered");
    });
  }

  initializeDroppable() {
    this.$el.droppable({
      getZ() {
        return 0;
      },
      onDrop: (mouseEvent, target) => {
        const id = $(target).attr("data-id");
        if ($(target).is(".card")) {
          this.model.dropCard(id);
        }
        if ($(target).is(".group")) {
          this.model.dropGroup(id);
        }
      },
    });
  }

  /*
      render
  */

  render() {
    this.$el.html(this.template());
    this.$el.hammer();
    this.flashView = new Flash({
      duration: 3500,
      el: this.$el.find(".sheet-flash")[0],
      messageSelector: ".sheet-flash__message",
    });

    new SheetState({ model: this.model });

    this.updateDisplayState(this.model, this.model.get("displayState"));
    this.updateBackground(this.model, this.model.get("background"));
  }

  updateDisplayState(sheet, displayState, _options) {
    this.$el.removeClass("sheet--has-state");
    this.$el.removeClassMatching(/sheet--state-\w*/);
    if (displayState && displayState !== "normal") {
      this.$el.addClass("sheet--has-state");
      this.$el.addClass(`sheet--state-${displayState}`);
    }
  }

  updateBackground(sheet, background, _options) {
    this.$el.removeClass((i, clas) =>
      clas
        .split(/\s/g)
        .filter(c => /^background-/.test(c))
        .join(",")
    );
    if (background != null) {
      this.$el.addClass(`background-${background}`);
    }
  }

  addGroup(group) {
    group.set("sheet", this.model, { silent: true });
    if (group.softDeleted()) {
      return;
    }
    if (this._findChildView(group)) {
      return;
    }
    const groupView = new Group({ model: group });
    this._addChildView(groupView);
  }

  softDeleteGroup(group, deleted, _options) {
    if (!deleted) {
      return;
    }
    this._disposeChildView(group);
  }

  /*
      human interaction event handlers
  */

  hiRequestNewCard(e) {
    let docX, docY;
    const event = e.pageX != null ? e : e.gesture.srcEvent;
    if (!$(event.target).hasClass("sheet")) {
      return;
    }
    if (e.gesture) {
      e.preventDefault();
    }

    if (e.gesture) {
      docX = e.gesture.center.x + $(document).scrollLeft();
      docY = e.gesture.center.y + $(document).scrollTop();
    } else {
      docX = e.pageX;
      docY = e.pageY;
    }

    const sheetOffset = this.$el.offset();

    if (!docX || !docY || !sheetOffset || !sheetOffset.left || !sheetOffset.top) {
      Bugsnag.notify("Group create error", "Bad x,y value during new group create", {
        event_data: { event: $.extend(true, {}, e), docX, docY, sheetOffset },
      });
    }

    const group = this.model.createGroup({
      x: (docX != null ? docX : 100) - (sheetOffset.left != null ? sheetOffset.left : 0),
      y: (docY != null ? docY : 100) - (sheetOffset.top != null ? sheetOffset.top : 0),
    });

    this.model.focusCard(group.lastCard());
  }

  hiCopy(e) {
    const a = document.activeElement;

    // If we're in text-copying mode (i.e. have selected some text in an INPUT
    // or TEXTAREA), clear whatever previously lived in the text/stickies
    // namespace and eject. Let the browser's default clipboard behavior take
    // over.
    if (a && typeof a.selectionStart !== "undefined" && typeof a.selectionEnd !== "undefined") {
      e.clipboardData.clearData("text/stickies");
      return;
    }

    e.preventDefault();

    this.flashView.success("Copied!");

    // If the user has not selected any text and the user has not selected
    // any stickies, there there isn't anything for us to send to the
    // clipboard.
    const items = this.model.selectedItems();
    if (!items.length) {
      e.clipboardData.clearData("text/stickies");
      return;
    }

    // Serialize the currently-selected stickies into the text/stickies
    // namespace.
    const serialized = this.clipboardEventProcessor.copyItems(items);
    e.clipboardData.setData("text/stickies", serialized);
  }

  hiPaste(e) {
    const serialized = e.clipboardData.getData("text/stickies");
    if (serialized) {
      e.preventDefault();
      this.model.deselectAll();
      this.clipboardEventProcessor.pasteItems(serialized);
    }
  }

  hiDeselect(event) {
    if (!$(event.target).hasClass("sheet")) {
      return;
    }

    this.model.deselectAll();
  }

  initializeSheetClipboardDataProcessor() {
    this.clipboardEventProcessor = new SheetClipboardDataProcessor(this);
  }

  _addChildView(view) {
    this._childViews.push(view);
    this.$el.find("> .children").append(view.el);
    view.trigger("attach");
  }

  _disposeChildView(model) {
    const view = this._findChildView(model);
    if (!view) {
      return false;
    }
    this._childViews.splice(this._childViews.indexOf(view), 1);
    view.dispose();
  }

  _disposeChildViews() {
    _(this._childViews).each(v => v.dispose());
    this.childViews = [];
  }

  _findChildView(model) {
    return _(this._childViews).find(v => v.model === model);
  }
}

module.exports = Sheet;
