/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$.fn.textMetrics = function() {
  let html;
  if ($(this).is("input" || $(this).is("textarea"))) {
    html = $(this).val();
    if (html === "") {
      html = $(this).attr("placeholder");
    }
  } else {
    html = $(this).html();
  }
  if (html == null) {
    html = "";
  }
  const $div = $("<div></div>")
    .text(html)
    .css({ position: "absolute", left: -1000, top: -1000 })
    .appendTo($("body"));
  const styles = ["font-size", "font-style", "font-weight", "font-family", "line-height", "text-transform", "letter-spacing"];
  for (const style of Array.from(styles)) {
    $div.css(style, $(this).css(style));
  }
  const metrics = { height: $div.innerHeight(), width: $div.innerWidth() };
  $div.remove();
  return metrics;
};

$.fn.adjustWidth = function() {
  if ($(this).is(":focus")) {
    return;
  }
  let width = $(this).textMetrics().width + 3;
  width = Math.min(width, $(this).data("maxWidth"));
  width = Math.max(width, $(this).data("minWidth"));
  return $(this).css("width", width);
};

$.fn.trimInput = function(minWidth) {
  return this.each(function() {
    const maxWidth = $(this).innerWidth();

    $(this).data("maxWidth", maxWidth);
    $(this).data("minWidth", minWidth);

    if (!$(this).is(":focus")) {
      $(this).adjustWidth();
    }

    $(this).blur(() => {
      return $(this).adjustWidth();
    });

    return $(this).focus(() => {
      $(this).css("width", "");
      return $(this).data("maxWidth", $(this).innerWidth());
    });
  });
};
