/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _ = require("underscore");
const TooltipOnboardingFlow = require("./tooltip_onboarding_flow");

class AddCollaboratorsFlow extends TooltipOnboardingFlow {
  static get flowName() {
    return "add collaborators";
  }

  get className() {
    return "onboarding__tooltip";
  }

  get body() {
    return _.template(`\
      <div class="guide-tooltip guide-tooltip--low-left">
        <div class='wrapper'>
            <div class='message'>Add collaborators here.</div>
            <button class='dismiss button button--primary'>Will do, thanks!</button>
        </div>
      </div>\
    `);
  }

  _positionDot() {
    return this._offsetFromParent(35, 73);
  }

  _positionTooltip() {
    return this._offsetFromParent(-50, 103);
  }

  _renderWithTooltip() {
    this.$el.html(this.body());
    return this._positionTooltip();
  }

  _parent() {
    return $(".presence-bar__manage-collaborators");
  }

  flowName() {
    return AddCollaboratorsFlow.flowName;
  }
}

module.exports = AddCollaboratorsFlow;
