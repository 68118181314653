/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const Logger = require("./logger");

class OfflineSocket {
  constructor() {
    this.logger = Logger.instance;
  }

  on(event, _handler) {
    return this.logger.info(`[ offline ] on ${event}`);
  }

  emit(event, _message) {
    return this.logger.info(`[ offline ] emit ${event}`);
  }

  removeListener(event, _handler) {
    return this.logger.info(`[ offline ] removeListener ${event}`);
  }
}

module.exports = OfflineSocket;
