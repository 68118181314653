/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const Backbone = require("backbone");

class Flash extends Backbone.View {
  constructor(...args) {
    super(...args);
    this.setMessage = this.setMessage.bind(this);
    this.error = this.error.bind(this);
    this.success = this.success.bind(this);
    this.messageSelector = args[0].messageSelector;
  }

  initialize(attrs) {
    super.initialize(attrs);
    this.duration = attrs.duration || 4000;
    return this.$el.css("animation-duration", `${this.duration / 1000}s`);
  }

  setMessage(type, message) {
    this.$el.addClass(type);
    this.$el.find(this.messageSelector).html(message);
    return setTimeout(() => {
      this.$el.removeClass(type);
      return this.$el.find(this.messageSelector).html("");
    }, this.duration);
  }

  error(message) {
    return this.setMessage("flash--error", message);
  }

  success(message) {
    return this.setMessage("flash--success", message);
  }
}

module.exports = Flash;
