const Backbone = require("backbone");

class UserIdentity extends Backbone.Model {
  get idAttribute() {
    return "_id";
  }

  avatar() {
    // We want to avoid any non-SSL images here, so we upgrade any http to https.
    const avatar = this.get("avatar");
    return typeof avatar === "string" ? avatar.replace(/^http:/, "https:") : avatar;
  }

  displayName() {
    return this.get("displayName");
  }

  username() {
    return this.get("username");
  }

  email() {
    return this.get("email");
  }

  initials() {
    return this.get("initials");
  }
}

module.exports = UserIdentity;
