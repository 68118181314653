const invalidControlClass = "form__control--invalid";
const invalidLabelClass = "form__label--invalid";

const removeAllInvalidStylingsFrom = $element => {
  $element.find(`.${invalidControlClass}`).removeClass(invalidControlClass);
  $element.find(`.${invalidLabelClass}`).removeClass(invalidLabelClass);
};

const getLength = $input => {
  return $input.val().trim().length;
};

const enableButton = $button => {
  $button.prop("disabled", false);
};

const showMessage = ($element, message) => {
  $element.show();
  $element.text(message);
};

const hideMessage = $element => {
  $element.text("");
  $element.hide();
};

const addValidationErrorToInput = input => {
  input.addClass(invalidControlClass);
  const subLabelSelector = `#${input.attr("id").replace("-field", "")}-sub-label`;
  $(subLabelSelector).addClass(invalidLabelClass);
};

module.exports = {
  showMessage,
  hideMessage,
  addValidationErrorToInput,
  removeAllInvalidStylingsFrom,
  enableButton,
  getLength,
};
