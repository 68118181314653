const validator = require("validator");
const { getLength, enableButton, removeAllInvalidStylingsFrom, hideMessage, showMessage, addValidationErrorToInput } = require("./shared/form");

const register = () => {
  const $form = $("#form");
  const $nameField = $form.find("[name=name]");
  const $emailField = $form.find("[name=email]");
  const $passwordField = $form.find("[name=password]");
  const $confirmPasswordField = $form.find("[name=confirm_password]");
  const $confirmPasswordSubLabel = $form.find("[id=confirm-password-sub-label]");
  const $submitButton = $form.find("#continue-button");
  const $registrationForm = $form.find("#registration-form");
  const $confirmationMessage = $form.find("#confirmation-message");
  const $error = $form.find("#error");

  const resetForm = () => {
    hideMessage($error);
    hideMessage($confirmPasswordSubLabel);
    removeAllInvalidStylingsFrom($form);
  };

  const hiFieldFocus = () => {
    enableButton($submitButton);
    resetForm();
  };

  $nameField.focus(hiFieldFocus);
  $emailField.focus(hiFieldFocus);
  $passwordField.focus(hiFieldFocus);
  $confirmPasswordField.focus(hiFieldFocus);

  $submitButton.click(event => {
    event.preventDefault();

    let hasErrors = false;

    if (getLength($nameField) < 1) {
      addValidationErrorToInput($nameField);
      hasErrors = true;
    }

    if (!validator.isEmail($emailField.val())) {
      addValidationErrorToInput($emailField);
      hasErrors = true;
    }

    if (getLength($passwordField) < 8) {
      addValidationErrorToInput($passwordField);
      hasErrors = true;
    }

    if ($passwordField.val() !== $confirmPasswordField.val()) {
      addValidationErrorToInput($confirmPasswordField);
      showMessage($confirmPasswordSubLabel, "Must match the password");
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    $.ajax({
      url: "/register",
      method: "post",
      data: {
        name: $nameField.val(),
        email: $emailField.val(),
        password: $passwordField.val(),
      },
      error: () => {
        showMessage($error, "We weren't able to create your account. Did you already sign up?");
      },
    }).done(() => {
      $registrationForm.hide();
      showMessage($confirmationMessage, "Thanks! Check your e-mail for a link to confirm your account.");
    });
  });
};

module.exports = register;
