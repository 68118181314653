module.exports = {
  CARD_INACTIVE_Z_OFFSET: -1,
  CARD_ACTIVE_Z_OFFSET: 1,
  GROUP_FOCUSED_Z_INDEX: 100000,
  OBJECT_BEING_DRAGGED_Z_INDEX: 100010,
  OBJECT_DRAGGING_Z_INDEX: 100020,
  SHEET_STATE_Z_INDEX: 300000,
  PRESENCE_Z_INDEX: 400000,
  HEADER_Z_INDEX: 500000,
  ONBOARDING_Z_INDEX: 800000,
  EMOJI_Z_INDEX: 900000,
};
