const Base = require("./base");

class Draggable extends Base {
  get significantMovementThreshold() {
    return 4;
  }

  get lastMousePosition() {
    return this._lastMousePosition || {};
  }

  set lastMousePosition(coordinates) {
    this._lastMousePosition = coordinates;
  }

  onDrag() {
    const deltaX = this.left() - this.model.get("x");
    const deltaY = this.top() - this.model.get("y");

    if (this.model.isSelected()) {
      this.model
        .sheet()
        .selectedItems()
        .forEach(item => {
          item.moveTo(item.get("x") + deltaX, item.get("y") + deltaY);
        });
    } else {
      this.model.moveTo(this.left(), this.top());
    }
  }

  startedDragging() {
    if (this.model.isSelected()) {
      this.model
        .sheet()
        .selectedItems()
        .forEach(item => item.drag());
    } else {
      this.model.drag();
    }
    this.updateZIndex();
  }

  dropped() {
    if (this.model.isSelected()) {
      this.model
        .sheet()
        .selectedItems()
        .forEach(item => {
          if (item.moveToValidLocation) {
            item.moveToValidLocation();
          }
        });
    }
  }

  // Drag-vs-click detection

  hiMouseDown(event) {
    const mousePositionX = event.pageX;
    const mousePositionY = event.pageY;

    this.mouseDown = true;
    this.originMousePosition = { x: mousePositionX, y: mousePositionY };
    this.mouseSignificantlyMoved = false;
  }

  hiMouseUp() {
    this.mouseDown = false;
    this.originMousePosition = undefined;
  }

  hiMouseMove(event) {
    if (this.mouseDown && !this.mouseSignificantlyMoved) {
      const mousePositionX = event.pageX;
      const mousePositionY = event.pageY;

      const mouseMovementX = Math.abs(this.originMousePosition.x - mousePositionX);
      const mouseMovementY = Math.abs(this.originMousePosition.y - mousePositionY);

      if ([mouseMovementX, mouseMovementY].some(movement => movement >= this.significantMovementThreshold)) {
        this.mouseSignificantlyMoved = true;
      }
    }
  }

  hiClick() {}

  isValidClick() {
    return !this.mouseSignificantlyMoved;
  }
}

module.exports = Draggable;
