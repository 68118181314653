/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _ = require("underscore");
const utils = require("../../utils");
const OnboardingFlow = require("./onboarding_flow");

class TooltipOnboardingFlow extends OnboardingFlow {
  set isOpen(open) {
    this._isOpen = open;
  }

  get isOpen() {
    return this._isOpen || false;
  }

  get events() {
    return {
      "click .guide-tooltip": "dismiss",
      "click .onboard-dot": "handleDotClick",
      "click .dismiss": "dismiss",
    };
  }

  initialize(attrs) {
    super.initialize(attrs);
    this.window = attrs.window;

    if (this.window != null) {
      $(this.window).on(`resize.${this.flowName()}`, this.render.bind(this));
    }
    this._parent().on("click.dismissOnboarding", () => this.dismiss());
    return this.render();
  }

  render() {
    if (this.isOpen) {
      this._renderWithTooltip();
    } else {
      this._renderWithDot();
    }
    return this;
  }

  _renderWithTooltip() {
    this.$el.html(this.body());
    this._positionTooltip();
    return this.$el.css("z-index", utils.zIndexManager.ONBOARDING_Z_INDEX + 1);
  }

  _renderWithDot() {
    const dotHtml = _.template(`\
<div class="onboard-dot"></div>\
`);
    this.$el.html(dotHtml);
    this._positionDot();
    return this.$el.css("z-index", utils.zIndexManager.ONBOARDING_Z_INDEX);
  }

  _offsetFromParent(top, left) {
    const newPosition = this._parent().offset();
    newPosition.top += top - $(document).scrollTop();
    newPosition.left += left - $(document).scrollLeft();
    return this.$el.css(newPosition);
  }

  remove() {
    this._parent().off("click.dismissOnboarding");
    if (this.window != null) {
      $(this.window).off(`resize.${this.flowName()}`);
    }
    return super.remove();
  }

  handleDotClick(e) {
    e.stopPropagation();
    this.isOpen = true;
    return this.render();
  }
}

module.exports = TooltipOnboardingFlow;
