const Backbone = require("backbone");

class SelectableModel extends Backbone.Model {
  get kind() {
    throw new Error("unimplemented");
  }

  get ephemeralProperties() {
    return ["isSelected", "mouseDownLocation"];
  }

  select() {
    this.sheet().trigger("item:selected");
    this.set({ isSelected: true });
  }

  deselect() {
    this.set({ isSelected: false });
  }

  isSelected() {
    return this.get("isSelected") || false;
  }
}

module.exports = SelectableModel;
