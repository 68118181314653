/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _ = require("underscore");
const utils = require("../utils");
const Base = require("./base");

class SheetState extends Base {
  constructor(...args) {
    super(...args);
    this.initialize = this.initialize.bind(this);
    this.render = this.render.bind(this);
    this.hiExitIncognito = this.hiExitIncognito.bind(this);
    this.updateState = this.updateState.bind(this);
    this.updateRole = this.updateRole.bind(this);
    this.updateContent = this.updateContent.bind(this);
  }

  get el() {
    return ".sheet-state";
  }

  get templates() {
    return {
      blur: {
        moderator: _.template(`\
            <div class="action exit-incognito">
              <i class="png-icon-incognito png-icon-incognito--white"></i>
              Exit Incognito Mode
            </div>\
          `),
        other: _.template(`\
          <i class="png-icon-incognito png-icon-incognito--white"></i>
          This Sheet is in Incognito Mode\
        `),
      },
      stale: {
        other: _.template(`\
          We've made some code updates.  Please <a href="javascript:window.location.reload()">reload</a>.\
        `),
      },
      reload: {
        other: _.template(`\
          Something has gone wrong.  Please <a href="javascript:window.location.reload()">reload</a>.\
        `),
      },
    };
  }

  get events() {
    return { "click .exit-incognito": "hiExitIncognito" };
  }

  initialize(attributes) {
    super.initialize(attributes);

    this.board = this.model.board();
    this.currentCollaborator = this.board.currentCollaborator();

    this.listenTo(this.model, "change:displayState", this.updateState, this);
    this.listenTo(this.currentCollaborator, "change:role", this.updateRole, this.currentCollaborator);

    return this.render();
  }

  render() {
    this.updateState(this, this.model.get("displayState"));
    this.updateRole(this.currentCollaborator, this.currentCollaborator.get("role"));
    this.updateContent(this);
    return this.$el.css("z-index", utils.zIndexManager.SHEET_STATE_Z_INDEX);
  }

  hiExitIncognito() {
    return this.model.setMode("normal");
  }

  updateState(sheet, state) {
    this.$el.removeClassMatching(/sheet-state--state-\w*/);
    this.$el.addClass(`sheet-state--state-${state}`);
    return this.updateContent(this);
  }

  updateRole(collaborator, role) {
    this.$el.removeClassMatching(/sheet-state--role-\w*/);
    this.$el.addClass(`sheet-state--role-${role}`);
    return this.updateContent(this);
  }

  updateContent() {
    const state = this.model.get("displayState");
    const role = this.currentCollaborator.get("role");
    const content = this.templates[state] ? (this.templates[state][role] ? this.templates[state][role]() : this.templates[state].other()) : "";
    return this.$el.html(content);
  }
}

module.exports = SheetState;
