/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const Base = require("./base");
const BoardAccessModal = require("./board_access");

class EditAccess extends Base {
  get events() {
    return { click: "showBoardAccessModal" };
  }

  constructor(...args) {
    super(...args);
    this.updateIcon = this.updateIcon.bind(this);
  }

  initialize(attributes) {
    super.initialize(attributes);
    this.listenTo(this.model, "change:access", this.updateIcon);
    this.listenTo(this.model.currentCollaborator(), "change:role", this.updateIcon);
    return this.updateIcon();
  }

  showBoardAccessModal() {
    if (!this.model.currentCollaborator().isModerator()) {
      return;
    }
    if (this.$el.attr("js-disabled") === "true") {
      return;
    }
    return new BoardAccessModal(this.model);
  }

  updateIcon() {
    this.$el.removeClass("board-access--public board-access--private board-access--disabled");
    if (this.model.isPrivate()) {
      this.$el.addClass("board-access--private");
    } else {
      this.$el.addClass("board-access--public");
    }
    if (!this.model.currentCollaborator().isModerator()) {
      return this.$el.addClass("board-access--disabled");
    }
  }
}

module.exports = EditAccess;
