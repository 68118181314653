const Backbone = require("backbone");
const ObjectID = require("bson-objectid");
const _ = require("underscore");

const utils = require("../utils");
const Card = require("./card");
const SelectableModel = require("./selectable-model");

class Group extends SelectableModel {
  get idAttribute() {
    return "_id";
  }

  get defaults() {
    return { name: "" };
  }

  get kind() {
    return "group";
  }

  get ephemeralProperties() {
    return [...super.ephemeralProperties, "sheet", "cards", "height", "width", "state", "board"];
  }

  initialize(attributes = {}) {
    this.logger = utils.Logger.instance;

    this.moveToValidLocation();

    const cards = new Backbone.Collection(_.map(attributes.cards, (card) => new Card(_(card).extend({ board: this.get("board") }))));
    this.set("cards", cards);
    if (!attributes.created) {
      this.set("created", new Date());
    }
    if (!attributes.updated) {
      this.set("updated", new Date());
    }
    cards.comparator = this.cardSorter;
    cards.sort();
    cards.on("remove", this.removeCard, this);
    cards.on("change:deleted", this.softDeleteCard, this);
    cards.on("add", this.resetCardPositioning, this);
  }

  cards() {
    return this.get("cards");
  }

  aliveCards() {
    return this.cards().reject((c) => c.softDeleted());
  }

  sheet() {
    return this.get("board").findSheetByGroupId(this.id);
  }

  sheetId() {
    return this.sheet().id;
  }

  board() {
    return this.get("board");
  }

  currentUser() {
    return this.board().currentUser();
  }

  currentUserId() {
    return this.board().currentUserId();
  }

  softDeleted() {
    return this.get("deleted");
  }

  resetCardPositioning(card, cards, options) {
    card.set(
      {
        x: undefined,
        y: undefined,
        state: undefined,
      },
      options
    );
  }

  cardSorter(a, b) {
    if (!a || !b) {
      return 0;
    }
    const orderA = a.get("order");
    const orderB = b.get("order");
    if (orderA !== orderB) {
      return orderA - orderB;
    }
    if (a.get("created") > b.get("created")) {
      return 1;
    } else {
      return 0;
    }
  }

  findCard(id) {
    return this.cards().find((card) => card.id === id);
  }

  getCardIndex(id) {
    return this.cards().findIndex((card) => card.id === id);
  }

  moveTo(x, y) {
    this.set({ x, y });
  }

  moveToValidLocation() {
    const x = this.get("x");
    const y = this.get("y");
    this.set({ x: Math.max(x, 0), y: Math.max(y, 0) });
  }

  resize(width, height) {
    this.set({ width, height });
  }

  bounds() {
    return {
      x: this.get("x"),
      y: this.get("y"),
      width: this.get("width"),
      height: this.get("height"),
    };
  }

  bringForward(options) {
    const maxZ = this.sheet().maxZ();
    if (this.get("z") !== maxZ) {
      this.set("z", maxZ + 1, options);
    }
  }

  lastCard() {
    return this.cards().last();
  }

  createCard() {
    const order = this.cards().length ? this.lastCard().get("order") + 1 : 0;
    const card = new Card({
      _id: ObjectID().toHexString(),
      board: this.get("board"),
      creator: this.currentUserId(),
      authors: [this.currentUserId()],
      order,
    });
    this.cards().add(card);
    return card;
  }

  dropSelectedItems(dropItem, location) {
    // Don't drop selected items into selected items, or everything will get deleted.
    if (this.isSelected()) return;

    if (this.isCardDroppedIntoSameLocation(dropItem, location)) {
      const selectedItems = this.sheet().selectedItems();
      selectedItems.forEach((item) => item.drop());
    } else {
      this.dropAndOrderSelectedItems(dropItem, location);
    }
  }

  dropCard(id, location) {
    const originalGroup = this.sheet().findGroupByCardId(id);
    const card = originalGroup.findCard(id);

    if (card.isSelected()) {
      this.dropSelectedItems(card, location);
    } else {
      this.dropIndividualCard(card, originalGroup, location);
      if (card.id !== location.id) {
        this.updateCardOrderingAfterInserting([card], location);
      }
    }
  }

  dropIndividualCard(card, originalGroup, location) {
    this.logger.info(`models.Group.dropCard: card(${card.id}) -> group(${this.id}) at ${JSON.stringify(location)}`);

    if (originalGroup.id !== this.id) {
      this.sheet().moveCard(card, originalGroup.id, this.id);
    }

    card.drop();
    this.blurCards();
  }

  dropGroup(id, location) {
    const group = this.sheet().findGroup(id);

    if (group.isSelected()) {
      this.dropSelectedItems(group, location);
    } else {
      const groupCards = group.cards().toArray();
      this.dropIndividualGroup(group, location);
      this.updateCardOrderingAfterInserting(groupCards, location);
    }
  }

  dropIndividualGroup(group, location) {
    this.logger.info(`models.Group.dropGroup: group(${group.id}) -> group(${this.id}) at ${JSON.stringify(location)}`);

    // Select the individual cards, so they show up as selected after the drop
    if (group.isSelected()) {
      group.deselect();
      group.selectCards();
    }

    this.sheet().mergeGroups(this.id, group.id, location);
    group.drop();
    this.blurCards();
  }

  isCardDroppedIntoSameLocation(dropItem, location) {
    if (dropItem.kind !== "card") return false;

    const originalGroup = dropItem.group();
    const locationIndex = originalGroup.getCardIndex(location.id);
    const originalIndex = originalGroup.getCardIndex(dropItem.id);
    const positionIndex = locationIndex + (location.position === "above" ? -1 : 1);

    return this.id === originalGroup.id && (!location.position || positionIndex === originalIndex);
  }

  dropAndOrderSelectedItems(dropItem, location) {
    const selectedItems = this.sheet().selectedItems();
    const [cards, groups] = _(selectedItems).partition((item) => item.kind === "card");
    const dropItemCards = dropItem.kind === "card" ? [dropItem] : dropItem.cards().toArray();
    const groupCards = groups.map((group) => group.cards().toArray());

    cards.forEach((card) => this.dropIndividualCard(card, card.group(), location));
    groups.forEach((group) => this.dropIndividualGroup(group, location));

    const allCards = [...cards, ...groupCards.flat()];
    const dropItemCardIds = dropItemCards.map((card) => card.id);
    const otherSelectedCards = allCards.filter((card) => !dropItemCardIds.includes(card.id));
    const orderedCards = [...dropItemCards, ...otherSelectedCards];
    this.updateCardOrderingAfterInserting(orderedCards, location);
  }

  updateCardOrderingAfterInserting(cards, location) {
    const ids = cards.map((card) => card.id);
    const surroundingCards = this.cards().reject((card) => ids.includes(card.id));
    const locationCardIndex = surroundingCards.findIndex((card) => card.id === location.id);
    const locationAdjustment = (location ? location.position : undefined) === "above" ? 0 : 1;
    const insertLocation = locationCardIndex + locationAdjustment;
    const newCardOrder = [...surroundingCards.slice(0, insertLocation), ...cards, ...surroundingCards.slice(insertLocation)];
    newCardOrder.forEach((card, index) => card.set("order", index));
  }

  removeCard(card, cards, options) {
    if (this.aliveCards().length === 0) {
      this.softDelete(options);
    }
  }

  softDeleteCard(card, deleted, options) {
    if (options && options.rebroadcast) {
      return;
    }
    if (!deleted) {
      return;
    }
    if (this.aliveCards().length === 0) {
      this.softDelete(options);
    }
  }

  drag() {
    this.set("state", "dragging");
  }

  drop() {
    this.unset("state");
    this.blur();
  }

  isBeingDragged() {
    return this.get("state") === "being-dragged";
  }

  isDragging() {
    return this.get("state") === "dragging";
  }

  hover(location) {
    this.set("hover", true);
    this.cards().each(function (card) {
      if (card.id === location.id) {
        card.hover(location.position);
      } else {
        card.blur();
      }
    });
  }

  blur() {
    this.set("hover", false);
    this.blurCards();
  }

  blurCards() {
    this.cards().each(function (card) {
      if (!card.isDragging()) {
        card.blur();
      }
    });
  }

  softDelete(options) {
    this.set({ deleted: new Date() }, options);
  }

  duplicate() {
    return new Group({
      _id: ObjectID().toHexString(),
      board: this.board(),
      deleted: this.get("deleted"),
      name: this.get("name"),
      x: this.get("x"),
      y: this.get("y"),
      z: this.get("z"),
    });
  }

  toggleSelect() {
    if (this.cards().length === 1) {
      this.cards().first().toggleSelect();
      return;
    }

    if (this.get("isSelected")) {
      this.deselect();
    } else {
      this.select();
    }
  }

  deselectCards() {
    this.cards().forEach((card) => card.deselect());
  }

  selectCards() {
    this.cards().forEach((card) => card.select());
  }

  select() {
    super.select();
    this.deselectCards();
  }

  isSelected() {
    if (super.isSelected()) return true;
    const cards = this.cards();
    return cards.length === 1 && cards.first().isSelected();
  }

  colorize(colorIndex) {
    this.cards().invoke("colorize", colorIndex);
  }

  selectAllExcept(cardId) {
    this.deselect();
    this.cards().forEach((card) => {
      if (card.id !== cardId) card.select();
    });
  }
}

module.exports = Group;
