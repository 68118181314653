const Backbone = require("backbone");
const _ = require("underscore");

const UserIdentity = require("./user_identity");
const UserOnboardedFeature = require("./user_onboarded_feature");

class User extends Backbone.Model {
  get idAttribute() {
    return "_id";
  }

  initialize(attrs = {}) {
    const identities = new Backbone.Collection(_.map(attrs.identities, identity => new UserIdentity(identity)));
    const onboardedFeatures = new Backbone.Collection(_.map(attrs.onboardedFeatures, onboardedFeature => new UserOnboardedFeature(onboardedFeature)));
    this.set("identities", identities);
    this.set("onboardedFeatures", onboardedFeatures);
  }

  activeIdentity() {
    const identityCollection = this.get("identities");
    const foundIdentity = identityCollection.findWhere({ sourceId: this.get("activeIdentitySourceId") });
    return foundIdentity || identityCollection.at(0);
  }

  role() {
    return this.get("role");
  }

  hasRole(role) {
    return this.role() === role;
  }

  onboardedFeatures() {
    return this.get("onboardedFeatures");
  }

  onboardFeature(featureName) {
    return this.onboardedFeatures().push(new UserOnboardedFeature({ featureName }));
  }
}

module.exports = User;
