const ObjectID = require("bson-objectid");
const _ = require("underscore");

const SelectableModel = require("./selectable-model");

const DEFAULT_COLOR_INDEX = 2;

class Card extends SelectableModel {
  get idAttribute() {
    return "_id";
  }

  get kind() {
    return "card";
  }

  get defaults() {
    return {
      text: "",
      authors: [],
      plusAuthors: [],
      colorIndex: DEFAULT_COLOR_INDEX,
    };
  }

  get ephemeralProperties() {
    return [...super.ephemeralProperties, "group", "state", "board"];
  }

  get specialKeywordRules() {
    return {
      "i-like": /^\s*i\s+(really\s+)?(like|love)/i,
      "i-wish": /^\s*i\s+(really\s+)?wish/i,
      "we-will": /^\s*(we|i)\s+(really\s+)?(will|should)/i,
    };
  }

  get specialKeywords() {
    return _.keys(this.specialKeywordRules);
  }

  initialize(attributes = {}) {
    if (!attributes.created) {
      this.set("created", new Date());
    }
    if (!attributes.updated) {
      this.set("updated", new Date());
    }

    this.on("change:order", this.updateOrder, this);
  }

  board() {
    return this.get("board");
  }

  group() {
    return this.board().findGroupByCardId(this.id);
  }

  groupId() {
    return this.group().id;
  }

  sheet() {
    return this.board().findSheetByCardId(this.id);
  }

  sheetId() {
    return this.sheet().id;
  }

  currentUser() {
    return this.board().currentUser();
  }

  currentUserId() {
    return this.board().currentUserId();
  }

  softDeleted() {
    return this.get("deleted");
  }

  isFocused() {
    return this.id === this.sheet().focusedCardId();
  }

  wasRecentlyFocused() {
    return this.id === this.sheet().recentlyFocusedCardId();
  }

  moveTo(x, y) {
    this.set({ x, y });
  }

  updateOrder(card, order, options) {
    this.group().cards().sort(options);
  }

  drag() {
    this.set("state", "dragging");
  }

  drop() {
    this.set({
      x: undefined,
      y: undefined,
      state: undefined,
    });
    this.blur();
  }

  type(text) {
    this.set({ text });
    this.touch();
    this.detectCommand();
  }

  findSpecialKeyword() {
    const text = this.get("text");
    const specialKeywordMatch = _.pick(this.specialKeywordRules, (rule, _keyword) => text.match(rule) !== null);
    return _.isEmpty(specialKeywordMatch) ? null : _.keys(specialKeywordMatch)[0];
  }

  colorize(colorIndex, options = {}) {
    if (this.findSpecialKeyword()) return;

    if (colorIndex > 4 || colorIndex < 0) {
      colorIndex = DEFAULT_COLOR_INDEX;
    }

    this.set({ colorIndex });
    this.touch();

    if (!options.shouldBroadcast) return;

    this.sheet().trigger("card:colorized", this, colorIndex);
  }

  hover(location) {
    this.set("hover", location);
  }

  blur() {
    this.unset("hover");
  }

  softDelete() {
    this.set({ deleted: new Date() });
  }

  toggleSelect() {
    if (this.isSelected()) {
      this.deselect();
    } else if (this.group().isSelected()) {
      this.group().selectAllExcept(this.id);
    } else {
      this.select();
    }
  }

  select() {
    super.select();
    this.group().deselect();
  }

  isBeingDragged() {
    return this.get("state") === "being-dragged";
  }

  isDragging() {
    return this.get("state") === "dragging";
  }

  plusOne() {
    this.group().bringForward();
    const plusAuthors = _.clone(this.get("plusAuthors")); // need to clone otherwise backbone won't trigger a change event
    const author = this.currentUserId();
    const index = plusAuthors.indexOf(author);
    if (index >= 0) {
      plusAuthors.splice(index, 1);
    } else {
      plusAuthors.push(author);
    }
    this.set("plusAuthors", plusAuthors);
  }

  touch() {
    const authors = this.get("authors");
    const author = this.currentUserId();
    if (!(authors.indexOf(author) >= 0)) {
      const clone = _.clone(authors);
      clone.push(author);
      this.set("authors", clone);
    }
  }

  duplicate() {
    return new Card({
      _id: ObjectID().toHexString(),
      board: this.board(),
      deleted: this.get("deleted"),
      creator: this.get("creator"),
      authors: this.get("authors"),
      order: this.get("order"),
      colorIndex: this.get("colorIndex"),
      text: this.get("text"),
    });
  }

  detectCommand() {
    // only people with role "admin" or "ai" can execute commands
    const role = this.board().currentCollaborator().user().role();
    if (role !== "admin" && role !== "ai") {
      return;
    }

    const text = this.get("text").trim();
    const commands = ["$summary"];
    if (commands.includes(text)) {
      // debouce commands
      if (this.detectCommandTimeout) {
        clearTimeout(this.detectCommandTimeout);
      }

      // this function makes sure that after the timeout (below) the command hasn't
      // changed in the card text.  if it hasn't, then the command is triggered.
      const triggerCommand = ((card, orig) => {
        return () => {
          const text = card.get("text").trim();
          if (text === orig) {
            const collaborator = card.board().currentCollaborator();
            const command = text.replace("$", "");
            const cardId = card.id;
            collaborator.set("command", { type: command, cardId: cardId, ts: Date.now() });

            const addDots = () => {
              card.set("text", card.get("text") + ".");
            };

            // show the user this is happening by dot dot dotting
            addDots();
            card.visualizeCommandInterval = setInterval(addDots, 2000);

            // but stop dot dot dotting when the command comes back or timesout
            collaborator.on("change:command", (collab, value, _options) => {
              if (value === null) {
                clearInterval(card.visualizeCommandInterval);
              }
            });
          }
        };
      })(this, text);

      // wait 2 seconds before actually triggering the command
      this.detectCommandTimeout = setTimeout(triggerCommand, 2000);
    }
  }
}

module.exports = Card;
