const validator = require("validator");
const { removeAllInvalidStylingsFrom, enableButton, hideMessage, showMessage, addValidationErrorToInput } = require("./shared/form");

const forgot = () => {
  const $form = $("#form");
  const $emailField = $form.find("[name=email]");
  const $submitButton = $form.find("#continue-button");
  const $forgotForm = $form.find("#forgot-form");
  const $confirmationMessage = $form.find("#confirmation-message");
  const $error = $form.find("#error");

  const resetForm = () => {
    hideMessage($error);
    removeAllInvalidStylingsFrom($form);
  };

  const hiFieldFocus = () => {
    enableButton($submitButton);
    resetForm();
  };

  $emailField.focus(hiFieldFocus);

  $submitButton.click(event => {
    event.preventDefault();

    var hasErrors = false;

    if (!validator.isEmail($emailField.val())) {
      addValidationErrorToInput($emailField);
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    $.ajax({
      url: "/forgot",
      method: "post",
      data: {
        email: $emailField.val(),
      },
      error: () => {
        showMessage($error, "Sorry, looks like something went wrong over here. Please try again later.");
      },
    }).done(() => {
      $forgotForm.hide();
      showMessage(
        $confirmationMessage,
        `Thank you!  If an account exists with this address, you will receive an email with instructions to reset your password.`
      );
    });
  });
};

module.exports = forgot;
