const Backbone = require("backbone");
const _ = require("underscore");

class Presence extends Backbone.Model {
  updatePresence(online, offline) {
    this.set("online-collaborators", online);
    this.set("offline-collaborators", offline);
  }

  onlineCollaborators() {
    return this.get("online-collaborators");
  }

  offlineCollaborators() {
    return this.get("offline-collaborators");
  }

  updatePresenceFromCollaborators(collaborators) {
    this.updatePresence(...this.statusCounts(collaborators));
  }

  statusCounts(collaborators) {
    const [online, offline] = _.partition(collaborators, this.isOnline);
    return [online.length, offline.length];
  }

  isOnline(collaborator) {
    return collaborator.onCurrentSheet();
  }
}

module.exports = Presence;
