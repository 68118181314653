/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const Base = require("./base");

class BrowserTab extends Base {
  constructor(...args) {
    super(...args);
    this.updateTitle = this.updateTitle.bind(this);
  }

  initialize(attributes) {
    super.initialize(attributes);
    this.render();
    return this.listenTo(this.model, "change:name", this.updateTitle);
  }

  render() {
    return this.updateTitle(this.model, this.model.get("name"));
  }

  updateTitle(board, name) {
    const title = `Stickies.io - ${name}`;
    if ($("head title").text() !== title) {
      return $("head title").html(title);
    }
  }
}

module.exports = BrowserTab;
