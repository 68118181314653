const Base = require("./base");
const Modal = require("./modal");
const singletonManager = require("../utils").modalManager;
require("../lib/jquery_extensions/initials");

class Collaborator extends Base {
  get className() {
    return "presence-bar__collaborator";
  }

  get currentCollaboratorActions() {
    return _.template(`\
      <div class="presence-bar__toggle-drawer">
        <span class="open-drawer">&raquo;</span>
        <span class="close-drawer">&laquo;</span>
      </div>
      <ul class="menu__items current-actions">
        <li class="menu__item menu__item--indented everyone-action arrange-sheet">Auto Arrange Stickies</li>
        <li class="menu__item menu__item--indented ai-action theme-sheet toggle-on">
          Group Cards By Theme
        </li>
        <li class="menu__item menu__item--indented ai-action theme-sheet toggle-off">
          <i class="png-icon-loading"></i>
          Group Cards By Theme
        </li>
        <li class="menu__item menu__item--indented moderator-action delete-sheet">Delete This Sheet</li>
        <li class="menu__item menu__item--indented moderator-action move-sheet">Move This Sheet</li>
        <li class="menu__item menu__item--indented moderator-action duplicate-sheet">Duplicate This Sheet</li>
        <li class="menu__item menu__item--indented everyone-action download-csv">Download Spreadsheet</li>
        <li class="menu__item menu__item--indented incognito-mode toggle-on">
          Enable Incognito Mode
        </li>
        <li class="menu__item menu__item--indented incognito-mode toggle-off">
          <i class="png-icon-check"></i>
          Enable Incognito Mode
        </li>
        <li class="menu__item menu__item--indented sticky-focus toggle-on">
          Focus My Stickies
        </li>
        <li class="menu__item menu__item--indented sticky-focus toggle-off">
          <i class="png-icon-check"></i>
          Focus My Stickies
        </li>
        <li class="menu__item menu__item--indented menu__item--border-top admin-action role set-current-moderator">Make Moderator</li>
        <li class="menu__item menu__item--indented menu__item--border-top admin-action role remove-current-moderator">Remove Moderator</li>
        <li class="menu__item menu__item--indented admin-action leave">Leave Board</li>
      </ul>\
    `);
  }

  get collaboratorActions() {
    return _.template(`\
      <div class="close-drawer close-drawer--small">&times;</div>
      <ul class="menu__items menu__item--indented other-actions">
        <li class="menu__item sticky-focus toggle-on">
          Focus Stickies
        </li>
        <li class="menu__item menu__item--indented sticky-focus toggle-off">
          Remove Focus
        </li>
        <li class="menu__item menu__item--indented role set-moderator">
          Make Moderator
        </li>
        <li class="menu__item menu__item--indented role remove-moderator">
          Remove Moderator
        </li>
      </ul>\
    `);
  }

  get boardSelect() {
    return _.template(`\
      <form id="board-select" method="post" action="/sheets/<%= sheet.id %>">
        <input type="hidden" name="_method" value="put" />
        <select name="boardId" size="<%= Math.min(boards.length, 5) %>">
          <% _.each(boards, function(board) { %>
            <option value="<%= board.id %>"><%= board.name %></option>
          <% }); %>
        </select>
      </form>
      <% if (sheets.length == 1) { %>
        <div>This is the only sheet on this board. <br /> Moving this sheet will cause this board to be deleted.</div>
      <% } %>\
    `);
  }

  get template() {
    return _.template(`\
      <div class="presence-bar__avatar">
        <img src="" />
        <div class="presence-bar__badges">
          <div class='presence-bar__badge presence-bar__badge--moderator'></div>
        </div>
      </div>
      <div class="menu menu--right menu--borderless">
        <div class="menu__content">
          <div class="presence-bar__user-info">
            <div class="display-name"></div>
            <div class="last-activity"><label>last activity: </label><span></span></div>
          </div>
          <div class="actions"></div>
        </div>
      </div>\
    `);
  }

  get events() {
    return {
      click: "stopPropagation",
      "click .presence-bar__avatar": "hiToggleDrawer",

      "click .actions .set-moderator": "hiSetModerator",
      "click .actions .set-current-moderator": "hiSetModerator",
      "click .actions .remove-moderator": "hiRemoveModerator",
      "click .actions .remove-current-moderator": "hiRemoveModerator",
      "click .actions .sticky-focus.toggle-on": "hiSetFocus",
      "click .actions .sticky-focus.toggle-off": "hiRemoveFocus",
      "click .actions .arrange-sheet": "hiArrangeSheet",
      "click .actions .theme-sheet": "hiThemeSheet",
      "click .actions .delete-sheet": "hiDeleteSheet",
      "click .actions .move-sheet": "hiMoveSheet",
      "click .actions .duplicate-sheet": "hiDuplicateSheet",
      "click .actions .incognito-mode.toggle-on": "hiEnableIncognitoMode",
      "click .actions .incognito-mode.toggle-off": "hiDisableIncognitoMode",
      "click .actions .download-csv": "hiDownloadSheet",
      "click .actions .leave": "hiLeave",

      "click .menu .close-drawer": "hiCloseDrawer",
      "click .menu .open-drawer": "hiToggleDrawer",
    };
  }

  id() {
    return `collaborator-${this.model.id}`;
  }

  initialize(attributes) {
    super.initialize(attributes);

    this.board = this.model.board();
    this.currentCollaborator = this.board.currentCollaborator();

    this.listenTo(this.model, "change:role", this.updateDrawer, this);
    this.listenTo(this.model, "change:role", this.updateRole, this);
    this.listenTo(this.model, "change:hilight", this.updateDrawer, this);
    this.listenTo(this.model, "change:hilight", this.updateFocus, this);
    this.listenTo(this.model, "change:lastHeartbeat", this.updateActivity, this);
    this.listenTo(this.model, "change:sheetIds", this.updateActivity, this);
    this.listenTo(this.model, "change:command", this.updateCommand, this);
    this.listenTo(this.model.user().activeIdentity(), "change:avatar", this.updateAvatar, this);
    if (this.currentCollaborator !== this.model) {
      this.listenTo(this.currentCollaborator, "change:role", this.updateDrawer, this);
    }

    this.listenTo(this.board, "change:activeSheetId", this.listenToSheet, this);
    this.listenToSheet(this.board, this.board.activeSheetId());

    this.render();

    $(document).on("click", () => this.hiCloseDrawer());
    this.$el.find(".presence-bar__avatar img").initials(this.model.user().activeIdentity().initials());
  }

  render() {
    this.$el.html(this.template());

    this.$el.attr("data-test-name", this.model.user().activeIdentity().displayName());

    let actionTemplate = this.collaboratorActions;
    if (this.model === this.currentCollaborator) {
      this.$el.addClass("current-collaborator");
      actionTemplate = this.currentCollaboratorActions;
    } else {
      this.$el.addClass("other-collaborator");
    }
    this.$el.find(".actions").html(actionTemplate());

    this.updateDrawer();
    this.updateAvatar(this.model, this.model.user().activeIdentity().avatar());
  }

  listenToSheet(board, sheetId) {
    if (this.sheet) {
      this.stopListening(this.sheet);
    }
    this.sheet = this.board.findSheetById(sheetId);
    this.listenTo(this.sheet, "change:mode", this.updateActions, this);
    this.updateActions();
  }

  stopPropagation(event) {
    return event.stopPropagation();
  }

  hiToggleDrawer(event) {
    const isOpen = this.$el.hasClass("menu--open");
    $(document).trigger(event);
    if (isOpen) {
      this.hiCloseDrawer();
    } else {
      this.hiOpenDrawer();
    }
    event.stopPropagation();
  }

  hiOpenDrawer(_event) {
    this.$el.addClass("menu--open");
    this.$(".menu").addClass("menu--open");
  }

  hiCloseDrawer() {
    this.$el.removeClass("menu--open");
    this.$(".menu").removeClass("menu--open");
  }

  hiSetModerator(_event) {
    this.model.makeModerator();
  }

  hiRemoveModerator(_event) {
    this.model.makeEditor();
  }

  hiSetFocus(_event) {
    this.board.setHilight(this.model);
  }

  hiRemoveFocus(_event) {
    this.board.clearHilight();
  }

  hiArrangeSheet(_event) {
    this.board.activeSheet().arrangeGroups();
  }

  hiThemeSheet(_event) {
    this.model.requestThemes();
    this.updateThemeActions();
  }

  hiDeleteSheet(_event) {
    if (this.board.sheets().length === 1) {
      return new Modal({
        title: "You can't delete this sheet.",
        message: "You cannot delete the last sheet on a board. <br /> Make a new sheet to be able to delete this one.",
        buttons: [{ text: "Okay" }],
      });
    } else {
      return new Modal({
        title: `Are you sure you want to delete <br /> ${this.board.activeSheet().name() || "<em>untitled sheet</em>"}?`,
        message: "Once deleted you will not be able to get it back.",
        buttons: [
          {
            text: "Delete",
            classes: ["action"],
            action: (modal) => {
              this.board.activeSheet().delete();
              return modal.close();
            },
          },
          { text: "Cancel" },
        ],
      });
    }
  }

  hiMoveSheet(_event) {
    const sheet = this.board.activeSheet();
    const sheets = this.board.sheets();
    const boards = _.chain(this.currentCollaborator.boards())
      .map((name, id) => ({
        id,
        name,
      }))
      .sortBy(({ name }) => name.toUpperCase())
      .value();

    if (boards.length === 0) {
      return new Modal({
        title: "You do not have other boards",
        message: "You cannot move this sheet without another board.",
        buttons: [{ text: "Okay" }],
      });
    }

    const modalMovingSheet = {
      title: "Moving sheet...",
      closeAction() {
        return false;
      },
    };

    return new Modal({
      title: "Choose this sheet's new home",
      message: this.boardSelect({ sheet, sheets, boards }),
      buttons: [
        {
          text: "Move Sheet",
          classes: ["action"],
          action: (modal) => {
            modal.$("#board-select").submit();
            new Modal(modalMovingSheet);
            singletonManager.disable();
            setTimeout(() => (window.location = `/sheets/${sheet.id}`), 1000);
          },
        },
        { text: "Cancel" },
      ],
    });
  }

  hiDuplicateSheet(_event) {
    const sheet = this.board.duplicateSheet(this.board.activeSheetId());
    this.hiCloseDrawer();
    this.board.setActiveSheetId(sheet.id);
    return new Modal({
      title: "Success!",
      message: `This is your new sheet, named ${sheet.get("name")}.`,
      buttons: [{ text: "Okay" }],
    });
  }

  hiEnableIncognitoMode(_event) {
    this.board.activeSheet().setMode("blur");
  }

  hiDisableIncognitoMode(_event) {
    this.board.activeSheet().setMode("normal");
  }

  hiDownloadSheet(_event) {
    window.location = `/sheets/${this.board.activeSheet().id}.csv`;
  }

  hiLeave(_event) {
    this.board.removeCollaborator(this.model.id);
    window.location = "/";
  }

  updateFocus(collaborator, hilight) {
    this.$el.toggleClass("focus", hilight === "hilight");
    this.updateFocusActions();
  }

  updateAvatar(user, url) {
    this.$el.find(".presence-bar__avatar img").attr("src", url);
  }

  updateRole(_collaborator, _roles) {
    this.$el.find(".presence-bar__badge").removeClass("presence-bar__badge--active");
    if (this.model.isModerator()) {
      this.$el.find(".presence-bar__badge--moderator").addClass("presence-bar__badge--active");
    }
  }

  updateDrawer() {
    this.updateName();
    this.updateRole();
    this.updateActivity();
    this.updateActions();
  }

  updateName() {
    const name = this.model.user().activeIdentity().displayName();
    this.$el.find(".presence-bar__user-info .display-name").html(name);
  }

  updateActivity() {
    this.$el.find(".presence-bar__user-info .last-activity span").html(this.model.lastActivity());
  }

  updateCommand() {
    this.updateThemeActions();
  }

  updateActions() {
    this.$(".actions li").hide();

    this.updateNormalActions();
    if (this.currentCollaborator.isModerator()) {
      this.updateModeratorActions();
    }
    if (this.currentCollaborator.user().hasRole("admin")) {
      this.updateAdminActions();
    }
    if (this.currentCollaborator.user().hasRole("ai") || this.currentCollaborator.user().hasRole("admin")) {
      this.updateAiActions();
    }
  }

  updateNormalActions() {
    this.$(".actions .everyone-action").show();
    this.updateFocusActions();
  }

  updateModeratorActions() {
    this.$(".actions .moderator-action").show();
    this.updateRoleActions();
    this.updateIncognitoActions();
  }

  updateAdminActions() {
    this.$(".actions .admin-action").show();
    if (this.model.isModerator()) {
      this.$(".actions .set-current-moderator").hide();
    } else {
      this.$(".actions .remove-current-moderator").hide();
    }
  }

  updateAiActions() {
    this.$(".actions .ai-action").show();
    this.updateThemeActions();
  }

  updateRoleActions() {
    this.$(".actions .role").hide();
    if (this.model.isModerator()) {
      this.$(".actions .remove-moderator").show();
    } else {
      this.$(".actions .set-moderator").show();
    }
  }

  updateIncognitoActions() {
    this.$(".actions .incognito-mode").hide();
    if (!this.sheet) {
      return;
    }
    if (this.sheet.get("mode") === "blur") {
      this.$(".actions .incognito-mode.toggle-off").show();
    } else {
      this.$(".actions .incognito-mode.toggle-on").show();
    }
  }

  updateFocusActions() {
    this.$(".actions .sticky-focus").hide();
    if (this.model.hilight() === "hilight") {
      this.$(".actions .sticky-focus.toggle-off").show();
    } else {
      this.$(".actions .sticky-focus.toggle-on").show();
    }
  }

  updateThemeActions() {
    this.$(".actions .theme-sheet").hide();
    if (this.model.command() && this.model.command().type === "theme") {
      this.$(".actions .theme-sheet.toggle-off").show();
    } else {
      this.$(".actions .theme-sheet.toggle-on").show();
    }
  }
}

module.exports = Collaborator;
