/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var sanitize = function(entity, { property, toDelete, children }) {
  // for each sub entity
  $.each(entity[property] || [], function(_, subEntity) {
    // in-place mutate, removing blacklisted properties
    $.each(toDelete || [], (_, d) => delete subEntity[d]);

    // then recurse into their sub entities, if appropriate
    return $.each(children || [], (_, subEntityConfig) => sanitize(subEntity, subEntityConfig));
  });

  // return undefined to communicate effects
  return undefined;
};

module.exports = { sanitize };
