/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _ = require("underscore");
const CollaboratorItemBase = require("./collaborator_item_base");

class InvitationItem extends CollaboratorItemBase {
  constructor(...args) {
    super(...args);
    this.events = this.events.bind(this);
    this.initialize = this.initialize.bind(this);
    this.resetInvitationActions = this.resetInvitationActions.bind(this);
    this.displaySendConfirmation = this.displaySendConfirmation.bind(this);
    this.hiResendInvitation = this.hiResendInvitation.bind(this);
    this.hiRemoveInvitee = this.hiRemoveInvitee.bind(this);
  }

  events() {
    return _.extend({}, CollaboratorItemBase.prototype.events, {
      "click .collaborator-list__additional-actions": this.hiResendInvitation,
      "click .collaborator-remove__link": this.hiRemoveInvitee,
    });
  }

  initialize(attrs) {
    super.initialize(attrs);

    this.duration = attrs.duration || 4000;

    this.listenTo(this.model, "change:email", this.updateName, this);

    this.$el.addClass("collaborator-list__item--invitee");
    this.updateName(this.model, this.model.email());
    this.updateIcon(this.model, "/images/icons/png/envelope.png");
    return this.resetInvitationActions();
  }

  resetInvitationActions() {
    this.confirmingSend = false;
    const actions = this.$el.find(".collaborator-list__additional-actions");
    actions.removeClass(".collaborator-list__additional-actions--confirm");
    return actions.text("Resend invite");
  }

  displaySendConfirmation() {
    this.confirmingSend = true;
    const actions = this.$el.find(".collaborator-list__additional-actions");
    actions.addClass(".collaborator-list__additional-actions--confirm");
    return actions.text("Sent!");
  }

  hiResendInvitation() {
    if (this.confirmingSend) {
      return;
    }

    this.model.resend();

    this.displaySendConfirmation();

    return setTimeout(this.resetInvitationActions, this.duration);
  }

  hiRemoveInvitee(e) {
    e.preventDefault();
    return this.model.board().removeInvitation(this.model.id);
  }
}

module.exports = InvitationItem;
