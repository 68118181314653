const Backbone = require("backbone");
const ObjectID = require("bson-objectid");

const utils = require("../utils");
const Collaborator = require("./collaborator");
const Invitation = require("./invitation");
const Sheet = require("./sheet");
const _ = require("underscore");

const FLATLINED_AGE = 3 * 60 * 1000;

class Board extends Backbone.Model {
  get idAttribute() {
    return "_id";
  }

  get ephemeralProperties() {
    return [
      "sheets",
      "emojis",
      "activeSheetId",
      "status",
      "mode",
      "version",
      "releaseIdentifier",
      "minimalWidth",
      "minimalHeight",
      "viewerX",
      "viewerY",
      "actualWidth",
      "actualHeight",
    ];
  }

  initialize(attrs = {}) {
    this.logger = utils.Logger.instance;
    const sheets = new Backbone.Collection(_.map(attrs.sheets, (sheet) => new Sheet(_(sheet).extend({ board: this }))));
    const collaborators = new Backbone.Collection(_.map(attrs.collaborators, (collab) => new Collaborator(_(collab).extend({ board: this }))));
    const invitations = new Backbone.Collection(_.map(attrs.invitations, (invitation) => new Invitation(_(invitation).extend({ board: this }))));

    this.set("minimalHeight", 0);
    this.set("minimalWidth", 0);
    this.set("viewerX", 0);
    this.set("viewerY", 0);
    this.set("actualWidth", 0);
    this.set("actualHeight", 0);
    this.set("sheets", sheets);
    this.set("collaborators", collaborators);
    this.set("currentCollaborator", this.collaboratorForUserId(attrs.currentUserId));
    this.set("invitations", invitations);

    this.fixActiveSheetId();

    this.on("change:activeSheetId", this.onActiveSheetIdChanged, this);

    this.on("change:minimalWidth", this._setActualWidth, this);
    this.on("change:minimalHeight", this._setActualHeight, this);
    this.on("change:viewerX", this._setActualWidth, this);
    this.on("change:viewerY", this._setActualHeight, this);

    this.sheets().on("remove", () => {
      this.fixActiveSheetId();
      this.setActiveSheetId(this.activeSheetId());
    });

    this.offlineFlatlinedCollaborators = this.offlineFlatlinedCollaborators.bind(this);
    setInterval(this.offlineFlatlinedCollaborators, 10000);
  }

  collaborators() {
    return this.get("collaborators");
  }

  currentCollaborator() {
    return this.get("currentCollaborator");
  }

  currentUser() {
    return this.currentCollaborator().user();
  }

  currentUserId() {
    return this.currentUser().id;
  }

  activeSheetId() {
    return this.get("activeSheetId");
  }

  board() {
    return this;
  }

  sheets() {
    return this.get("sheets");
  }

  loadedAt() {
    return this.get("loadedAt");
  }

  mode() {
    return this.get("mode");
  }

  version() {
    return this.get("version");
  }

  releaseIdentifier() {
    return this.get("releaseIdentifier");
  }

  invitations() {
    return this.get("invitations");
  }

  activeSheet() {
    return this.findSheetById(this.activeSheetId());
  }

  fixActiveSheetId() {
    if (!this.activeSheet()) {
      this.setActiveSheetId(this.lastSheetCreated().id);
      return true;
    }

    return false;
  }

  setActiveSheetId(sheetId) {
    this.set("activeSheetId", sheetId);
  }

  sheetIdToIndex(id) {
    return (
      this.sheets()
        .map((sheet) => sheet.id)
        .indexOf(id) + 1
    );
  }

  activeSheetIndex() {
    return this.sheetIdToIndex(this.activeSheet().id);
  }

  onActiveSheetIdChanged(board, activeSheetId, options) {
    if (this.fixActiveSheetId()) {
      return;
    }

    this.currentCollaborator().addSheetId(activeSheetId, options);
    this.currentCollaborator().removeSheetId(board.previous("activeSheetId"), options);
  }

  ensureActiveSheetId(collaborator, sheetIds, options) {
    sheetIds = _(sheetIds.concat([this.activeSheetId()])).uniq();
    collaborator.set({ sheetIds }, options);
  }

  lastSheetCreated() {
    return this.sheets().reduce((memo, sheet) => {
      const isMemoMostRecent = memo ? memo.createdAt() > sheet.createdAt() : false;

      return isMemoMostRecent ? memo : sheet;
    });
  }

  userForId(id) {
    const collaborator = this.collaboratorForUserId(id);
    if (!collaborator) {
      return null;
    }

    return collaborator.user();
  }

  userIdentityForId(id) {
    const user = this.userForId(id);
    if (!user) {
      return null;
    }

    return user.activeIdentity();
  }

  collaboratorForUserId(id) {
    return this.collaborators().find((collaborator) => collaborator.user().id.toString() === id.toString());
  }

  collaboratorForId(id) {
    return this.collaborators().find((collaborator) => collaborator.id === id);
  }

  invitationForId(id) {
    return this.invitations().find((invitation) => invitation.id === id);
  }

  invitationForCollaboratorId(id) {
    return this.invitations().find((invitation) => {
      if (!invitation.collaborator()) {
        return false;
      }

      return invitation.collaborator().id === id;
    });
  }

  removeCollaborator(id, options) {
    const invitation = this.invitationForCollaboratorId(id);
    if (invitation) {
      this.invitations().remove(invitation, options);
    }
    const collaborator = this.collaboratorForId(id);
    if (collaborator) {
      this.collaborators().remove(collaborator, options);
    }
  }

  removeInvitation(id, options) {
    const invitation = this.invitationForId(id);
    if (invitation) {
      this.invitations().remove(invitation, options);
    }
  }

  userJoined(collaboratorAttributes, sheetId, options) {
    let collaborator = this.collaboratorForId(collaboratorAttributes._id);

    if (!collaborator) {
      collaborator = new Collaborator(_(collaboratorAttributes).extend({ board: this }));
      this.collaborators().add(collaborator, options);
    }

    collaborator.set("lastHeartbeat", new Date().getTime(), options);
    collaborator.addSheetId(sheetId, options);
  }

  userLeft(collaboratorAttributes, options) {
    if (!(collaboratorAttributes && collaboratorAttributes._id)) {
      return;
    }
    const collaborator = this.collaboratorForId(collaboratorAttributes._id);
    if (collaborator) {
      collaborator.set("lastHeartbeat", collaborator.lastHeartbeat() - FLATLINED_AGE, options);
      collaborator.set("sheetIds", [], options);
    }
  }

  addSheet(name) {
    const sheet = new Sheet({
      _id: ObjectID().toHexString(),
      board: this,
      name,
    });
    this.sheets().add(sheet);
    this.setActiveSheetId(sheet.id);
    return sheet;
  }

  findSheetById(id) {
    if (id) {
      return this.sheets().find((sheet) => sheet.id.toString() === id.toString());
    }
  }

  findSheetByGroupId(id) {
    const group = this.activeSheet().findGroup(id);
    if (group) return this.activeSheet();

    for (const sheet of this.sheets()) {
      const group = sheet.findGroup(id);
      if (group) return sheet;
    }
  }

  findSheetByCardId(id) {
    const card = this.activeSheet().findCard(id);
    if (card) return this.activeSheet();

    for (const sheet of this.sheets()) {
      const card = sheet.findCard(id);
      if (card) return sheet;
    }
  }

  findGroupById(id) {
    const group = this.activeSheet().findGroup(id);
    if (group) return group;

    for (const sheet of this.sheets()) {
      const group = sheet.findGroup(id);
      if (group) return group;
    }
  }

  findGroupByCardId(id) {
    const group = this.activeSheet().findGroupByCardId(id);
    if (group) return group;

    for (const sheet of this.sheets()) {
      const group = sheet.findGroupByCardId(id);
      if (group) return group;
    }
  }

  findCardById(id) {
    const card = this.activeSheet().findCard(id);
    if (card) return card;

    for (const sheet of this.sheets()) {
      const card = sheet.findCard(id);
      if (card) return card;
    }
  }

  findSheetByIndex(index) {
    return this.sheets().at(index);
  }

  offlineFlatlinedCollaborators() {
    const expiration = new Date().getTime() - FLATLINED_AGE;

    this.collaborators().forEach((collaborator) => {
      if (collaborator === this.currentCollaborator()) return;

      if (collaborator.get("sheetIds").length === 0) return;

      if (collaborator.lastHeartbeat() > expiration) return;

      this.userLeft(collaborator.attributes, { rebroadcast: true });
    });
  }

  setHilight(target) {
    this.collaborators().each((collaborator) => {
      if (collaborator !== target) {
        collaborator.set("hilight", "lolite");
      }
    });
    target.set("hilight", "hilight");
  }

  clearHilight() {
    this.collaborators().each((collaborator) => collaborator.set("hilight", null));
  }

  isPrivate() {
    return this.get("access") === "private";
  }

  updateMinimalDimensions(width, height) {
    this.set("minimalWidth", width);
    this.set("minimalHeight", height);
  }

  sheetNameFor(name) {
    const matches = name.match(/^(.*?)\s*\(\d+\)\s*$/);
    if (matches) {
      name = matches[1];
    }
    if (!this.sheets().find((sheet) => sheet.cleanName() === name)) {
      return name;
    }

    const escape = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
    };

    let inc = 2;
    while (this.sheets().find((sheet) => sheet.cleanName().match(new RegExp(`^${escape(name)}\\s*\\(${inc}\\)$`)))) {
      inc += 1;
    }
    return `${name} (${inc})`;
  }

  duplicateSheet(sheetId) {
    const curSheet = this.findSheetById(sheetId);
    const newSheet = curSheet.duplicate();
    this.sheets().add(newSheet);
    curSheet.groups().each(function (curGroup) {
      const newGroup = curGroup.duplicate();
      newSheet.groups().add(newGroup);
      curGroup.cards().each(function (curCard) {
        const newCard = curCard.duplicate();
        newGroup.cards().add(newCard);
      });
    });
    return newSheet;
  }

  _setActualWidth() {
    const minWidth = this.get("minimalWidth");

    const viewerX = this.get("viewerX");

    if (viewerX < minWidth) {
      this.set("actualWidth", minWidth);
    } else {
      if (viewerX < this.get("actualWidth")) {
        this.set("actualWidth", viewerX);
      }
    }
  }

  _setActualHeight() {
    const minHeight = this.get("minimalHeight");

    const viewerY = this.get("viewerY");

    if (viewerY < minHeight) {
      this.set("actualHeight", minHeight);
    } else {
      if (viewerY < this.get("actualHeight")) {
        this.set("actualHeight", viewerY);
      }
    }
  }
}

module.exports = Board;
