/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _ = require("underscore");
const TooltipOnboardingFlow = require("./tooltip_onboarding_flow");

class OpenDrawerFlow extends TooltipOnboardingFlow {
  static get flowName() {
    return "open drawer";
  }

  get className() {
    return "onboarding__tooltip";
  }

  get body() {
    return _.template(`\
      <div class="guide-tooltip guide-tooltip--upper-left">
        <div class='wrapper'>
            <div class='message'>Additional tools live here</div>
            <button class='dismiss button button--primary'>Roger that!</button>
        </div>
      </div>\
    `);
  }

  _positionDot() {
    return this._offsetFromParent(37, 20);
  }

  _positionTooltip() {
    return this._offsetFromParent(9, 47);
  }

  _parent() {
    return $(".presence-bar__toggle-drawer");
  }

  flowName() {
    return OpenDrawerFlow.flowName;
  }
}

module.exports = OpenDrawerFlow;
