const _ = require("underscore");
const Base = require("./base");
const CollaboratorItem = require("./collaborator_item");
const InvitationItem = require("./invitation_item");

class CollaboratorList extends Base {
  get views() {
    // initialize this.views the first time it's used (which can happen during super()).
    this._views = this._views || {};
    return this._views;
  }

  get template() {
    return _.template(`\
      <div class="collaborator-list">
        <div class="collaborator-list__title">
          Collaborators (<span class="collaborator-list__count"></span>)
        </div>
        <div class="collaborator-list__items">
        </div>
      </div>\
    `);
  }

  initialize(attributes) {
    super.initialize(attributes);

    this.listenTo(this.model.currentCollaborator(), "change:role", this.setCurrentCollaboratorRole, this);
    this.listenTo(this.model.collaborators(), "change:role", this.refreshViews, this);
    this.listenTo(this.model.collaborators(), "add", this.refreshViews, this);
    this.listenTo(this.model.collaborators(), "remove", this.refreshViews, this);
    // @listenTo @model.invitations(),   'change:acceptedAt',   @refreshViews, @
    this.listenTo(this.model.invitations(), "change:collaborator", this.refreshViews, this);
    this.listenTo(this.model.invitations(), "change:role", this.refreshViews, this);
    this.listenTo(this.model.invitations(), "add", this.refreshViews, this);
    this.listenTo(this.model.invitations(), "remove", this.refreshViews, this);

    this.render();
  }

  render() {
    this.$el.html(this.template());
    this.setCurrentCollaboratorRole();
    this.refreshViews();
  }

  rerender(items) {
    this.$el.find(".collaborator-list__count").html(this.totalCollaborators());
    const $listItems = this.$el.find(".collaborator-list__items");
    items.forEach(item => {
      if (!this.views[item.model.id]) {
        this.views[item.model.id] = this.newView(item);
      }
      const view = this.views[item.model.id];
      $listItems.append(view.el);
    });
  }

  newView(item) {
    if (item.type === "invitation") {
      return new InvitationItem({ model: item.model });
    } else {
      return new CollaboratorItem({ model: item.model });
    }
  }

  setCurrentCollaboratorRole() {
    if (this.model.currentCollaborator().isModerator()) {
      this.$el.find(".collaborator-list").removeClass("collaborator-list--editor");
    } else {
      this.$el.find(".collaborator-list").addClass("collaborator-list--editor");
    }
  }

  isModerator(item) {
    return item.model.isModerator();
  }

  totalCollaborators() {
    return this.model.collaborators().length + this.model.invitations().filter(invitation => !invitation.acceptedAt()).length;
  }

  clearViews(viewKeys) {
    viewKeys.forEach(key => {
      const view = this.views[key];
      view.remove();
      return delete this.views[key];
    });
  }

  prepareItems(type, collection) {
    const items = collection.map(collaborator => {
      return { type, model: collaborator };
    });
    return _.flatten(_.partition(items, this.isModerator));
  }

  refreshViews() {
    const collaboratorItems = this.prepareItems("collaborator", this.model.collaborators());
    const openInvitations = this.model.invitations().filter(invitation => {
      return this.model.collaborators().indexOf(invitation.collaborator()) < 0;
    });
    const invitationItems = this.prepareItems("invitation", openInvitations);

    const items = collaboratorItems.concat(invitationItems);

    const viewsToDelete = _.difference(
      Object.keys(this.views),
      items.map(item => item.model.id)
    );

    this.clearViews(viewsToDelete);

    this.rerender(items);
  }

  finalize() {
    this.clearViews(Object.keys(this.views));
  }
}

module.exports = CollaboratorList;
