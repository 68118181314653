require("../lib/jquery_extensions/droppable");
require("../lib/jquery_extensions/draggable");
require("../lib/jquery_extensions/remove_class_matching");
require("../lib/jquery_extensions/hammer");
require("../lib/jquery_extensions/emojify");

require("../vendor/fromcodepoint.js");

const _ = require("underscore");
const Draggable = require("./draggable");
const utils = require("../utils");

class Card extends Draggable {
  get className() {
    return "card card--reveal-actions-on-hover";
  }

  get template() {
    return _.template(`
      <div class='header-bar'>
        <span class='delete-btn'>&times;</span>
        <span class='notice' style='display: none'></span>
      </div>
      <div class='content'>
        <div class='viewable'></div>
        <textarea class='editable'></textarea>
      </div>
      <div class='card__footer'>
        <div class='card__authors card__action'></div>
        <div class='card__colors card__action'>
          <span class='color card__color color-0'></span>
          <span class='color card__color color-1'></span>
          <span class='color card__color color-2'></span>
          <span class='color card__color color-3'></span>
          <span class='color card__color color-4'></span>
        </div>
        <div class='card__plus-one--button card__plus-one card__action'>
          <div class='card__plus-one-count' title="Change the +1 count for this card">+1</div>
        </div>
        <div class='card__plus-one--static-text card__plus-one tooltip-parent'>
          <div class='card__plus-one-count'></div>
          <div class='tooltip tooltip--right'>
            <div class='tooltip__avatars'></div>
          </div>
        </div>
      </div>
    `);
  }

  get userIconTemplate() {
    return _.template(`
      <img class="avatar" data-initials="<%= userIdentity.initials() %>" src="<%= userIdentity.avatar() %>" title="<%- userIdentity.displayName() %>"/>\
    `);
  }

  get events() {
    this._events = this._events || {
      // human interaction event
      click: "hiClick",
      mousedown: "hiMouseDown",
      mouseup: "hiMouseUp",
      mousemove: "hiMouseMove",
      "click .color": "hiChangeColor",
      "input .editable": "hiChangeText",
      "propertychange .editable": "hiChangeText",
      "change .editable": "hiChangeText",
      "blur .editable": "hiUnFocusText",
      "mousedown .editable": "hiActivateText",
      "click .delete-btn": "hiSoftDelete",
      "click .card__plus-one--button": "hiIncrementPlusCount",
      "mouseover .card__plus-one--static-text": "hiCheckTooltip",
      "mouseleave .card__plus-one--static-text": "hiDelayTooltip",
    };
    return this._events;
  }

  // for some reason the tap event for plus-one isn't registering, so we
  // have to add the touchstart event as well. -mike
  get touchEvents() {
    this._touchEvents = this._touchEvents || {
      "tap .color": "hiChangeColor",
      "tap .delete-btn": "hiSoftDelete",
      "tap .card__plus-one--button": "hiIncrementPlusCount",
      "touchstart .card__plus-one--button": "hiIncrementPlusCount",
    };

    return this._touchEvents;
  }

  attributes() {
    return {
      id: `card-${this.model.id}`,
      "data-id": this.model.id,
    };
  }

  initialize(attributes) {
    super.initialize(attributes);
    this.listenTo(this, "attach", this.onAttach, this);

    const creator = this.model.get("creator");
    this.collaborator = this.model.board().collaboratorForUserId(creator);
  }

  finalize() {
    this.undelegateEvents();
    this.stopListening();
  }

  startListening() {
    this.listenTo(this.model.sheet(), "change:focusedCardId", this.updateFocus, this);

    this.listenTo(this.model, "change:_id", this.updateId, this);
    this.listenTo(this.model, "change:colorIndex", this.updateColor, this);
    this.listenTo(this.model, "change:text", this.updateText, this);
    this.listenTo(this.model, "change:x", this.updateX, this);
    this.listenTo(this.model, "change:y", this.updateY, this);
    this.listenTo(this.model, "change:hover", this.updateHover, this);
    this.listenTo(this.model, "change:state", this.updateState, this);
    this.listenTo(this.model, "change:plusAuthors", this.updatePlusAuthors, this);
    this.listenTo(this.model, "change:authors", this.updateAuthors, this);
    this.listenTo(this.model, "change:isSelected", this.updateSelected, this);

    this.listenTo(this.collaborator, "change:hilight", (...args) => this.updateHilight(...args), this.collaborator);
  }

  onAttach() {
    this.render();
    this.initializeEmojis();
    this.initializeDraggable();
    this.initializeLocks();
    if (this.model.get("creator") === this.model.currentUserId()) {
      this.$el.addClass("mine");
    }
    this.startListening();
  }

  dispose() {
    this.remove();
    this.calculateMinimalBoardSize();
  }

  initializeLocks() {
    this.dragLock = this.createDragLock();
    this.editLock = this.createEditLock(".editable");
  }

  initializeEmojis() {
    this.$(".editable").emojify(this.model.board());
  }

  boardDiv(next, count) {
    if (!count) {
      count = 0;
    }
    const div = this.$el.closest(".board");
    count += 1;
    if (count === 2 || div.length > 0) {
      return next(div);
    }
    setTimeout(() => this.boardDiv(next, count), 250);
  }

  initializeDraggable() {
    return this.$el.draggable({
      canvas: this.$el.closest(".sheet"),
      isTarget: target => {
        if ($(target).is(".content *")) {
          return false;
        }
        if ($(target).is(".color")) {
          return false;
        }
        if ($(target).is(".card__plus-one--button .card__plus-one-count")) {
          return false;
        }
        if ($(target).is(".delete-btn")) {
          return false;
        }
        return true;
      },
      isOkToDrag: () => {
        // dont allow card to drag if its the only one in its group (allow the group to drag)
        return this.model.group().cards().length > 1 && !this.model.isBeingDragged();
      },
      isDragCanceled: () => {
        return this.model.get("state") !== "dragging";
      },
      onMouseDown: () => {},
      onMouseUp: () => {},
      onMouseMove: () => {
        this.onDrag();
      },
      startedDragging: () => {
        this.startedDragging();
      },
      stoppedDragging: () => {},
      dropped: () => {
        this.model.drop();
        this.updateZIndex();
      },
    });
  }

  render() {
    this.$el.html(this.template());
    this.$el.hammer();
    this.updateText(this.model, this.model.get("text"));
    this.updatePosition(this.model.get("x"), this.model.get("y"));
    this.updateColor(this.model, this.model.get("colorIndex"));
    this.updateAuthors(this.model, this.model.get("authors"));
    this.updatePlusAuthors(this.model, this.model.get("plusAuthors"));
    this.updateCreator(this.model, this.model.get("creator"));
    this.updateHilight(this.collaborator, this.collaborator ? this.collaborator.get("hilight") : undefined);
    this.updateFocus();
    this.updateZIndex();
    this.updateSelected();
    return this;
  }

  updateId(card, id) {
    this.$el.attr("id", `card-${id}`);
    this.$el.attr("data-id", id);
  }

  updateColor(card, color) {
    this.$el.removeClassMatching(/color-\d+/);
    this.$el.addClass(`color-${color != null ? color : 2}`);
  }

  updateFocus() {
    if (this.model.isFocused()) {
      this.enableEditing();
    } else {
      this.disableEditing();
    }
  }

  updateZIndex() {
    let zIndex;
    if (this.model.isDragging()) {
      zIndex = utils.zIndexManager.OBJECT_DRAGGING_Z_INDEX;
    } else if (this.model.isBeingDragged()) {
      zIndex = utils.zIndexManager.OBJECT_BEING_DRAGGED_Z_INDEX;
    } else {
      zIndex = "";
    }

    this.$el.css("z-index", zIndex);
  }

  updateText(card, text, options) {
    if (this.$(".editable").val() !== text) {
      this.$(".editable").val(text);
    }

    const sanitized = text.replace(/</g, "&lt;");
    this.$(".viewable").html(sanitized);
    this.updateViewable();

    this.$(".editable").css("height", this.$(".viewable").outerHeight());

    this.updateKeywordClass(card);

    if (options && options.rebroadcast && !options.replay) {
      this.model.sheet().unfocusCard(this.model);
      const userIdentity = this.model.board().userIdentityForId(card.get("author"));
      if (userIdentity) {
        this.editLock.lock(1000, userIdentity.avatar(), `${userIdentity.displayName()} is typing...`);
      }
    }
  }

  updateKeywordClass(card) {
    this.$el.removeClass(card.specialKeywords.join(" "));
    const specialKeyword = card.findSpecialKeyword();
    if (!specialKeyword) return;
    this.$el.addClass(specialKeyword);
  }

  updateViewable() {
    const esc = str =>
      // eslint-disable-next-line no-useless-escape
      str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    const markdownify = function(str, delim, tag, preserveInternal) {
      if (!preserveInternal) {
        preserveInternal = false;
      }
      const d = esc(delim);
      const c = esc(delim[0]);
      const b = preserveInternal ? "\\b" : "";
      const re = new RegExp(`${b}${d}(\\S[^${c}]*)${d}`, "g");
      return str.replace(re, `<${tag}>$1</${tag}>`);
    };

    let content = this.$(".editable").val();
    content = content.replace(/<([^-])/g, "&lt;$1");
    content = content.replace(/^([ \t]*[-\\*]+){3,}(\s*)$/gm, "<hr/>$2");
    content = content.replace(/^[ \t]*->(.*)<-(\s*)$/gm, "<span style='text-align: center; display: inline-block; width: 100%'>$1</span>$2");
    content = markdownify(content, "**", "strong");
    content = markdownify(content, "__", "strong");
    content = markdownify(content, "*", "em");
    content = markdownify(content, "_", "em", true);
    content = markdownify(content, "~~", "strike");
    content = content.replace(/^(\s*)[-\\*](\s)/gm, "$1&#x25cf;$2");
    content = content.replace(/(https?:\/\/[^\s<]+)/gi, "<a href='$1' target='offsite'>$1</a>");
    content = content.replace(/(\r?\n)/g, "<br/>$1");
    content = content.replace(/^\s+/gm, x => new Array(x.length).join("&nbsp;"));
    content = content.replace(/\s{2,}/gm, x => new Array(x.length + 1).join("&nbsp;"));
    this.$(".viewable").html(content);

    this.$(".viewable").height("auto");
    // This crazy regex below will burn your CPU to the ground if you make a sticky with lots of newlines and then
    // try to type a character in to the middle of it.  It's pretty neat actually. -mike and sid
    // if content.match /<br\/>(\s*(<\S+>\s*<\/\S+>\s*|<\S+\/>)?\s*)*$/i
    if (content.match(/<[bh]r\/>\s*$/i)) {
      const height = this.$(".viewable").outerHeight();
      this.$(".viewable").height(height + parseInt(this.$(".viewable").css("line-height")));
    }
  }

  updateX(card, x, options) {
    this.updatePosition(x, card.get("y"), options);
  }

  updateY(card, y, options) {
    this.updatePosition(card.get("x"), y, options);
  }

  updatePosition(x, y, options) {
    this.moveTo({ x, y });
    if (options && options.rebroadcast && !options.replay) {
      const userIdentity = this.model.board().userIdentityForId(this.model.get("author"));
      if (userIdentity) {
        this.dragLock.lock(1000, userIdentity.avatar(), userIdentity.displayName());
      }
      this.updateZIndex();
    }
  }

  updateHover(card, hover) {
    this.$el.removeClassMatching(/hover-\w+/);
    if (hover) {
      this.$el.addClass(`hover-${hover}`);
    }
  }

  updateState(card, state) {
    const previous = card.previous("state");
    if (previous) {
      this.$el.removeClass(previous);
    }
    if (state) {
      this.$el.addClass(state);
    }
    this.updateZIndex();

    if (state === "dragging") {
      this.model.moveTo(this.left(), this.top());
    }
  }

  updatePlusAuthors(card, plusAuthors) {
    const $plus = this.$(".card__plus-one--static-text");
    const $plusCount = this.$(".card__plus-one--static-text .card__plus-one-count");
    const $plusAuthors = this.$(".card__plus-one--static-text .tooltip__avatars");
    if (plusAuthors.length === 0) {
      $plusCount.text("");
      $plusAuthors.empty();
      $plus.hide();
    } else {
      $plusCount.text(`+${plusAuthors.length}`);
      $plusAuthors.empty();
      _(plusAuthors).each(author => {
        const userIdentity = this.model.board().userIdentityForId(author);
        if (userIdentity) {
          return $plusAuthors.append(this.userIconTemplate({ userIdentity }));
        }
      });
      $plusAuthors.find("img.avatar").initials();
      $plus.show();
    }

    $plusAuthors.removeClass(function(index, css) {
      const matches = css.match(/tooltip__avatars--\d/);
      return (matches || []).join(" ");
    });

    const authorClass = `tooltip__avatars--${Math.min(plusAuthors.length, 3)}`;
    $plusAuthors.addClass(authorClass);

    if (plusAuthors.indexOf(this.model.currentUserId()) > -1) {
      this.$(".card__plus-one--button .card__plus-one-count").html("-1");
    } else {
      this.$(".card__plus-one--button .card__plus-one-count").html("+1");
    }
  }

  updateAuthors(card, authors) {
    if (authors.length === 0) {
      return;
    }

    const $authors = this.$(".card__authors");
    $authors.empty();
    for (const author of authors) {
      const userIdentity = this.model.board().userIdentityForId(author);
      if (userIdentity) {
        $authors.append(this.userIconTemplate({ userIdentity }));
      }
    }
    $authors.find("img.avatar").initials();
  }

  updateCreator(card, creator) {
    this.$el.attr("creator", creator);
  }

  updateHilight(collaborator, hilight) {
    this.$el.removeClass("hilight lolite");
    if (hilight) {
      this.$el.addClass(hilight);
    }
  }

  updateSelected() {
    this.$el.toggleClass("card__selected--self", this.model.isSelected());
  }

  _getGroupZIndex() {
    return parseInt(this.model.group().get("z") * 10);
  }

  enableEditing() {
    const z = this._getGroupZIndex();
    this.$(".viewable").css("z-index", z + utils.zIndexManager.CARD_INACTIVE_Z_OFFSET);
    this.$(".editable").css("z-index", z + utils.zIndexManager.CARD_ACTIVE_Z_OFFSET);
    this.$el.addClass("card--editing");
    this.$(".editable").focus();
  }

  disableEditing() {
    const z = this._getGroupZIndex();
    this.$(".viewable").css("z-index", z + utils.zIndexManager.CARD_ACTIVE_Z_OFFSET);
    this.$(".editable").css("z-index", z + utils.zIndexManager.CARD_INACTIVE_Z_OFFSET);
    this.$el.removeClass("card--editing");
  }

  // human interaction event handlers

  hiSoftDelete(event) {
    this.model.softDelete();
    event.stopPropagation();
  }

  hiChangeColor(event) {
    let colorIndex = $(event.target)
      .attr("class")
      .match(/color-(\d+)/)[1];
    colorIndex = colorIndex ? parseInt(colorIndex, 10) : undefined;
    this.model.colorize(colorIndex, { shouldBroadcast: true });
    event.stopPropagation();
    if (this.model.wasRecentlyFocused()) {
      this.model.sheet().focusCard(this.model);
    }
    if (colorIndex === 0 && this.model.get("text") === "bugsnag") {
      this.logger.warn("Sending test error to bugsnag");
      Bugsnag.notify("Test Error", "This error was triggered on purpose.");
    }
  }

  hiChangeText() {
    this.model.type(this.$(".editable").val());
  }

  hiUnFocusText() {
    this.model.sheet().unfocusCard(this.model);
  }

  hiActivateText(event) {
    if ($(event.target).is("a")) {
      return false;
    }
    this.model.sheet().focusCard(this.model);
  }

  hiIncrementPlusCount(event) {
    this.model.plusOne();
    event.preventDefault();
    event.stopPropagation();
  }

  hiCheckTooltip() {
    const $plus = this.$(".card__plus-one--static-text");
    const $container = this.$(".tooltip-container");
    const $tooltip = this.$(".tooltip");
    const windowWidth = $(window).width();
    const plusRect = $plus.get(0).getBoundingClientRect();
    const tooltipWidth = $tooltip.width();
    $container.toggleClass("flip", plusRect.right + tooltipWidth > windowWidth);

    clearTimeout(this.tooltipTimeout);
    $tooltip.show();
    this.$el.css({ overflow: "visible" });
  }

  hiDelayTooltip() {
    const $tooltip = this.$(".tooltip");

    this.tooltipTimeout = setTimeout(() => {
      $tooltip.hide();
      this.$el.css({ overflow: "hidden" });
    }, 200);
  }

  hiClick(event) {
    this.hiBringForward(event);
    this.hiSelect(event);
    super.hiClick();
  }

  hiBringForward(event) {
    if ($(event.target).is(".delete-btn")) {
      return;
    }

    this.model.group().bringForward();
  }

  hiSelect(event) {
    if (!this.isValidClick(event)) return;

    if (event.ctrlKey || event.metaKey) {
      this.model.toggleSelect();
      return;
    }

    const target = $(event.target);
    if (!(target.is(".delete-btn") || target.is(".editable") || target.is(".card__plus-one"))) {
      this.model.sheet().deselectAll();
      this.model.select();

      // Clicking on a card toggles its selected state. It doesn't cause any
      // previously-active INPUT or TEXTAREA to become blurred. This can cause
      // some confusion when the user copies to clipboard; did they just copy
      // the previously-selected text, or did they copy the now-selected card?
      // To reduce ambiguity, we simply blur any previously-active element when
      // a card is selected.
      if (document.activeElement) {
        document.activeElement.blur();
      }
    }
  }
}

module.exports = Card;
