const io = require("socket.io-client");

const utils = require("./utils");
const models = require("./models");
const views = require("./views");
const _ = require("underscore");
const ObjectID = require("bson-objectid");

let Handler = (function () {
  let HEARTBEAT_PERIOD;
  let SENDER_PERIOD;
  let MISSING_ENTITY_TIMEOUT;
  let COMMAND_TIMEOUT;
  let MSG_CONTEXT_PROPS;
  Handler = class Handler {
    static initClass() {
      HEARTBEAT_PERIOD = 1000 * 30;
      SENDER_PERIOD = 250;
      MISSING_ENTITY_TIMEOUT = 250;
      COMMAND_TIMEOUT = 1000 * 30;

      MSG_CONTEXT_PROPS = ["boardId", "sheetId", "groupId", "uid", "version", "release", "replay"];
    }

    constructor(namespace, board, logger) {
      this.setHeartbeatPeriod = this.setHeartbeatPeriod.bind(this);
      this.setSenderPeriod = this.setSenderPeriod.bind(this);
      this.setMissingEntityTimeout = this.setMissingEntityTimeout.bind(this);
      this.setDisconnectedTimeout = this.setDisconnectedTimeout.bind(this);
      this.createHeartbeat = this.createHeartbeat.bind(this);
      this.clearHeartbeat = this.clearHeartbeat.bind(this);
      this.createSender = this.createSender.bind(this);
      this.clearSender = this.clearSender.bind(this);
      this.refresh = this.refresh.bind(this);
      this.reload = this.reload.bind(this);
      this.createSocket = this.createSocket.bind(this);
      this.send = this.send.bind(this);
      this.sendNow = this.sendNow.bind(this);
      this.sendSoon = this.sendSoon.bind(this);
      this.sendHeartbeat = this.sendHeartbeat.bind(this);
      this.sendAll = this.sendAll.bind(this);
      this.missingEntity = this.missingEntity.bind(this);
      this.entityCreated = this.entityCreated.bind(this);
      this.handleMissingEntity = this.handleMissingEntity.bind(this);
      this.onError = this.onError.bind(this);
      this.onStatus = this.onStatus.bind(this);
      this.onConnect = this.onConnect.bind(this);
      this.onDisconnect = this.onDisconnect.bind(this);
      this.onJoin = this.onJoin.bind(this);
      this.onLeave = this.onLeave.bind(this);
      this.onHeartbeat = this.onHeartbeat.bind(this);
      this.onReload = this.onReload.bind(this);
      this.onProcessed = this.onProcessed.bind(this);
      this.onBoardUpdate = this.onBoardUpdate.bind(this);
      this.onBoardDelete = this.onBoardDelete.bind(this);
      this.onSheetCreate = this.onSheetCreate.bind(this);
      this.onSheetUpdate = this.onSheetUpdate.bind(this);
      this.onSheetDelete = this.onSheetDelete.bind(this);
      this.onSheetMove = this.onSheetMove.bind(this);
      this.onGroupCreate = this.onGroupCreate.bind(this);
      this.onGroupUpdate = this.onGroupUpdate.bind(this);
      this.onCardCreate = this.onCardCreate.bind(this);
      this.onCardUpdate = this.onCardUpdate.bind(this);
      this.onCardMove = this.onCardMove.bind(this);
      this.onCollaboratorUpdate = this.onCollaboratorUpdate.bind(this);
      this.onCollaboratorDelete = this.onCollaboratorDelete.bind(this);
      this.onInvitationCreate = this.onInvitationCreate.bind(this);
      this.onInvitationUpdate = this.onInvitationUpdate.bind(this);
      this.onInvitationDelete = this.onInvitationDelete.bind(this);
      this.onUserUpdate = this.onUserUpdate.bind(this);
      this.onCommandTheme = this.onCommandTheme.bind(this);
      this.onCommandSummary = this.onCommandSummary.bind(this);
      this.boardIdentity = this.boardIdentity.bind(this);
      this.sheetIdentity = this.sheetIdentity.bind(this);
      this.refreshMessage = this.refreshMessage.bind(this);
      this.userMessage = this.userMessage.bind(this);
      this.message = this.message.bind(this);
      this.locks = this.locks.bind(this);
      this.context = this.context.bind(this);
      this.deleteMessage = this.deleteMessage.bind(this);
      this.namespace = namespace;
      this.board = board;
      if (logger == null) {
        logger = utils.Logger.instance;
      }
      this.logger = logger;
      this.user = this.board.currentUser();
      this.logger.user = this.user;
    }

    setHeartbeatPeriod(heartbeatPeriod) {
      this.heartbeatPeriod = heartbeatPeriod;
    }

    setSenderPeriod(senderPeriod) {
      this.senderPeriod = senderPeriod;
    }

    setMissingEntityTimeout(missingEntityTimeout) {
      this.missingEntityTimeout = missingEntityTimeout;
    }

    setDisconnectedTimeout(disconnectedTimeout) {
      this.disconnectedTimeout = disconnectedTimeout;
    }

    initialize(initializeCallback) {
      let message;
      this.initializeCallback = initializeCallback;
      this.socket = this.createSocket();
      this.logger.socket = this.socket;
      this.sendQueue = {};
      this.sendQueueCounter = 0;
      this.missingEntities = {};

      if (!this.heartbeatPeriod) {
        this.heartbeatPeriod = HEARTBEAT_PERIOD;
      }
      if (!this.senderPeriod) {
        this.senderPeriod = SENDER_PERIOD;
      }
      if (!this.missingEntityTimeout) {
        this.missingEntityTimeout = MISSING_ENTITY_TIMEOUT;
      }

      const _on = this.socket.on.bind(this.socket);
      this.socket.on = (event, handler) => {
        const decoratedHandler = (message) => {
          this.logger.debug(`Handling event: ${event}`);
          utils.bugsnagger.leaveBreadcrumb(`receive: ${event}`, message);
          if (handler) {
            return handler(event, message);
          }
        };
        return _on(event, decoratedHandler);
      };

      this.socket.on("error", this.onError);
      this.socket.on("status", this.onStatus);
      this.socket.on("connect", this.onConnect);
      this.socket.on("disconnect", this.onDisconnect);
      this.socket.on("join", this.onJoin);
      this.socket.on("leave", this.onLeave);
      this.socket.on("heartbeat", this.onHeartbeat);
      this.socket.on("reload", this.onReload);
      this.socket.on("processed", this.onProcessed);
      this.socket.on("board.update", this.onBoardUpdate);
      this.socket.on("board.delete", this.onBoardDelete);
      this.socket.on("sheet.create", this.onSheetCreate);
      this.socket.on("sheet.update", this.onSheetUpdate);
      this.socket.on("sheet.delete", this.onSheetDelete);
      this.socket.on("sheet.move", this.onSheetMove);
      this.socket.on("group.create", this.onGroupCreate);
      this.socket.on("group.update", this.onGroupUpdate);
      this.socket.on("card.create", this.onCardCreate);
      this.socket.on("card.update", this.onCardUpdate);
      this.socket.on("card.move", this.onCardMove);
      this.socket.on("collaborator.update", this.onCollaboratorUpdate);
      this.socket.on("collaborator.delete", this.onCollaboratorDelete);
      this.socket.on("invitation.create", this.onInvitationCreate);
      this.socket.on("invitation.update", this.onInvitationUpdate);
      this.socket.on("invitation.delete", this.onInvitationDelete);
      this.socket.on("user.update", this.onUserUpdate);
      this.socket.on("command.theme", this.onCommandTheme);
      this.socket.on("command.summary", this.onCommandSummary);

      $(window).on("beforeunload", () => {
        // don't show disconnect status for user initiated page reload
        this.socket.removeListener("disconnect", this.onDisconnect);
        return undefined;
      }); // avoids popup confirmation for leaving page

      const handleCardEvents = (card) => {
        if (!card.eventsInitialized) {
          card.on("change", (card, options) => this.send("card.update", this.cardMessage(card), options));
          card.eventsInitialized = true;
        }
      };

      const handleGroupEvents = (group) => {
        group.on("change", (group, options) => this.send("group.update", this.groupMessage(group), options));

        const cards = group.cards();
        cards.each(handleCardEvents);
        cards.on("add", handleCardEvents);
        cards.on("add", (card, cards, options) => {
          if (options != null ? options.movecard : undefined) {
            this.send("card.move", this.cardMoveMessage(card), options);
          } else {
            this.send("card.create", this.cardMessage(card), options);
          }
        });
      };

      const handleSheetEvents = (sheet) => {
        sheet.on("change", (sheet, options) => this.send("sheet.update", this.sheetMessage(sheet), options));
        sheet.on("destroy", (sheet, sheets, options) => this.send("sheet.delete", this.deleteMessage(sheet), options));

        const groups = sheet.groups();
        groups.each(handleGroupEvents);
        groups.on("add", handleGroupEvents);
        groups.on("add", (group, groups, options) => {
          this.send("group.create", this.groupMessage(group), options);
        });
      };

      const handleOnboardingEvents = (onboardedFeatures) => {
        onboardedFeatures.on("add", (model, _collection, _extra) => {
          message = {
            user: { id: this.user.id },
            board: { id: this.board.id },
            featureName: model.featureName(),
          };
          this.send("user.update", message);
        });
      };

      const handleBoardEvents = (board) => {
        board.on("destroy", (board, things, options) => {
          this.send("board.delete", this.deleteMessage(board), options);
        });

        board.on("change", (board, options) => {
          this.send("board.update", this.boardMessage(board), options);
        });

        board.collaborators().on("change:command", (collaborator, value, options) => {
          if (!value) {
            return;
          }

          const message = _(this.userMessage()).extend(value);
          message.collaboratorId = collaborator.id;
          this.send(`command.${value.type}`, message, options);

          const timeout = () => {
            this.board.currentCollaborator().set("command", null);
          };

          if (this.clearCommandTimeout) {
            clearTimeout(this.clearCommandTimeout);
          }
          this.clearCommandTimeout = setTimeout(timeout, COMMAND_TIMEOUT);
        });

        board.collaborators().on("change", (collaborator, options) => {
          message = _(this.userMessage()).extend({
            collaboratorId: collaborator.id,
            changed: _(collaborator.changed).omit(collaborator.ephemeralProperties),
          });
          if (!_(message.changed).isEmpty()) {
            this.send("collaborator.update", message, options);
          }
        });

        board.collaborators().on("remove", (collaborator, collaborators, options) => {
          message = _(this.userMessage()).extend({
            collaboratorId: collaborator.id,
          });
          this.send("collaborator.delete", message, options);
        });

        board.invitations().on("add", (invitation, invitations, options) => {
          this.send("invitation.create", this.invitationMessage(invitation), options);
        });

        board.invitations().on("change", (invitation, options) => {
          this.send("invitation.update", this.invitationMessage(invitation), options);
        });

        board.invitations().on("resend", (invitation, options) => {
          this.send("invitation.resend", _(this.userMessage()).extend({ _id: invitation.id }), options);
        });

        board.invitations().on("remove", (invitation, invitations, options) => {
          this.send("invitation.delete", _(this.userMessage()).extend({ _id: invitation.id }), options);
        });

        const sheets = board.sheets();
        sheets.each(handleSheetEvents);
        sheets.on("add", handleSheetEvents);
        sheets.on("add", (sheet, sheets, options) => {
          this.send("sheet.create", this.sheetMessage(sheet), options);
        });
      };

      handleBoardEvents(this.board);
      handleOnboardingEvents(this.board.currentUser().get("onboardedFeatures"));

      if (this.initializeCallback && this.board.mode() === "offline") {
        this.initializeCallback();
      }
    }

    detach() {
      this.socket.removeAllListeners();
    }

    createHeartbeat(period = this.heartbeatPeriod) {
      if (this.heartbeat) return;

      this.clearHeartbeat();
      this.heartbeat = setInterval(this.sendHeartbeat, period);
      this.sendHeartbeat();
    }

    clearHeartbeat() {
      clearInterval(this.heartbeat);
      this.heartbeat = undefined;
    }

    createSender(period = this.senderPeriod) {
      if (this.sender) return;

      this.clearSender();
      this.sender = setInterval(this.sendAll, period);
    }

    clearSender() {
      clearInterval(this.sender);
      this.sender = undefined;
    }

    refresh() {
      // this happens when a user first joins and gets their first heartbeat back
      if (!this.lastHeartbeatReceived) {
        this.reload();
        return;
      }

      if (this.lastDisconnectReceived) {
        const disconnectDelta = new Date().getTime() - this.lastDisconnectReceived;
        const message = Object.assign(this.refreshMessage(), { delta: disconnectDelta + 5 * 1000 });
        this.send("refresh", message);
        delete this.lastDisconnectReceived;
        return;
      }

      const heartbeatDelta = new Date().getTime() - this.lastHeartbeatReceived;
      if (heartbeatDelta > this.heartbeatPeriod * 2) {
        const message = Object.assign(this.refreshMessage(), { delta: heartbeatDelta + this.heartbeatPeriod });
        this.send("refresh", message);
      }
    }

    reload() {
      const message = Object.assign(this.refreshMessage(), { since: this.board.loadedAt() - 10 * 1000 });
      this.send("refresh", message);
    }

    createSocket() {
      // we use window.location.host to fix a bug in socket.io-client 1.3.4 (fixed in master)
      if (this.board.mode() === "offline") {
        return new utils.OfflineSocket();
      }
      return io.connect(`${window.location.host}${this.namespace}`);
    }

    send(name, message, options) {
      if (!message) {
        return;
      }

      // console.log(`send: ${name} - ${JSON.stringify(options)} - ${JSON.stringify(message)}`);
      if (options && options.rebroadcast) {
        return;
      }

      message.uid = ObjectID().toHexString();
      message.version = this.board.version();
      message.release = this.board.releaseIdentifier();

      if (name === "card.update" || name === "group.update") {
        this.sendSoon(name, message);
      } else {
        this.sendNow(name, message);
      }

      utils.bugsnagger.leaveBreadcrumb(`send: ${name}`, message);
    }

    sendNow(name, message) {
      const logmsg = `send: ${name} - ${JSON.stringify(message)}`;
      if (name === "card.update" || name === "group.update") {
        this.logger.debug(logmsg);
      } else {
        this.logger.info(logmsg);
      }

      this.socket.emit(name, message);
    }

    sendSoon(name, message) {
      const merge = (oldMessage, newMessage) => _(oldMessage).extend(newMessage);
      const key = `${name}-${message._id != null ? message._id : message.userId}`;

      const oldMessage = (this.sendQueue[key] && this.sendQueue[key].message) || {};
      const merged = merge(oldMessage, message);

      this.sendQueue[key] = {
        message: merged,
        counter: ++this.sendQueueCounter,
      };

      this.createSender();
    }

    sendHeartbeat() {
      if (!this.lastHeartbeatSent || !(Date.now() - this.lastHeartbeatSent <= 1000)) {
        this.logger.debug("sending heartbeat");
        this.send("heartbeat", this.userMessage());
        this.lastHeartbeatSent = Date.now();
      }
    }

    sendAll() {
      // send messages in the order in which they were enqueued
      const entries = Object.entries(this.sendQueue);
      if (entries.length === 0) {
        return;
      }

      const byCounter = (e1, e2) => e1[1].counter - e2[1].counter;
      entries.sort(byCounter);

      const extract = (key) => key.substr(0, key.indexOf("-"));
      entries.forEach((entry) => {
        const [key, data] = entry;
        this.sendNow(extract(key), data.message);
      });

      this.sendQueue = {};

      this.clearSender();
    }

    missingEntity(event, missing, message) {
      const idProp = event.indexOf(missing) === 0 ? "_id" : `${missing}Id`;
      const idVal = message[idProp];

      this.missingEntities[idVal] = this.missingEntities[idVal] || [];
      this.missingEntities[idVal].push({ event, missing, message, ts: Date.now() });

      const handleExpired = () => {
        for (const id in this.missingEntities) {
          const vals = this.missingEntities[id];
          const notExpired = [];
          for (const val of vals) {
            if (Date.now() - val.ts >= this.missingEntityTimeout) {
              // expired, handle it
              this.handleMissingEntity(val.event, val.missing, val.message);
            } else {
              // not expired, put it back
              notExpired.push(val);
            }
          }
          this.missingEntities[id] = notExpired;
        }
      };

      return this.missingEntityInterval || (this.missingEntityInterval = setInterval(handleExpired, this.missingEntityTimeout / 3));
    }

    entityCreated(event, message) {
      const id = message._id;
      const waiting = this.missingEntities[id] || [];
      for (const wait of Array.from(waiting)) {
        const listeners = this.socket.listeners(wait.event);
        for (const listener of Array.from(listeners)) {
          listener(wait.message);
        }
      }
      this.missingEntities[id] = [];
    }

    handleMissingEntity(event, missing, message) {
      const msg = _(message).pick("_id", "boardId", "sheetId", "groupId");
      msg.author = this.board.currentUserId();

      const matches = (m, ...e) => m === missing && e.indexOf(event) >= 0;

      if (matches("group", "card.update", "card.create", "card.move")) {
        msg._id = message.groupId;
        delete msg.groupId;
      }

      if (matches("sheet", "card.update", "card.create", "card.move", "group.update", "group.create")) {
        msg._id = message.sheetId;
        delete msg.sheetId;
        delete msg.groupId;
      }

      this.send(`${missing}.missing`, msg);

      this.logger.warn(`[ ${event} ] missing ${missing}: ${JSON.stringify(message)}`);
    }

    onError(event, error) {
      this.logger.error(JSON.stringify(error));
    }

    onStatus(event, status) {
      if (status.code === 401 && status.reload) {
        this.clearHeartbeat();
        this.clearSender();

        const title = "Hold on a second";
        const message = "It looks like your session expired.<br/>Please log in again.";
        const closeAction = () => window.location.reload();

        new views.Modal({
          title,
          message,
          priority: 10,
          closeAction,
          buttons: [{ text: "Okay" }],
        });

        setTimeout(closeAction, 60000);
      }
    }

    onConnect() {
      this.send("join", this.userMessage());
    }

    onDisconnect() {
      this.disconnectedTimer = setTimeout(
        () => {
          return this.board.set("status", "Disconnected");
        },
        this.disconnectedTimeout != null ? this.disconnectedTimeout : 5000
      );
      this.clearSender();
      this.clearHeartbeat();
      this.lastDisconnectReceived = new Date().getTime();
    }

    onJoin(event, message) {
      if (!message.collaborator) {
        this.logger.warn("Missing join message body");
        return;
      }

      if (message.collaborator._id === this.board.currentCollaborator().id) {
        clearTimeout(this.disconnectedTimer);
        this.board.unset("status");
        this.createHeartbeat();
        if (this.initializeCallback != null) {
          this.initializeCallback();
        }
        window.router.refresh();
        delete this.initializeCallback;
      } else {
        this.board.userJoined(message.collaborator, message.sheetId, {
          rebroadcast: true,
          replay: message.replay,
        });
        this.sendHeartbeat();
      }
    }

    onLeave(event, message) {
      if (message.collaborator._id !== this.board.currentCollaborator().id) {
        this.board.userLeft(message.collaborator, { rebroadcast: true, replay: message.replay });
        this.sendHeartbeat();
      }
    }

    onHeartbeat(event, message) {
      if (this.board.version() !== message.version) {
        this.board.set("version", message.version);
        this.board.set("mode", "stale");
      }
      this.board.userJoined(message.collaborator, message.sheetId, {
        rebroadcast: true,
        replay: message.replay,
      });
      this.refresh();
      this.lastHeartbeatReceived = new Date().getTime();
    }

    onReload(_event, _message) {
      this.board.set("mode", "reload");
    }

    onProcessed(_event, _message) {
      // console.log(`${_event} - ${JSON.stringify(_message)}`);
    }

    onBoardUpdate(event, message) {
      this.board.set(_(message).omit("_id"), { rebroadcast: true, replay: message.replay });
    }

    onBoardDelete(_event, _message) {
      this.clearHeartbeat();
      this.clearSender();
      new views.Modal({
        title: "Board Deleted",
        message: "This board has been deleted. <br /> You will be redirected to the dashboard.",
        closeAction() {
          return (window.location = "/");
        },
        buttons: [{ text: "Okay" }],
      });
    }

    onSheetCreate(event, message) {
      let sheet = this.board.findSheetById(message._id);
      if (sheet) {
        sheet.set(_(message).omit(MSG_CONTEXT_PROPS), { rebroadcast: true, replay: message.replay });
      } else {
        sheet = new models.Sheet(_.extend({ board: this.board }, _.omit(message, MSG_CONTEXT_PROPS)));
        this.board.sheets().add(sheet, { rebroadcast: true, replay: message.replay });
      }
      this.entityCreated(event, message);
    }

    onSheetUpdate(event, message) {
      const sheet = this.board.findSheetById(message._id);
      if (!sheet) {
        return this.missingEntity(event, "sheet", message);
      }
      sheet.set(_(message).omit(MSG_CONTEXT_PROPS), { rebroadcast: true, replay: message.replay });
    }

    onSheetDelete(event, message) {
      const sheet = this.board.findSheetById(message._id);
      if (!sheet) {
        return;
      }

      const sheetId = sheet.id;
      const activeSheetId = this.board.activeSheetId();
      this.board.sheets().remove(sheet, { rebroadcast: true, replay: message.replay });

      if (sheetId === activeSheetId) {
        const lastSheet = this.board.lastSheetCreated();
        if (lastSheet) {
          this.board.setActiveSheetId(lastSheet.get("id"));
        } else {
          this.logger.warn(`Cannot find any sheet for board ${this.board.name}`);
        }
        new views.Modal({
          title: "Holy Sheet!",
          message: "Your sheet got deleted. <br /> You've been moved to a new sheet.",
          buttons: [{ text: "Okay" }],
        });
      }
    }

    onSheetMove(event, message) {
      const activeSheetId = this.board.activeSheetId();
      const sheetLength = this.board.sheets().length;

      if (message.boardId === this.board.id) {
        new views.Modal({
          title: "A sheet was moved to this board",
          message: "The board will be reloaded to get the new sheet",
          closeAction() {
            return window.location.reload();
          },
          buttons: [{ text: "Okay" }],
        });
      } else if (sheetLength > 1) {
        this.onSheetDelete(event, message);
      }

      if (sheetLength === 1) {
        new views.Modal({
          title: "Holy Sheet!",
          message: "Your sheet got moved to a different board, and this board was deleted.",
          closeAction() {
            return (window.location = `/sheets/${message._id}`);
          },
          buttons: [{ text: "Go to Board" }],
        });
      } else if (message._id === activeSheetId) {
        new views.Modal({
          title: "Holy Sheet!",
          message: "Your sheet got moved to a different board. <br /> Would you like to go to that board?",
          buttons: [
            {
              text: "Go to Board",
              action() {
                return (window.location = `/sheets/${message._id}`);
              },
            },
            { text: "Stay Here" },
          ],
        });
      }
    }

    onGroupCreate(event, message) {
      const sheet = this.board.findSheetById(message.sheetId);
      if (!sheet) {
        return this.missingEntity(event, "sheet", message);
      }
      let group = sheet.findGroup(message._id);
      if (group) {
        group.set(_(message).omit(MSG_CONTEXT_PROPS), { rebroadcast: true, replay: message.replay });
      } else {
        group = new models.Group(_.extend({ board: this.board }, _.omit(message, MSG_CONTEXT_PROPS)));
        sheet.groups().add(group, { rebroadcast: true, replay: message.replay });
      }
      this.entityCreated(event, message);
    }

    onGroupUpdate(event, message) {
      const sheet = this.board.findSheetById(message.sheetId);
      if (!sheet) {
        return this.missingEntity(event, "sheet", message);
      }
      const group = sheet.findGroup(message._id);
      if (!group) {
        return this.missingEntity(event, "group", message);
      }
      group.set(_(message).omit(MSG_CONTEXT_PROPS), {
        rebroadcast: true,
        replay: message.replay,
      });
    }

    onCardCreate(event, message) {
      const sheet = this.board.findSheetById(message.sheetId);
      if (!sheet) {
        return this.missingEntity(event, "sheet", message);
      }
      const group = sheet.findGroup(message.groupId);
      if (!group) {
        return this.missingEntity(event, "group", message);
      }
      let card = group.findCard(message._id);
      if (card) {
        card.set(_(message).omit(MSG_CONTEXT_PROPS), { rebroadcast: true, replay: message.replay });
      } else {
        card = new models.Card(_.extend({ board: this.board }, _.omit(message, MSG_CONTEXT_PROPS)));
        group.cards().add(card, { rebroadcast: true, replay: message.replay });
      }
      this.entityCreated(event, message);
    }

    onCardUpdate(event, message) {
      const sheet = this.board.findSheetById(message.sheetId);
      if (!sheet) {
        return this.missingEntity(event, "sheet", message);
      }
      const group = sheet.findGroup(message.groupId);
      if (!group) {
        return this.missingEntity(event, "group", message);
      }
      const card = group.findCard(message._id) || sheet.findCard(message._id); // in case we are moving the card to a new group
      if (!card) {
        return this.missingEntity(event, "card", message);
      }
      card.set(_(message).omit(MSG_CONTEXT_PROPS), {
        rebroadcast: true,
        replay: message.replay,
      });
    }

    onCardMove(event, message) {
      const sheet = this.board.findSheetById(message.sheetId);
      if (!sheet) {
        return this.missingEntity(event, "sheet", message);
      }
      const oldGroup = sheet.findGroupByCardId(message._id);
      if (!oldGroup) {
        return this.missingEntity(event, "group", message);
      }
      const card = oldGroup.findCard(message._id);
      if (!card) {
        return this.missingEntity(event, "card", message);
      }
      const newGroup = sheet.findGroup(message.groupId);
      if (!newGroup) {
        return this.missingEntity(event, "group", message);
      }
      sheet.moveCard(card, oldGroup.id, newGroup.id, {
        rebroadcast: true,
        replay: message.replay,
      });
    }

    onCollaboratorUpdate(event, message) {
      const collaborator = this.board.collaboratorForId(message.collaboratorId);
      if (!collaborator) {
        this.logger.debug(`Handler: cannot find collaborator user ${message.collaboratorId}`);
        return;
      }
      collaborator.set(message.changed, { rebroadcast: true, replay: message.replay });
      if (collaborator === this.board.currentCollaborator() && message.changed.sheetIds != null) {
        this.board.ensureActiveSheetId(collaborator, collaborator.sheetIds());
      }
    }

    onCollaboratorDelete(event, message) {
      this.board.removeCollaborator(message.collaboratorId, {
        rebroadcast: true,
        replay: message.replay,
      });
      if (this.board.currentCollaborator().id === message.collaboratorId) {
        this.clearHeartbeat();
        this.clearSender();
        new views.Modal({
          title: "Access Revoked",
          message: "You have been removed from this board. <br /> You will be redirected to the dashboard.",
          closeAction() {
            window.location = "/";
          },
          buttons: [{ text: "Okay" }],
        });
      }
    }

    onInvitationCreate(event, message) {
      const existingInvite = this.board.invitations().find((invitation) => invitation.email() === message.email);
      if (existingInvite) {
        existingInvite.set(_(message).omit("board", "sheet", "user"), {
          rebroadcast: true,
          replay: message.replay,
        });
      } else {
        const invite = new models.Invitation(_.chain(message).omit("sheet", "user").extend({ board: this.board }).value());
        this.board.invitations().add(invite, { rebroadcast: true, replay: message.replay });
      }
    }

    onInvitationUpdate(event, message) {
      const existingInvite = this.board.invitations().find((invitation) => invitation.id === message._id);
      if (!existingInvite) {
        return this.missingEntity(event, "invitation", message);
      }
      existingInvite.set(_(message).omit("board", "sheet", "user"), {
        rebroadcast: true,
        replay: message.replay,
      });
    }

    onInvitationDelete(event, message) {
      const existingInvite = this.board.invitations().find((invitation) => invitation.id === message._id);
      if (existingInvite) {
        this.board.removeInvitation(message._id, { rebroadcast: true, replay: message.replay });
      }
    }

    onUserUpdate() {}
    // user onboarded, nothing to do

    onCommandTheme(event, message) {
      const sheet = this.board.findSheetById(message.sheetId);
      if (!sheet) {
        return this.missingEntity(event, "sheet", message);
      }

      sheet.arrangeCardsByTheme(message.themes);
      this.board.currentCollaborator().set("command", null);
    }

    onCommandSummary(event, message) {
      const sheet = this.board.findSheetById(message.sheetId);
      if (!sheet) {
        return this.missingEntity(event, "sheet", message);
      }

      const card = sheet.findCard(message.cardId);
      if (!card) {
        return this.missingEntity(event, "card", message);
      }

      card.set("text", message.summary);
      this.board.currentCollaborator().set("command", null);

      console.log(message.prompt);
    }

    boardIdentity() {
      return {
        id: this.board.id,
        name: this.board.get("name"),
      };
    }

    sheetIdentity() {
      return { id: this.board.activeSheetId() };
    }

    refreshMessage() {
      return {
        user: _(this.user.toJSON()).pick("id"),
        board: this.boardIdentity(),
      };
    }

    userMessage() {
      return {
        user: _(this.user.toJSON()).pick("id"),
        board: this.boardIdentity(),
        sheet: this.sheetIdentity(),
      };
    }

    message(entityName, model) {
      const attrs = _(model.changed).keys();
      let message = model.toJSON();
      if (model._changing) {
        message = _(message).pick(attrs);
      } // restrict to changed attrs on updates only
      for (const attr of Array.from(attrs)) {
        if (message[attr] == null) {
          message[attr] = null;
        }
      } // add in any deleted attrs
      message = _(message).omit("_id", "created", "updated");
      message = _(message).omit(model.ephemeralProperties);
      if (_(message).isEmpty()) {
        return null;
      }

      _.extend(message, this.locks(entityName, model));
      return _.extend(message, this.context(entityName, model));
    }

    locks(entityName, model) {
      const { changed } = model;
      if (changed.x || changed.y) {
        return { lock: `${entityName}:drag:${model.id}` };
      }
      if (changed.text) {
        return { lock: `${entityName}:text:${model.id}` };
      }
      return {};
    }

    context(entityName, model) {
      const message = {};

      message._id = message._id || model.id;
      message.author = this.board.currentUserId();
      message.boardId = this.board.id;

      switch (entityName) {
        case "group":
          if (!message.sheetId) {
            const sheet = this.board.findSheetByGroupId(model.id);
            message.sheetId = sheet && sheet.id;
          }
          break;

        case "card":
          if (!message.sheetId) {
            const sheet = this.board.findSheetByCardId(model.id);
            message.sheetId = sheet && sheet.id;
          }
          if (!message.groupId) {
            const group = this.board.findGroupByCardId(model.id);
            message.groupId = group && group.id;
          }
          break;
      }

      return message;
    }

    boardMessage(board) {
      return this.message("board", board);
    }

    sheetMessage(sheet) {
      return this.message("sheet", sheet);
    }

    groupMessage(group) {
      return this.message("group", group);
    }

    cardMessage(card) {
      return this.message("card", card);
    }

    cardMoveMessage(card) {
      return this.context("card", card);
    }

    invitationMessage(invitation) {
      const invitationJSON = _(invitation.toJSON()).omit(invitation.ephemeralProperties);
      let message = _(this.userMessage()).extend(invitationJSON);
      message = _(message).omit("id");
      if (invitation.id) {
        if (message._id == null) {
          message._id = invitation.id;
        }
      }
      message.invitingCollaborator = this.board.currentCollaborator().id;
      return message;
    }

    deleteMessage(model) {
      const message = {
        _id: model.id,
        boardId: this.board.id,
        author: this.board.currentUserId(),
      };
      if (message.sheetId == null) {
        message.sheetId = typeof model.sheetId === "function" ? model.sheetId() : undefined;
      }
      if (message.groupId == null) {
        message.groupId = typeof model.groupId === "function" ? model.groupId() : undefined;
      }
      return message;
    }
  };
  Handler.initClass();
  return Handler;
})();

module.exports = Handler;
