const Backbone = require("backbone");
const Modal = require("./views/modal");

class StickiesRouter extends Backbone.Router {
  initialize() {
    this.location = window.location;

    $(window).on("hashchange", () => this.handleHashChange(this.location.hash));
  }

  routes() {
    return {
      "boards/:board_id": "viewBoardAction",
      login: "loginAction",
      new_login: "newLoginAction",
      register: "registerAction",
      forgot: "forgotAction",
      "change_password/:token": "changePasswordAction",
      profile: "profileAction",
      "": "rootAction",
    };
  }

  navigate(fragment, options) {
    if (options == null) {
      options = {};
    }
    super.navigate(fragment, options);
    const hash = (fragment.match(/#.*/) || ["#"])[0];
    if (hash && hash !== this.location.hash) {
      this.location.hash = hash;
    }
  }

  navigateHash(hash, options) {
    if (options == null) {
      options = {};
    }
    if (!hash) {
      return;
    }
    hash = hash.toString();
    if (!hash.match(/^#/)) {
      hash = `#${hash}`;
    }
    this.navigate(this.location.pathname + (this.location.search != null ? this.location.search : "") + hash, options);
  }

  refresh() {
    this.handleHashChange(this.location.hash);
  }

  execute(callback, args, actionName) {
    this[actionName](success => {
      if (success == null) {
        success = true;
      }
      if (success) {
        this.currentAction = actionName;
        const hash = (this.location.hash.match(/#.*/) || ["#"])[0];
        this.handleHashChange(hash);
      }
    }, ...Array.from(args));
  }

  handleHashChange(newHash) {
    if (this.currentAction === "viewBoardAction") {
      this.viewBoardHashAction(newHash);
    }
  }

  viewBoardAction(done, _boardId) {
    if (window.boardAttrs) {
      // the nesting already implies the relationship between entities in our
      // system; strip out unnecessary properties
      let activeSheetIndex, sheet;
      window.stickies.utils.sanitizer.sanitize(window.boardAttrs, {
        property: "sheets",
        toDelete: ["board", "boardId"],
        children: [
          {
            property: "groups",
            toDelete: ["sheetId", "sheet"],
            children: [
              {
                property: "cards",
                toDelete: ["groupId", "group"],
              },
            ],
          },
        ],
      });

      this.board = new stickies.models.Board(window.boardAttrs);

      // need to set correct sheet id before initializing Handler and loading Room View
      const hashFragments = this.location.hash.replace(/^#/, "").split(/&/);
      if ((activeSheetIndex = hashFragments[0]) && (sheet = this.board.findSheetByIndex(parseInt(activeSheetIndex) - 1))) {
        this.board.setActiveSheetId(sheet.id);
      }

      // configure logging
      // TODO: move this to somewhere more appropriate
      stickies.utils.Logger.instance.setBoard(this.board);
      stickies.utils.Logger.instance.setLevel(window.loglevel);
      const logger = stickies.utils.Logger.instance;

      // load emoji data from server while initializing client
      // TODO: move this to somewhere more appropriate
      $.getJSON("/emojis", emojis => {
        this.board.set("emojis", emojis);
      });

      this.board.on("rendered", () => {
        logger.info("Stickies board finished rendering");
        document.stickiesRenderComplete = true;
        this.board.off("rendered");
      });

      // bridge socket-messages to model updates
      const handler = new stickies.Handler(window.namespace, this.board);
      handler.initialize(() => {
        this.roomView = new stickies.views.Room({ model: this.board });
        done();
      });
    } else {
      done(false);
    }
  }

  viewBoardHashAction(newHash) {
    let activeSheetIndex, sheet;
    const hashFragments = newHash.replace(/^#/, "").split(/&/);

    // if the hash has a valid sheet index ...
    if ((activeSheetIndex = hashFragments[0]) && (sheet = this.board.findSheetByIndex(parseInt(activeSheetIndex) - 1))) {
      // synchronize sheet index and active sheet in model
      this.board.setActiveSheetId(sheet.id);

      // show or hide manage collaborators modal if necessary
      if (hashFragments[1] === "collaborators") {
        this.roomView.presence.showManageCollaborators(() => {
          this.navigate(location.pathname + (location.search != null ? location.search : "") + "#" + hashFragments[0]);
        });
      } else {
        this.roomView.presence.removeManageCollaborators();
      }
    } else {
      // default the url hash to the last created sheet index, triggering reroute
      const lastSheetId = this.board.lastSheetCreated().id;
      const sheetIndex = this.board.sheetIdToIndex(lastSheetId);
      const newFragments = [sheetIndex];
      if (hashFragments[1]) {
        newFragments.push(hashFragments[1]);
      }
      this.location.hash = `#${newFragments.join("&")}`;
    }
  }

  loginAction(done, _hashFragment) {
    const $input = $("#user_id");
    const $submit = $input.closest("form");
    const decorator = function($input, shiftKey) {
      const val = $input.val();
      if (!val.match(/@/) && !shiftKey) {
        $input.val(`@${val}`);
      }
    };

    $input.placeholder($submit, { decorator });

    let dataKey;

    $(".show-social-login").click(() => {
      dataKey = "loginRoute";
      $(".login__call-to-action").text("Sign in with");
      $("#social-login").addClass("open");
    });

    $(".show-social-sign-up").click(() => {
      dataKey = "signUpRoute";
      $(".login__call-to-action").text("Sign up with");
      $("#social-login").addClass("open");
    });

    $("#social-login .button").click(e => {
      window.location.href = $(e.currentTarget).data(dataKey);
    });

    done();
  }

  newLoginAction(done, _hashFragment) {
    stickies.pages.NewLogin();

    done();
  }

  registerAction(done, _hashFragment) {
    stickies.pages.Register();

    done();
  }

  forgotAction(done, _hashFragment) {
    stickies.pages.Forgot();

    done();
  }

  emailChangeAction(done, _hashFragment) {
    stickies.pages.EmailChange();

    done();
  }

  changePasswordAction(done, token) {
    stickies.pages.ChangePassword(token);

    done();
  }

  profileAction(done, _hasFragment) {
    stickies.pages.Profile();

    done();
  }

  rootAction(done) {
    const addPlaceholderToTextfield = function() {
      const $input = $("#board-name");
      const $submit = $input.closest("form").find("button[type=submit]");
      $input.placeholder($submit);
    };

    addPlaceholderToTextfield();

    $("[data-left-title]").each(function(idx, element) {
      // copy 'left-title' to 'title' attribute
      const $e = $(element);
      $e.attr("title", $e.attr("data-left-title"));
      $e.tooltip({
        position: "bottom left",
        effect: "fade",
        opacity: 0.9,
      });
    });

    // Need to listen to the hover event in order to work on iPad
    $(".board-cell").on("hover", function(_event) {});

    // enable keyboard activation of a grid cell
    $(".board-cell").on("click keyup", function(e) {
      // Enter keypress triggers the link.
      if (e.type === "click" || (e.type === "keyup" && e.which === 13)) {
        this.location = $(e.currentTarget)
          .find(".board-cell__view-button")
          .attr("href");
      }
    });

    $(".board-cell__action a").on("click", function(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    });

    $("form.archive-action a").on("click", evt =>
      $(evt.currentTarget)
        .closest("form")
        .submit()
    );

    $("form.delete-action a").on("click", function(evt) {
      const $form = $(evt.currentTarget).closest("form");
      const boardName = $form
        .find(".name")
        .val()
        .trim();
      new Modal({
        title: `Are you sure you want to delete <br /> ${boardName || "<em>untitled board</em>"}?`,
        message: "This will delete all sheets in this board! <br /> Once deleted, you will not be able to get them back.",
        buttons: [
          {
            text: "Delete",
            classes: ["action"],
            action: _modal => {
              $form.submit();
            },
          },
          { text: "Cancel" },
        ],
      });
    });

    $(".board-grid-header__expand button").on("click", function(_evt) {
      const show = $(this).text() === "SHOW";
      const label = show ? "HIDE" : "SHOW";
      const userId = $(this).attr("data-user");
      const section = $(this).attr("data-section");
      const data = {};
      data[`prefs.dashboard.${section}.show`] = show;
      const params = {
        url: `/users/${userId}`,
        method: "PUT",
        type: "PUT",
        data,
      };

      $(this).text(label);
      $.ajax(params).fail((xhr, status, error) => console.log(`Error updating user (${status}) - ${error}`));

      const target = $(this).data("target");
      $(target).toggleClass("in", show);
    });

    $(".board-grid .board-cell__avatar img").initials();

    $("#board-access-toggle").on("click", function(_evt) {
      const accessField = $("input#board-access");
      const val = accessField.val();
      accessField.val(val === "public" ? "private" : "public");
    });

    done();
  }
}

module.exports = StickiesRouter;
