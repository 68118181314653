const _ = require("underscore");
const Modal = require("./modal");
const Flash = require("./flash");
const MultipleEmailInput = require("./multiple_email_input");
const MultipleEmailInputModel = require("../models/multiple_email_input");
const CollaboratorList = require("./collaborator_list");
const Invitation = require("../models/invitation");
const ObjectID = require("bson-objectid");

class ManageCollaborators extends Modal {
  get body() {
    return _.template(`\
<div class='body'>
  <div class='send-invites'>
    <div class='form__group--flex'>
      <div class='add-collaborator__container'>
        <div class='invitation-email__container'></div>
        <div class="collaborator-role-selector collaborator-role-selector--manage">
          <div class="collaborator-role-selector__current-role collaborator-role-selector__current-role--manage">
            <div class="collaborator-role-selector__current-role-name collaborator-role-selector__current-role-name--manage">Moderator</div>
            <div class="collaborator-role-selector__menu-toggle"></div>
          </div>
          <div class="menu menu--within-manage-collaborators menu--down">
            <div class="menu__content">
              <ul class="menu__items"><li class="menu__item menu__item--active menu__item--manage">Moderator</li></ul>
            </div>
          </div>
        </div>
      </div>
      <button class='form__button button--primary send-email' disabled>Send</button>
    </div>
    <div class='invitation-feedback flash'><div class="invitation-feedback__message"></div></div>
    <div id="collaborator-list"></div>
  </div>
</div>\
`);
  }

  get events() {
    return {
      "click .send-email": "inviteCollaborator",
      "click #modal-backdrop": "close",
      "click .close-button": "close",
      "click .collaborator-role-selector__current-role--manage": "hiClickCurrentRole",
      "click .menu__item--manage": "hiChangeRole",
      "keypress .invitation-email__input": "hiKeyPressedInField",
      "focus .add-collaborator__container": "hiFocusCollabContainer",
      "click .add-collaborator__container": "hiFocusCollabContainer",
      "click #modal-container": "hiBlurCollabContainer",
    };
  }

  constructor(...args) {
    super(...args);
    this.render = this.render.bind(this);
    this.remove = this.remove.bind(this);
    this.isDuplicateEmail = this.isDuplicateEmail.bind(this);
    this.currentInviteeRole = this.currentInviteeRole.bind(this);
    this.inviteCollaborator = this.inviteCollaborator.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.hiClickCurrentRole = this.hiClickCurrentRole.bind(this);
    this.updateRoles = this.updateRoles.bind(this);
    this.hiChangeRole = this.hiChangeRole.bind(this);
    this.hiKeyPressedInField = this.hiKeyPressedInField.bind(this);
  }

  initialize(board, closeAction) {
    if (closeAction == null) {
      closeAction = false;
    }
    this.multipleEmailInput = new MultipleEmailInput({ model: new MultipleEmailInputModel() });
    this.board = board;
    this.listenTo(this.multipleEmailInput.model, "change", this.updateSendButton);
    return super.initialize({
      class: "modal-box--manage-collaborators",
      title: "Manage Collaborators",
      message: "Invite users to participate and lead meetings on this board.",
      priority: 10,
      closeButton: true,
      buttons: [],
      closeAction,
    });
  }

  updateSendButton() {
    this.$el.find(".send-email").prop("disabled", !this.multipleEmailInput.model.isValid());
  }

  render() {
    super.render();
    this.$el.find("#modal-box").append(this.body());
    this.flashView = new Flash({
      el: this.$el.find(".flash")[0],
      messageSelector: ".invitation-feedback__message",
    });
    this.collaboratorListView = new CollaboratorList({
      el: this.$el.find("#collaborator-list")[0],
      model: this.board,
    });
    this.multipleEmailInput.setElement(this.$(".invitation-email__container")).render();
    return this.updateRoles("editor");
  }

  remove() {
    if (this.flashView) {
      this.flashView.remove();
    }
    if (this.collaboratorListView) {
      this.collaboratorListView.remove();
    }
    return super.remove();
  }

  isDuplicateEmail(email) {
    return this.board.invitations().some((invitation) => invitation.email() === email);
  }

  currentInviteeRole() {
    const roleField = this.$el.find(".collaborator-role-selector__current-role-name");
    return roleField.html().toLowerCase();
  }

  inviteCollaborator() {
    const errors = this.multipleEmailInput.model.allEmailAddresses
      .map((email) => {
        if (this.isDuplicateEmail(email)) {
          return `This user has already been invited to the board: ${email}`;
        }
        return undefined;
      })
      .filter((error) => typeof error !== "undefined");

    if (errors.length > 0) {
      this.flashView.error(errors[0]);
    } else {
      this.multipleEmailInput.model.allEmailAddresses.forEach((email) => {
        this.board.invitations().add(
          new Invitation({
            _id: ObjectID().toHexString(),
            email: email,
            invitingCollaborator: this.board.currentCollaborator().id,
            role: this.currentInviteeRole(),
            board: this.board,
          })
        );
      });

      this.multipleEmailInput.model.clearAll();
      return this.flashView.success("Invite sent!");
    }
  }

  toggleMenu() {
    const form = this.$el.find(".collaborator-role-selector--manage");
    form.find(".collaborator-role-selector__current-role").toggleClass("collaborator-role-selector__current-role--clicked");
    return form.find(".menu").toggleClass("menu--open");
  }

  hiClickCurrentRole() {
    if (this.board.currentCollaborator().isModerator()) {
      return this.toggleMenu();
    }
  }

  updateRoles(role) {
    const form = this.$el.find(".collaborator-role-selector--manage");
    form.find(".collaborator-role-selector__current-role-name").html(role);
    form.attr("data-test-role", role);
    if (role === "editor") {
      return form.find(".menu__item").html("moderator").attr("data-test-role", "moderator");
    } else {
      return form.find(".menu__item").html("editor").attr("data-test-role", "editor");
    }
  }

  hiChangeRole(e) {
    const chosenRole = $(e.target).html();
    this.updateRoles(chosenRole);
    return this.toggleMenu();
  }

  hiKeyPressedInField(e) {
    if (e.which === 13) {
      return this.$el.find("button").click();
    }
  }

  hiFocusCollabContainer() {
    this._addCollaboratorContainer().addClass("add-collaborator__container--focus");
  }

  hiBlurCollabContainer(e) {
    if (!this._addCollaboratorContainer().length) {
      return;
    }

    if (!$.contains(this._addCollaboratorContainer()[0], e.target)) {
      this._addCollaboratorContainer().removeClass("add-collaborator__container--focus");
    }
  }

  _addCollaboratorContainer() {
    return this.$el.find(".add-collaborator__container");
  }
}

module.exports = ManageCollaborators;
