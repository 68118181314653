const validator = require("validator");
const { getLength, enableButton, removeAllInvalidStylingsFrom, hideMessage, showMessage, addValidationErrorToInput } = require("../shared/form");

const details = () => {
  const $detailsForm = $("#details-form");
  const $nameField = $detailsForm.find("[name=name]");
  const $emailField = $detailsForm.find("[name=email]");
  const $submitButton = $detailsForm.find("#continue-button");
  const $confirmationMessage = $detailsForm.find("#confirmation-message");
  const $error = $detailsForm.find("#error");

  const resetForm = () => {
    hideMessage($error);
    hideMessage($confirmationMessage);
    removeAllInvalidStylingsFrom($detailsForm);
  };

  const hiFieldFocus = () => {
    enableButton($submitButton);
    resetForm();
  };

  $("#avatar").initials();

  $nameField.focus(hiFieldFocus);
  $emailField.focus(hiFieldFocus);

  $submitButton.click(event => {
    event.preventDefault();

    var hasErrors = false;

    if (getLength($nameField) < 1) {
      addValidationErrorToInput($nameField);
      hasErrors = true;
    }

    if (!hasErrors && !validator.isEmail($emailField.val())) {
      addValidationErrorToInput($emailField);
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    $.ajax({
      url: "/profile",
      method: "patch",
      data: {
        name: $nameField.val(),
        email: $emailField.val(),
      },
      error: resp => {
        const suffix = resp && resp.responseText ? `: ${resp.responseText}` : "";
        showMessage($error, `There was an error processing your request${suffix}.`);
      },
    }).done(() => {
      showMessage(
        $confirmationMessage,
        "Your profile has been updated. If you changed your email address, check your inbox for a verification email."
      );
    });
  });
};

module.exports = details;
