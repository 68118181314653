module.exports = {
  Base: require("./base"),
  Board: require("./board"),
  BrowserTab: require("./browser_tab"),
  Card: require("./card"),
  Collaborator: require("./collaborator"),
  CollaboratorItem: require("./collaborator_item"),
  CollaboratorList: require("./collaborator_list"),
  Draggable: require("./draggable"),
  EditName: require("./edit_name"),
  Flash: require("./flash"),
  Group: require("./group"),
  Header: require("./header"),
  InvitationItem: require("./invitation_item"),
  Modal: require("./modal"),
  MultipleEmailInput: require("./multiple_email_input"),
  Onboarding: require("./onboarding"),
  CreateSheetFlow: require("./onboarding/create_sheet_flow"),
  Presence: require("./presence"),
  PresenceCounter: require("./presence_counter"),
  Room: require("./room"),
  Sheet: require("./sheet"),
  SheetList: require("./sheet_list"),
  SheetState: require("./sheet_state"),
  SheetClipboardDataProcessor: require("./sheet_clipboard_data_processor"),
};
