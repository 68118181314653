/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$.fn.placeholder = function(submitButton, options) {
  options = _.extend({ decorator() {} }, options);

  const $input = $(this);
  let shiftKeyPressed = false;

  $input.focus(function() {
    if (this.value === this.defaultValue) {
      return (this.value = "");
    } else {
      const s = () => this.select();
      return setTimeout(s, 10);
    }
  });

  $input.blur(function() {
    if (this.value === "") {
      return (this.value = this.defaultValue);
    }
  });

  $input.keydown(function(event) {
    if (event.keyCode === 16) {
      return (shiftKeyPressed = true);
    }
  });

  $input.keyup(function(event) {
    if (event.keyCode === 16) {
      return (shiftKeyPressed = false);
    }
  });

  return $(submitButton).click(function(event) {
    event.preventDefault();

    const val = $input.val();
    if (val === "" || val === $input.attr("defaultValue")) {
      return false;
    }

    options.decorator($input, event.shiftKey || shiftKeyPressed);
    return $(this)
      .closest("form")
      .submit();
  });
};
