/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
class ModalManager {
  constructor() {
    this.enabled = true;
  }

  setActiveModal(newInstance, newInstancePriority) {
    if (!this.enabled) {
      return;
    }
    if (this.instance) {
      if ((newInstancePriority || 0) >= this.instance.priority) {
        this.instance.remove();
      } else {
        return;
      }
    }
    this.instance = newInstance;
  }

  reset() {
    if (this.instance != null) {
      this.instance.remove();
    }
    this.instance = undefined;
  }

  disable() {
    this.enabled = false;
  }

  enable() {
    this.enabled = true;
  }
}

module.exports = ModalManager;
