/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _ = require("underscore");
const Base = require("./base");
require("../lib/jquery_extensions/initials");

class CollaboratorItemBase extends Base {
  get className() {
    return "collaborator-list__item";
  }

  get tagName() {
    return "div";
  }

  get template() {
    return _.template(`\
<img src="" class="collaborator-list__icon">
<div class="collaborator-list__main">
  <span class="collaborator-list__name"></span>
  <span class="collaborator-list__additional-actions"></span>
</div>
<div class="collaborator-role-selector">
  <div class="collaborator-role-selector__current-role">
    <div class="collaborator-role-selector__current-role-name"></div>
    <div class="collaborator-role-selector__menu-toggle"></div>
  </div>
  <div class="menu menu--within-manage-collaborators menu--down">
    <div class="menu__content">
      <ul class="menu__items"><li class="menu__item menu__item--active"></li></ul>
    </div>
  </div>
</div>
<div class="collaborator-remove">
  <a class="collaborator-remove__link">×</a>
</div>\
`);
  }

  get events() {
    return {
      "click .collaborator-role-selector__current-role": "hiClickCurrentRole",
      "click .menu__item": "hiChangeRole",
    };
  }

  constructor(...args) {
    super(...args);
    this.initialize = this.initialize.bind(this);
    this.updateName = this.updateName.bind(this);
    this.updateRoles = this.updateRoles.bind(this);
    this.updateIcon = this.updateIcon.bind(this);
    this.render = this.render.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.hiClickCurrentRole = this.hiClickCurrentRole.bind(this);
    this.hiChangeRole = this.hiChangeRole.bind(this);
  }

  initialize(attrs) {
    super.initialize(attrs);

    const notAuthorized = !this.model
      .board()
      .currentCollaborator()
      .isModerator();

    const isCurrentCollaborator = this.model.board().currentCollaborator() === this.model;

    this.listenTo(this.model, "change:role", this.updateRoles, this);

    this.render();

    if (notAuthorized || isCurrentCollaborator) {
      this.$el.find(".collaborator-remove").addClass("collaborator-remove--disabled");
    }

    return this.updateRoles(this.model, this.model.role());
  }

  updateName(item, name) {
    this.$el.find(".collaborator-list__name").html(name);
    return this.$el.attr("data-test-name", name);
  }

  updateRoles(item, role) {
    this.$el.find(".collaborator-role-selector__current-role-name").html(role);
    this.$el.attr("data-test-role", role);
    if (role === "editor") {
      return this.$el
        .find(".menu__item")
        .html("moderator")
        .attr("data-test-role", "moderator");
    } else {
      return this.$el
        .find(".menu__item")
        .html("editor")
        .attr("data-test-role", "editor");
    }
  }

  updateIcon(item, url) {
    const icon = this.$el.find(".collaborator-list__icon");
    icon.attr("src", url);
    if (this.model.user) {
      const ins = this.model
        .user()
        .activeIdentity()
        .initials();
      icon.initials(ins);
    }
  }

  render() {
    return this.$el.html(this.template());
  }

  toggleMenu() {
    this.$el.find(".collaborator-role-selector__current-role").toggleClass("collaborator-role-selector__current-role--clicked");
    return this.$el.find(".menu").toggleClass("menu--open");
  }

  hiClickCurrentRole() {
    if (
      this.model
        .board()
        .currentCollaborator()
        .isModerator()
    ) {
      return this.toggleMenu();
    }
  }

  hiChangeRole() {
    if (this.model.isModerator()) {
      this.model.set("role", "editor");
    } else {
      this.model.set("role", "moderator");
    }
    return this.toggleMenu();
  }
}

module.exports = CollaboratorItemBase;
