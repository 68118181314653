/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _ = require("underscore");
const CollaboratorItemBase = require("./collaborator_item_base");

class CollaboratorItem extends CollaboratorItemBase {
  constructor(...args) {
    super(...args);
    this.events = this.events.bind(this);
    this.initialize = this.initialize.bind(this);
    this.hiClickCurrentRole = this.hiClickCurrentRole.bind(this);
    this.hiRemoveCollaborator = this.hiRemoveCollaborator.bind(this);
  }

  events() {
    return _.extend({}, CollaboratorItemBase.prototype.events, {
      "click .collaborator-remove__link": this.hiRemoveCollaborator,
    });
  }

  initialize(attrs) {
    super.initialize(attrs);

    this.listenTo(this.model.user().activeIdentity(), "change:displayName", this.updateName, this);
    this.listenTo(this.model.user().activeIdentity(), "change:avatar", this.updateIcon, this);

    this.updateName(
      this.model,
      this.model
        .user()
        .activeIdentity()
        .displayName()
    );
    this.updateIcon(
      this.model,
      this.model
        .user()
        .activeIdentity()
        .avatar()
    );

    if (this.model.board().currentCollaborator() === this.model) {
      return this.$el.find(".collaborator-list__current-role").addClass("collaborator-list__current-role--disabled");
    }
  }

  hiClickCurrentRole() {
    if (this.model.board().currentCollaborator() !== this.model) {
      return super.hiClickCurrentRole();
    }
  }

  hiRemoveCollaborator(e) {
    e.preventDefault();
    return this.model.board().removeCollaborator(this.model.get("id"));
  }
}

module.exports = CollaboratorItem;
