/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$.fn.bounds = function() {
  var boundify = function(box) {
    box.middle = function() {
      return {
        x: this.left + (this.right - this.left) / 2.0,
        y: this.top + (this.bottom - this.top) / 2.0,
      };
    };
    box.contains = function(point) {
      return this.left <= point.x && point.x <= this.right && this.top <= point.y && point.y <= this.bottom;
    };
    box.upperHalf = function() {
      return boundify({
        left: this.left,
        right: this.right,
        top: this.top,
        bottom: this.middle().y,
      });
    };
    box.lowerHalf = function() {
      return boundify({
        left: this.left,
        right: this.right,
        top: this.middle().y,
        bottom: this.bottom,
      });
    };
    box.extendUp = function(pixels) {
      return boundify({
        left: this.left,
        right: this.right,
        top: this.top - pixels,
        bottom: this.bottom,
      });
    };
    box.extendDown = function(pixels) {
      return boundify({
        left: this.left,
        right: this.right,
        top: this.top,
        bottom: this.bottom + pixels,
      });
    };
    return box;
  };

  const bounds = $(this).offset();
  bounds.bottom = bounds.top + $(this).height();
  bounds.right = bounds.left + $(this).width();
  return boundify(bounds);
};
