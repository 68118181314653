const { getLength, enableButton, removeAllInvalidStylingsFrom, hideMessage, showMessage, addValidationErrorToInput } = require("./shared/form");

const changePassword = token => {
  const $form = $("#form");
  const $passwordField = $form.find("[name=password]");
  const $confirmPasswordField = $form.find("[name=confirm_password]");
  const $submitButton = $form.find("#continue-button");
  const $changePasswordForm = $form.find(".change-password-form");
  const $confirmPasswordSubLabel = $form.find("[id=confirm-password-sub-label]");
  const $confirmationMessage = $form.find("#confirmation-message");
  const $providerMessage = $form.find(".user-form__other-providers");
  const $error = $form.find("#error");

  const resetForm = () => {
    hideMessage($error);
    hideMessage($confirmPasswordSubLabel);
    removeAllInvalidStylingsFrom($form);
  };

  const hiFieldFocus = () => {
    enableButton($submitButton);
    resetForm();
  };

  $passwordField.focus(hiFieldFocus);
  $confirmPasswordField.focus(hiFieldFocus);

  $submitButton.click(event => {
    event.preventDefault();

    var hasErrors = false;

    if (getLength($passwordField) < 8) {
      addValidationErrorToInput($passwordField);
      hasErrors = true;
    }

    if ($passwordField.val() !== $confirmPasswordField.val()) {
      addValidationErrorToInput($confirmPasswordField);
      showMessage($confirmPasswordSubLabel, "Must match the password");
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    $.ajax({
      url: `/change_password/${token}`,
      method: "post",
      data: {
        password: $passwordField.val(),
      },
      error: () => {
        showMessage($error, "Sorry, we weren't able to update your password. This link may be expired.");
      },
    }).done(() => {
      $changePasswordForm.hide();
      $providerMessage.hide();
      showMessage($confirmationMessage, "Done! You can now log in with your updated password.");
    });
  });
};

module.exports = changePassword;
