/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _ = require("underscore");
const utils = require("../../utils");
const OnboardingFlow = require("./onboarding_flow");

class CreateStickyFlow extends OnboardingFlow {
  static get flowName() {
    return "create sticky";
  }

  get className() {
    return "onboarding__create-sticky-flow";
  }

  get body() {
    return _.template(`\
      <div class='wrapper'>
        <div class='icon'></div>
        <div class='message'>
          <div>Double click anywhere on the board</div>
          <div>to create a new Sticky.</div>
        </div>
        <button class='dismiss button button--primary'>Ok, got it!</button>
      </div>\
    `);
  }

  initialize(attrs) {
    super.initialize(attrs);
    return this.render();
  }

  render() {
    this.$el.html(this.body());
    return this.$el.css("z-index", utils.zIndexManager.ONBOARDING_Z_INDEX + 10);
  }

  flowName() {
    return CreateStickyFlow.flowName;
  }
}

module.exports = CreateStickyFlow;
