/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _ = require("underscore");
const Backbone = require("backbone");
const utils = require("../utils");
const CreateStickyFlow = require("./onboarding/create_sticky_flow");
const CreateSheetFlow = require("./onboarding/create_sheet_flow");
const AddCollaboratorsFlow = require("./onboarding/add_collaborators_flow");
const OpenDrawerFlow = require("./onboarding/open_drawer_flow");

class Onboarding extends Backbone.View {
  static get editorFlows() {
    return [CreateStickyFlow, AddCollaboratorsFlow, OpenDrawerFlow];
  }

  static get moderatorFlows() {
    return [CreateSheetFlow];
  }

  get el() {
    return "#onboarding";
  }

  constructor(...args) {
    super(...args);
    this.showFlow = this.showFlow.bind(this);
    this.render = this.render.bind(this);
  }

  initialize(attrs) {
    super.initialize(attrs);
    return this.render();
  }

  showFlow(flow) {
    const user = this.model.currentUser();
    this.$el.append(flow.$el);
    return this.listenTo(flow, "dismiss", function() {
      flow.remove();
      return user.onboardFeature(flow.flowName());
    });
  }

  render() {
    const user = this.model.currentUser();
    const onboardedFeatures = user.onboardedFeatures().map(f => f.featureName());

    let flows = Onboarding.editorFlows;
    if (this.model.currentCollaborator().isModerator()) {
      flows = flows.concat(Onboarding.moderatorFlows);
    }

    flows = flows.filter(flow => !onboardedFeatures.includes(flow.flowName));
    _.forEach(flows, Flow => {
      return this.showFlow(new Flow({ model: user, window }));
    });

    return this.$el.css("z-index", utils.zIndexManager.ONBOARDING_Z_INDEX);
  }
}

module.exports = Onboarding;
