class Bugsnagger {
  leaveBreadcrumb(name, message) {
    if (message == null) {
      message = {};
    }
    const isdeep = obj => obj != null && typeof obj === "object" && obj.getTime == null;

    if (!isdeep(message)) {
      return Bugsnag.leaveBreadcrumb(name, message);
    }

    const deepProps = _.chain(message)
      .values()
      .find(v => isdeep(v))
      .value();
    if (!deepProps) {
      return Bugsnag.leaveBreadcrumb(name, message);
    }

    if (name === "receive: heartbeat") {
      const clone = _(message).clone();
      clone._collaboratorId = message.collaborator._id;
      clone._userId = message.collaborator.user._id;
      clone.collaborator = "<redacted>";
      return Bugsnag.leaveBreadcrumb(name, clone);
    }

    const shallow = _(message).mapObject(function(val, _key) {
      if (!isdeep(val)) {
        return val;
      }
      return JSON.stringify(val);
    });

    return Bugsnag.leaveBreadcrumb(name, shallow);
  }
}

module.exports = new Bugsnagger();
