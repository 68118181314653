const { enableButton, hideMessage, showMessage, removeAllInvalidStylingsFrom } = require("./shared/form");

const newLogin = () => {
  const $form = $("#form");
  const $emailField = $form.find("[name=email]");
  const $passwordField = $form.find("[name=password]");
  const $submitButton = $form.find("#continue-button");
  const $error = $form.find("#error");

  const resetForm = () => {
    hideMessage($error);
    removeAllInvalidStylingsFrom($form);
  };

  const hiFieldFocus = () => {
    enableButton($submitButton);
    resetForm();
  };

  $emailField.focus(hiFieldFocus);
  $passwordField.focus(hiFieldFocus);

  /*
    This function is a workaround for the issue described here:
    https://stackoverflow.com/questions/35049555/chrome-autofill-autocomplete-no-value-for-password
    Briefly, chrome autofills do not set input values until the user interacts with the page.
    We can look at pseudo-classes (set by Chrome) on the inputs to see if they've been autofilled.  The classes
    are set async so we have to 'poll' to look for them for a few seconds.  The polling is cancelled when classes
    are found.

    For other browsers we have to use the browser api (not jQuery) to get the autofilled values.  These values are also
    set async so polling applies here as well.
   */
  const checkAutofills = (count = 0) => {
    if (count === 10) return;

    let isEmailAutofilled;
    let isPasswordAutofilled;

    // for chrome we must check autofill class
    try {
      isEmailAutofilled = document.querySelector('input[name="email"]:-webkit-autofill');
      isPasswordAutofilled = document.querySelector('input[type="password"]:-webkit-autofill');
    } catch (e) {}

    if (isEmailAutofilled && isPasswordAutofilled) {
      $submitButton.prop("disabled", false);
      return;
    }

    // for the other browsers we check input values
    const emailInput = document.querySelector('input[name="email"]');
    const passwordInput = document.querySelector('input[type="password"]');

    const isEmailEntered = emailInput && emailInput.value.length > 0;
    const isPasswordEntered = passwordInput && passwordInput.value.length > 0;

    if (isEmailEntered && isPasswordEntered) {
      $submitButton.prop("disabled", false);
      return;
    }

    setTimeout(() => checkAutofills(++count), 30);
  };
  checkAutofills();

  $submitButton.click(event => {
    event.preventDefault();

    $.ajax({
      url: "/login",
      method: "post",
      data: {
        email: $emailField.val(),
        password: $passwordField.val(),
      },
      error: () => {
        showMessage($error, "Your email address or password was not recognized");
      },
    }).done(() => {
      location.href = "/";
    });
  });
};

module.exports = newLogin;
