/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _ = require("underscore");
const TooltipOnboardingFlow = require("./tooltip_onboarding_flow");

class CreateSheetFlow extends TooltipOnboardingFlow {
  static get flowName() {
    return "create sheet";
  }

  get className() {
    return "onboarding__tooltip onboarding__flow--create-sheet";
  }

  get body() {
    return _.template(`\
      <div class="guide-tooltip">
        <div class='wrapper'>
            <div class='message'>Add a new sheet to your board here.</div>
            <button class='dismiss button button--primary'>Ok, got it!</button>
        </div>
      </div>\
      `);
  }

  _positionDot() {
    return this._offsetFromParent(26, 4);
  }

  _positionTooltip() {
    return this._offsetFromParent(50, -189);
  }

  _parent() {
    return $("#sheet-list .header__menu-toggle");
  }

  flowName() {
    return CreateSheetFlow.flowName;
  }
}

module.exports = CreateSheetFlow;
