/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const Backbone = require("backbone");

class UrlBar extends Backbone.View {
  initialize() {
    this.model.on("change:activeSheetId", this.updateSheetIndex, this);
    return this.model.sheets().on("remove", this.updateSheetIndex, this);
  }

  updateSheetIndex() {
    return window.router.navigateHash(`#${this.model.activeSheetIndex()}`);
  }
}

module.exports = UrlBar;
