/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const Backbone = require("backbone");

class OnboardingFlow extends Backbone.View {
  get events() {
    return {
      click: "dismiss",
      "click .dismiss": "dismiss",
    };
  }

  dismiss(event) {
    if (event != null) {
      event.stopPropagation();
    }
    return this.trigger("dismiss");
  }
}

module.exports = OnboardingFlow;
