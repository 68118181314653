$.fn.draggable = function(opts) {
  let event;
  const $this = this;
  this.isDragging = false;
  this.addClass("draggable");

  const settings = $.extend(
    true,
    {
      id: this[0].id,
      target: null,
      canvas: null,
      startedDragging() {},
      stoppedDragging() {},
      dropped() {},
      onMouseMove() {},
      onMouseUp() {},
      onMouseDown() {},
      isOkToDrag() {
        return true;
      },
      isDragCanceled() {
        return false;
      },
      isTarget(_target) {
        return true;
      },
      position(dx, dy, x, y) {
        return { left: x, top: y };
      },
    },
    opts
  );

  const trigger = function(name, mouseEvent) {
    const offset = $this.offset();
    $(window).trigger(name, {
      target: $this[0],
      mouseEvent,
      x: offset.left,
      y: offset.top,
    });
  };

  const stopEvent = function(e) {
    e.stopPropagation();
    e.preventDefault();
  };

  const events = {
    down: ["mousedown", "touchstart"],
    up: ["mouseup", "touchend"],
    move: ["mousemove", "touchmove"],
  };

  for (const type in events) {
    for (let n = 0; n < events[type].length; n++) {
      event = events[type][n];
      events[type][n] = event + ".draggable." + settings.id;
    }
  }

  const getRelevantEvent = function(e) {
    const oe = e.originalEvent;
    if (oe.type.slice(0, 5) === "touch") {
      for (const touch of Array.from(oe.touches)) {
        if (settings.target === touch.target) {
          return touch;
        }
      }
      return null;
    }
    return oe;
  };

  const getCoordinates = function(e) {
    const relevant = getRelevantEvent(e);
    if (!relevant) {
      return null;
    }
    return { x: relevant.pageX, y: relevant.pageY };
  };

  const mouseDown = function(e) {
    if (!settings.isOkToDrag()) {
      return true;
    }
    if (!settings.isTarget(e.target)) {
      return true;
    }
    if (this.isDragging) {
      return true;
    }
    if (e.type === "mousedown") {
      if (e.which !== 1) {
        return true;
      }
      if (e.ctrlKey) {
        return true;
      }
    }

    stopEvent(e);

    settings.target = e.target;
    let coords = getCoordinates(e);
    let offset = $this.offset();
    const origin = {
      x: coords.x,
      y: coords.y,
      left: offset.left,
      top: offset.top,
    };

    settings.onMouseDown(e);

    const mouseMove = function(e) {
      if (!this.isDragging) {
        settings.startedDragging();
        this.isDragging = true;
      }

      if (settings.isDragCanceled()) {
        for (event of events.move) {
          $(window).off(event);
        }
        for (event of events.up) {
          $(window).off(event);
        }
        this.isDragging = false;
        settings.stoppedDragging();
        return;
      }

      coords = getCoordinates(e);
      if (!coords) {
        return;
      }

      const delta = {
        x: coords.x - origin.x,
        y: coords.y - origin.y,
      };

      const canvasOffset = settings.canvas ? settings.canvas.offset() : { left: 0, top: 0 };

      offset = {
        left: Math.max(origin.left + delta.x, canvasOffset.left),
        top: Math.max(origin.top + delta.y, canvasOffset.top),
      };

      $this.offset(offset);

      settings.onMouseMove(e);
      trigger("drag");
      return false;
    };

    const mouseUp = function(e) {
      coords = getCoordinates(e);
      if (e.type !== "mouseup" && coords !== null) {
        return;
      }

      settings.onMouseUp(e);

      if (this.isDragging) {
        this.isDragging = false;
        settings.stoppedDragging();

        // Don't trigger drops if the mouse hasn't moved, as that should be treated as a click.
        // This fixes a bug where un-grouped selected cards get grouped by clicking a grouped card.
        trigger("drop", e);
      }

      settings.dropped();

      for (event of events.move) {
        $(window).off(event);
      }
      for (event of events.up) {
        $(window).off(event);
      }
      return true;
    };

    for (event of events.move) {
      $(window).on(event, mouseMove);
    }
    for (event of events.up) {
      $(window).on(event, mouseUp);
    }
    return true;
  };

  for (event of events.down) {
    $this.on(event, mouseDown);
  }

  return $this;
};
