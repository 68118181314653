const _ = require("underscore");
const Backbone = require("backbone");
const moment = require("moment");
const ObjectID = require("bson-objectid");
const utils = require("../utils");
const Group = require("./group");

const flatMap = (xs, f) => {
  return _.flatten(_.map(xs, f), true);
};

class Sheet extends Backbone.Model {
  get idAttribute() {
    return "_id";
  }

  get backgrounds() {
    return ["grid", "box", "target", "leancanvas"];
  }

  get ephemeralProperties() {
    return ["board", "groups", "focusedCardId", "recentlyFocusedCardId", "displayState"];
  }

  initialize(attributes = {}) {
    this.logger = utils.Logger.instance;
    const groups = new Backbone.Collection(_.map(attributes.groups, (group) => new Group(_(group).extend({ board: this.get("board") }))));
    this.set("groups", groups);
    if (!attributes.created) {
      this.set("created", new Date());
    }
    if (!attributes.updated) {
      this.set("updated", new Date());
    }
    if (!this.get("background")) {
      this.set("background", "grid");
    }
    this.updateDisplayState();

    this.listenTo(this.board(), "change:mode", this.updateDisplayState, this);
    this.listenTo(this, "change:mode", this.updateDisplayState, this);
    this.listenTo(this, "card:colorized", this.colorizeSelectedItems, this);
  }

  groups() {
    return this.get("groups");
  }

  aliveGroups() {
    return this.groups().reject((g) => g.softDeleted());
  }

  cards() {
    return this.groups().flatMap((g) => g.cards());
  }

  aliveCards() {
    return this.aliveGroups().flatMap((g) => g.aliveCards());
  }

  board() {
    return this.get("board");
  }

  name() {
    return this.get("name");
  }

  cleanName() {
    return (this.name() || "").trim();
  }

  findCard(id) {
    for (const group of this.groups()) {
      const card = group.findCard(id);
      if (card) return card;
    }
  }

  findGroupByCardId(id) {
    for (const group of this.groups()) {
      const card = group.findCard(id);
      if (card) return group;
    }
  }

  findGroup(id) {
    return this.groups().find((group) => group.id === id);
  }

  focusedCardId() {
    return this.get("focusedCardId");
  }

  recentlyFocusedCardId() {
    return this.get("recentlyFocusedCardId");
  }

  focusCard(card) {
    const cardId = (card ? card.id : null) || card;
    this.set("focusedCardId", cardId);
  }

  unfocusCard(card) {
    const cardId = (card ? card.id : null) || card;
    if (this.focusedCardId() !== cardId) {
      return;
    }

    this.set("recentlyFocusedCardId", cardId);
    this.unset("focusedCardId");
    clearTimeout(this.unfocusTimeout);
    this.unfocusTimeout = setTimeout(() => this.unset("recentlyFocusedCardId"), 100);
  }

  missingGroup(id, msg) {
    this.logger.warn(`Missing group ${id} when trying to: ${msg}`);
  }

  setMode(state) {
    this.set("mode", state);
  }

  updateDisplayState(_model, _value, options) {
    if (this.board().get("mode")) {
      this.set("displayState", this.board().get("mode"), options);
    } else {
      this.set("displayState", this.get("mode"), options);
    }
  }

  createGroup(coords, name, options = {}) {
    const group = this.newGroupAt(coords, name);
    this.groups().add(group);

    if (!options.empty) {
      group.createCard();
    }

    return group;
  }

  mergeGroups(parentId, childId) {
    const parent = this.findGroup(parentId);
    if (!parent) {
      return this.missingGroup(parentId, `merge group ${childId} in to this group`);
    }
    const child = this.findGroup(childId);
    if (!child) {
      return this.missingGroup(childId, `merge this group in to group ${parentId}`);
    }
    const childCards = child.cards().toArray();
    return childCards.map((card) => this.moveCard(card, child.id, parent.id));
  }

  moveCard(card, fromGroupId, toGroupId, options) {
    const fromGroup = this.findGroup(fromGroupId);
    if (!fromGroup) {
      return this.missingGroup(fromGroupId, `move card from this group to group ${toGroupId}`);
    }
    const toGroup = this.findGroup(toGroupId);
    if (!toGroup) {
      return this.missingGroup(toGroupId, `move card from group ${fromGroupId} to this group`);
    }

    // avoid triggering the card-creation and card-deletion code in the handler
    const moveOptions = _.extend({}, options, { movecard: true });

    // TODO: why don't we honor order here?
    if (fromGroupId !== toGroupId) {
      fromGroup.cards().remove(card, moveOptions);
    }

    if (!toGroup.cards().any((c) => c.id === card.id)) {
      toGroup.cards().add(card, moveOptions);
    }
  }

  dropSelectedItems() {
    this.selectedItems().forEach((item) => {
      if (item.kind === "card") {
        this.dropIndividualCard(item, item.group());
      } else {
        this.dropIndividualGroup(item);
      }
    });
  }

  dropCard(id) {
    const oldGroup = this.findGroupByCardId(id);
    const card = oldGroup.findCard(id);

    if (card.isSelected()) {
      this.dropSelectedItems();
    } else {
      this.dropIndividualCard(card, oldGroup);
    }
  }

  dropIndividualCard(card, oldGroup) {
    const coords = {
      x: card.get("x") + card.group().get("x"),
      y: card.get("y") + card.group().get("y"),
    };

    if (!coords.x || !coords.y) {
      Bugsnag.notify("Group create error", "Bad x,y values when creating group by dropping card from another group", (event) => {
        event.addMetadata("drop_info", {
          card: card.attributes,
          oldGroup: oldGroup.attributes,
          newGroup: card.group().attributes,
        });
      });
    }

    const group = this.newGroupAt(coords);
    group.updateCardOrderingAfterInserting([card]);

    this.groups().add(group);
    this.moveCard(card, oldGroup.id, group.id);
  }

  dropGroup(id) {
    const group = this.findGroup(id);
    if (group.isSelected()) {
      this.dropSelectedItems();
    } else {
      this.dropIndividualGroup(group);
    }
  }

  dropIndividualGroup(group) {
    group.drop();
  }

  arrangeGroups() {
    const xpad = 12;
    const ypad = 8;

    const groups = this.aliveGroups().map(function (group) {
      const b = group.bounds();
      return {
        model: group,
        w: b.width + xpad * 2,
        h: b.height + ypad * 2,
        x: b.x,
        y: b.y,
      };
    });

    const packer = new utils.packer.Packer();
    packer.fit(groups);

    _(groups).each((group) => group.model.moveTo(xpad + group.fit.x, ypad + group.fit.y));
  }

  arrangeCardsByTheme(themes) {
    const groups = this.aliveGroups();
    if (groups.length === 0) {
      return;
    }

    const cardsById = {};
    this.aliveCards().forEach((c) => {
      cardsById[c.id] = c;
    });
    const leftovers = [];

    const xpad = 12;
    const ypad = 8;
    const width = this.aliveGroups()[0].bounds().width;
    let i = 0;

    themes.forEach((theme) => {
      const cards = theme.ids.map((id) => cardsById[id]).filter((c) => c);

      if (cards.length === 0) {
        return;
      }

      if (cards.length === 1) {
        leftovers.push(cards[0]);
        return;
      }

      const coords = { x: cards[0].group().bounds().x, y: cards[0].group().bounds().y };
      const group = this.createGroup(coords, theme.name, { empty: true });
      cards.forEach((card) => {
        this.moveCard(card, card.groupId(), group.id);
      });
      group.moveTo(xpad + (width + xpad * 2) * i, ypad);
      i += 1;

      cards.forEach((card) => delete cardsById[card.id]);
    });

    Object.values(cardsById).forEach((card) => leftovers.push(card));

    if (leftovers.length > 0) {
      const leftoverCoords = { x: leftovers[0].group().bounds().x, y: leftovers[0].group().bounds().y };
      const leftoverGroup = this.createGroup(leftoverCoords, "Leftovers 🤷", { empty: true });
      leftovers.forEach((leftover) => {
        this.moveCard(leftover, leftover.groupId(), leftoverGroup.id);
      });
      leftoverGroup.moveTo(xpad + (width + xpad * 2) * i, ypad);
    }
  }

  colorizeSelectedItems(_card, colorIndex) {
    _.invoke(this.selectedItems(), "colorize", colorIndex);
  }

  getCanvasSize() {
    if (!this.groups().length) {
      return { x: 200, y: 150 };
    }

    const maxCardX = this.groups().max((group) => group.get("x"));
    const maxCardY = this.groups().max((group) => group.get("y") + group.get("height"));
    const maxX = maxCardX.get("x") + maxCardX.get("width") + $("#presence").width();
    const maxY = maxCardY.get("y") + maxCardY.get("height") + $("#board-nav").height();
    return { x: maxX, y: maxY };
  }

  delete(options) {
    const sheets = this.board().sheets();
    if (sheets.length !== 1) {
      sheets.remove(this);
      const rebroadcast = options && options.rebroadcast;

      if (!rebroadcast) {
        this.trigger("destroy", this, sheets, options);
      }
    }
  }

  move(boardId) {
    this.set("boardId", boardId);
  }

  //
  // Utility functions
  //

  createdAt() {
    return new Date(this.get("created"));
  }

  maxZ() {
    const groups = this.groups();
    if (!groups || !(groups.length > 0)) {
      return 0;
    }
    const maxGroup = groups.max((group) => group.get("z") || 0);
    return maxGroup.get("z") || 0;
  }

  newGroupAt({ x, y }, name) {
    const group = new Group({
      _id: ObjectID().toHexString(),
      board: this.board(),
      x: (x || 100) - 10,
      y: (y || 100) - 10,
      z: this.maxZ() + 1,
    });

    if (name) group.attributes.name = name;

    return group;
  }

  duplicate() {
    const name = this.board().sheetNameFor(this.get("name"));
    return new Sheet({
      _id: ObjectID().toHexString(),
      board: this.board(),
      name,
    });
  }

  deselectAll() {
    this.groups().forEach((group) => {
      group.deselect();
      group.deselectCards();
    });
  }

  selectedItems() {
    return flatMap(this.groups().toArray(), (group) => {
      // If the group is selected (which means it's a single-card group), return the group.
      if (group.isSelected()) {
        return group;
      }

      // Otherwise return any selected cards within the multi-card group.
      return group
        .cards()
        .toArray()
        .filter((card) => card.isSelected());
    });
  }

  static defaultName() {
    return moment().format("l");
  }
}

module.exports = Sheet;
