/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$.fn.removeClassMatching = function(regexp) {
  return this.each(function() {
    return $(this).attr("class", (_, value) => $.trim(value.replace(regexp, "")));
  });
};
