class Touch {
  initialize() {
    this._force = false;
  }

  force(_force) {
    this._force = _force;
  }

  supported() {
    if (this._force) return true;

    return "ontouchstart" in window || (window.DocumentTouch && document instanceof window.DocumentTouch);
  }
}

module.exports = new Touch();
