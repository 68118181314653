const utils = require("../utils");

const OFFSET_FROM_ANCESTOR = 30;

// Minimum distance required between the top-left edges of the copied items and the bottom-right edges of
// the window below which we will adjust the paste positioning of the items so that they are more visible
const ANCESTOR_MINIMUM_VISIBILITY_MARGIN = 250;

class SheetClipboardDataProcessor {
  constructor(sheetView) {
    this.sheetView = sheetView;
    this.sheetModel = sheetView.model;
    this.logger = utils.Logger.instance;

    this._createItemsFromData = this._createItemsFromData.bind(this);
  }

  copyItems(items) {
    const copyGroups = items
      .map((item) => this._getItemPosition(item))
      .filter((item) => typeof item !== "undefined")
      .map(this._formatCopyGroup);

    return JSON.stringify(copyGroups);
  }

  pasteItems(clipText) {
    return this._getValidClipboardJson(clipText)
      .then((data) => this._getPositionAdjustment(data))
      .then(({ adjustment, clipboardData }) => this._adjustItemPosition(adjustment, clipboardData))
      .then((groupData) => {
        groupData.map(this._createItemsFromData);
      })
      .catch((error) => this.logger.error(error));
  }

  _getItemPosition(item) {
    const sheetOffset = this.sheetView.$el.offset();
    const cardOffset = $(`#card-${item.id}`).offset();

    const isEmpty = (obj) => obj === null || obj === undefined;

    // to proceed, we need either the x/y attributes or both the sheet and card offsets
    const missingAttributes = [item.attributes.x, item.attributes.y].some((attr) => isEmpty(attr));
    const missingOffsets = [sheetOffset, cardOffset].some((offset) => isEmpty(offset));
    if (missingAttributes && missingOffsets) {
      return;
    }

    // these adjustments account for differences in spacing (margin & border) and relative positioning of cards between
    // a multi-card group and a single-card group
    const leftSpacingAdjustment = 5;
    const topSpacingAdjustment = 2;
    item.x = !isEmpty(item.attributes.x) ? item.attributes.x : Math.round(cardOffset.left - sheetOffset.left + leftSpacingAdjustment);
    item.y = !isEmpty(item.attributes.y) ? item.attributes.y : Math.round(cardOffset.top - sheetOffset.top + topSpacingAdjustment);

    return item;
  }

  _formatCopyGroup(item) {
    const cards = (item.aliveCards && item.aliveCards()) || [item];
    const formattedCards = cards.map((card) => ({ text: card.attributes.text, colorIndex: card.attributes.colorIndex }));

    return { cards: formattedCards, x: item.x, y: item.y, name: item.attributes.name };
  }

  async _getValidClipboardJson(clipText) {
    const compose = (...fns) => (arg) => fns.reduce((acc, fn) => fn(acc), arg);
    const validate = compose(this._validateCards, this._validateCoordinates, this._validateGroupNames, this._validateCardText);
    return validate(JSON.parse(clipText));
  }

  _validateCards(clipboardData) {
    if (!clipboardData.length || !clipboardData[0].cards) throw new Error("No cards");
    return clipboardData;
  }

  _validateCoordinates(clipboardData) {
    if (!clipboardData.every((group) => !isNaN(group.x) && !isNaN(group.y))) throw new Error("Coordinates must be numbers");
    return clipboardData;
  }

  _validateGroupNames(clipboardData) {
    if (!clipboardData.every((group) => typeof group.name === "undefined" || typeof group.name === "string" || group.name instanceof String))
      throw new Error("Group names must be strings");
    return clipboardData;
  }

  _validateCardText(clipboardData) {
    if (
      !clipboardData.every((group) => {
        return group.cards.every((card) => typeof card.text === "string" || card.text instanceof String);
      })
    )
      throw new Error("Card text must be a string");
    return clipboardData;
  }

  _getPositionAdjustment(data) {
    const adjustment = { x: 0, y: 0 };

    data = _.filter(data, (group) => group.cards.length > 0);
    const cardMinX = _.min(data, (card) => card.x).x;
    const cardMinY = _.min(data, (card) => card.y).y;
    const windowMinX = window.scrollX;
    const windowMinY = window.scrollY;
    const windowMaxX = windowMinX + window.innerWidth;
    const windowMaxY = windowMinY + window.innerHeight;

    if (cardMinX < windowMinX || cardMinX + ANCESTOR_MINIMUM_VISIBILITY_MARGIN > windowMaxX) {
      adjustment.x = windowMinX - cardMinX;
    }

    if (cardMinY < windowMinY || cardMinY + ANCESTOR_MINIMUM_VISIBILITY_MARGIN > windowMaxY) {
      adjustment.y = windowMinY - cardMinY;
    }

    return { adjustment: adjustment, clipboardData: data };
  }

  _adjustItemPosition(adjustment, groupData) {
    return groupData.map((data) => {
      data.x = data.x + adjustment.x + OFFSET_FROM_ANCESTOR;
      data.y = data.y + adjustment.y + OFFSET_FROM_ANCESTOR;
      return data;
    });
  }

  _createItemsFromData(groupData) {
    const group = this.sheetModel.createGroup({ x: groupData.x, y: groupData.y }, groupData.name, { empty: true });

    groupData.cards.forEach((card) => {
      const newCard = group.createCard();
      newCard.type(card.text);
      newCard.colorize(card.colorIndex);
    });

    group.toggleSelect();
  }
}

module.exports = SheetClipboardDataProcessor;
