/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
require("../lib/jquery_extensions/emojify");
const Base = require("./base");

class EditName extends Base {
  get events() {
    return {
      keydown: "hiBlurName",
      "keyup input": "hiChangeName",
      "change input": "hiChangeName",
      "mousedown input": "hiStartFocus",
      "mouseup input": "hiFinishFocus",
    };
  }

  constructor(...args) {
    super(...args);
    this.initializeEmojis = this.initializeEmojis.bind(this);
    this.initializeLock = this.initializeLock.bind(this);
    this.hiBlurName = this.hiBlurName.bind(this);
    this.hiChangeName = this.hiChangeName.bind(this);
    this.hiStartFocus = this.hiStartFocus.bind(this);
    this.hiFinishFocus = this.hiFinishFocus.bind(this);
    this.updateName = this.updateName.bind(this);
  }

  initialize(attributes) {
    super.initialize(attributes);
    this.render();
    this.initializeEmojis();
    this.initializeLock();
    this.listenTo(this.model, "change:name", this.updateName);
    if (this.model.get("name") === undefined) {
      if (this.model.defaultName != null) {
        return this.model.set("name", this.model.defaultName());
      }
    }
  }

  render() {
    return this;
  }

  remove() {
    this.$("input")
      .empty()
      .off();
    this.undelegateEvents();
    this.stopListening();
    return this;
  }

  initializeEmojis() {
    return this.$("input").emojify(this.model.board());
  }

  initializeLock() {
    this.editLock = this.createEditLock(this.$("input")[0]);
  }

  hiBlurName(event) {
    const isEnter = event.keyCode === 13;
    if (isEnter) {
      return this.$("input").blur();
    }
  }

  hiChangeName() {
    return this.model.set("name", this.$("input").val());
  }

  hiStartFocus() {
    this.wasFocused = this.$("input").is(":focus");
  }

  hiFinishFocus() {
    if (!this.wasFocused) {
      return this.$("input").select();
    }
  }

  updateName(board, name, options) {
    if (this.$("input").val() !== name) {
      this.$("input")
        .val(name)
        .adjustWidth();
    }
    if (options != null ? options.rebroadcast : undefined) {
      return this.editLock.lock(1000);
    }
  }
}

module.exports = EditName;
