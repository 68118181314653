/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const utils = require("../utils");

const Base = require("./base");
const EditName = require("./edit_name");
const EditAccess = require("./edit_access");
const BrowserTab = require("./browser_tab");
const SheetList = require("./sheet_list");

class Header extends Base {
  get el() {
    return "#board-nav";
  } // This should be #header, which means a bunch of code changes

  get events() {
    return { keydown: "hiBlurBoardName" };
  }

  constructor(...args) {
    super(...args);
    this.hiBlurBoardName = this.hiBlurBoardName.bind(this);
  }

  initialize(attributes) {
    super.initialize(attributes);
    this.listenTo(Backbone, "modal-open", this.disableSheetOptions, this);
    this.listenTo(Backbone, "modal-close", this.enableSheetOptions, this);
    return this.render();
  }

  render() {
    this.browserTab = new BrowserTab({ model: this.model });
    this.editBoardName = new EditName({
      model: this.model,
      el: this.$(".name")[0],
    });
    this.editAccess = new EditAccess({
      model: this.model,
      el: $(".board-access"),
    });
    this.sheetList = new SheetList({ model: this.model });
    this.$el.closest("#header").css("z-index", utils.zIndexManager.HEADER_Z_INDEX);
    return this;
  }

  remove() {
    this.sheetList.remove();
    this.editBoardName.remove();
    return super.remove();
  }

  setDefaultSheetName() {
    const date = new Date();
    const today = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    return this.$el.find("#new-sheet input").val(today);
  }

  disableSheetOptions() {
    this.$el
      .find("input.header__input")
      .removeClass("header__input--editable")
      .attr("disabled", true);
    this.$el
      .closest("#header")
      .find("#sheet-list input.sheet-name.header__input")
      .removeClass("header__input--editable")
      .attr("disabled", true);
    this.$el
      .closest("#header")
      .find("#sheet-list .header__menu-toggle")
      .addClass("header__menu-toggle--disabled")
      .attr("js-disabled", true);
    this.$el
      .closest("#header")
      .find(".header__small-action.board-access")
      .addClass("header__small-action--disabled")
      .attr("js-disabled", true);
  }

  enableSheetOptions() {
    this.$el
      .find("input.header__input")
      .addClass("header__input--editable")
      .attr("disabled", false);
    this.$el
      .closest("#header")
      .find("#sheet-list input.sheet-name.header__input")
      .addClass("header__input--editable")
      .attr("disabled", false);
    this.$el
      .closest("#header")
      .find("#sheet-list .header__menu-toggle")
      .removeClass("header__menu-toggle--disabled")
      .attr("js-disabled", false);
    this.$el
      .closest("#header")
      .find(".header__small-action.board-access")
      .removeClass("header__small-action--disabled")
      .attr("js-disabled", false);
  }

  /*
      human interaction event handlers
  */

  hiBlurBoardName(event) {
    const isEnter = event.keyCode === 13;
    if (isEnter) {
      return this.$("input#name").blur();
    }
  }
}

module.exports = Header;
