const _ = require("underscore");
const Backbone = require("backbone");

const User = require("./user");

class Collaborator extends Backbone.Model {
  get idAttribute() {
    return "_id";
  }

  get ephemeralProperties() {
    return ["lastHeartbeat", "hilight", "command"];
  }

  initialize(attrs) {
    this.set("board", attrs.board);
    this.set("user", new User(attrs.user));
    this.set("lastHeartbeat", attrs.lastOnlineAt);
    this.set("sheetIds", []);
    this.set("boards", attrs.boards);
  }

  board() {
    return this.get("board");
  }

  boards() {
    return this.get("boards");
  }

  user() {
    return this.get("user");
  }

  userRole() {
    return this.get("userRole");
  }

  role() {
    return this.get("role");
  }

  lastHeartbeat() {
    return this.get("lastHeartbeat");
  }

  sheetIds() {
    return this.get("sheetIds");
  }

  hilight() {
    return this.get("hilight");
  }

  command() {
    return this.get("command");
  }

  isModerator() {
    return this.role() === "moderator";
  }

  makeModerator() {
    this.set("role", "moderator");
  }

  makeEditor() {
    this.set("role", "editor");
  }

  addSheetId(sheetId, options) {
    const sheetIds = this.sheetIds();
    if (!_(sheetIds).contains(sheetId)) {
      this.set("sheetIds", sheetIds.concat([sheetId]), options);
    }
  }

  removeSheetId(sheetId, options) {
    const sheetIds = this.sheetIds();
    if (_.contains(sheetIds, sheetId)) {
      this.set(
        "sheetIds",
        _(sheetIds).reject((id) => id === sheetId),
        options
      );
    }
  }

  lastActivity() {
    if (this.onCurrentSheet()) {
      return "online";
    }
    const date = new Date(this.lastHeartbeat());
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  onCurrentSheet() {
    if (this.board().currentCollaborator() === this) {
      return true;
    }
    const currentSheetId = this.board().activeSheetId();
    return _(this.sheetIds()).contains(currentSheetId);
  }

  requestThemes() {
    this.set("command", this.themeCommand());
  }

  // Command factory methods

  // note: we need the ts (timestamp) here otherwise this property doesn't change
  //       at all, so the listener doesn't here it, in the case that someone
  //       tries to trigger a second theming without reloading the page
  themeCommand() {
    return {
      type: "theme",
      ts: Date.now(),
    };
  }
}

module.exports = Collaborator;
