const Backbone = require("backbone");

class Invitation extends Backbone.Model {
  get idAttribute() {
    return "_id";
  }

  get ephemeralProperties() {
    return ["board"];
  }

  board() {
    return this.get("board");
  }

  email() {
    return this.get("email");
  }

  role() {
    return this.get("role");
  }

  code() {
    return this.get("code");
  }

  invitedAt() {
    return this.get("invitedAt");
  }

  acceptedAt() {
    return this.get("acceptedAt");
  }

  collaboratorId() {
    return this.get("collaborator");
  }

  invitingCollaboratorId() {
    return this.get("invitingCollaborator");
  }

  isModerator() {
    return this.role() === "moderator";
  }

  collaborator() {
    return this.board().collaboratorForId(this.collaboratorId());
  }

  invitingCollaborator() {
    return this.board().collaboratorForId(this.invitingCollaboratorId());
  }

  resend() {
    this.trigger("resend", this);
  }
}

module.exports = Invitation;
