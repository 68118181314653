const logger = require("./logger").instance;

const fixGrammarlyBugsnagErrors = async event => {
  // Grammarly throws these ResizeObserver errors ALL THE TIME
  // We just need to ignore them. -mike
  if (event.originalError === "ResizeObserver loop limit exceeded") {
    const grammarly = $("grammarly-extension");
    if (grammarly.length > 0) {
      logger.warn("Grammarly extension threw ResizeObserver error - ignoring");
      return false;
    }
  }

  return true;
};

Bugsnag.addOnError(fixGrammarlyBugsnagErrors);
