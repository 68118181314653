require("../lib/jquery_extensions/bounds");
require("../lib/jquery_extensions/droppable");
require("../lib/jquery_extensions/draggable");
require("../lib/jquery_extensions/trim_input");
require("../lib/jquery_extensions/hammer");
require("../lib/jquery_extensions/emojify");

const _ = require("underscore");
const Draggable = require("./draggable");
const Card = require("./card");
const utils = require("../utils");

class Group extends Draggable {
  get className() {
    return "group";
  }

  get nameDecorated() {
    return this._nameDecorated || false;
  }

  set nameDecorated(nameDecorated) {
    this._nameDecorated = nameDecorated;
  }

  get template() {
    return _.template(`\
      <div class='background'></div>
      <div class='notice' style='display: none'></div>
      <div class='name-container'><input type='text' class='name' placeholder='Group Name'></input></div>
      <button class='add-card'>+</button>\
    `);
  }

  get events() {
    this._events = this._events || {
      click: "hiClick",
      mousedown: "hiMouseDown",
      mouseup: "hiMouseUp",
      mousemove: "hiMouseMove",
      "keyup .name": "hiChangeGroupName",
      "change .name": "hiChangeGroupName",
      "keydown .name-container": "hiBlurGroupName",
      "click .add-card": "hiRequestNewCard",
      mouseenter: "hiFocus",
      mouseleave: "hiUnfocus",
    };

    return this._events;
  }

  get touchEvents() {
    this._touchEvents = this._touchEvents || {
      "tap .add-card": "hiRequestNewCard",
    };
    return this._touchEvents;
  }

  attributes() {
    return {
      id: `group-${this.model.id}`,
      "data-id": this.model.id,
    };
  }

  initialize(attributes) {
    super.initialize(attributes);
    this.listenTo(this, "attach", this.onAttach, this);
  }

  finalize() {
    this.undelegateEvents();
    this.stopListening();
  }

  startListening() {
    this.listenTo(this.model, "change:_id", this.updateId, this);
    this.listenTo(this.model, "change:name", this.updateName, this);
    this.listenTo(this.model, "change:x", this.updateX, this);
    this.listenTo(this.model, "change:y", this.updateY, this);
    this.listenTo(this.model, "change:z", this.updateZ, this);
    this.listenTo(this.model, "change:hover", this.updateHover, this);
    this.listenTo(this.model, "change:state", this.updateState, this);
    this.listenTo(this.model, "change:isSelected", this.updateSelected, this);

    this.listenTo(this.model.cards(), "add", this.addCard, this);
    this.listenTo(this.model.cards(), "remove", this.removeCard, this);
    this.listenTo(this.model.cards(), "sort", this.reorderCards, this);
    this.listenTo(this.model.cards(), "change:text", this.resize, this);
    this.listenTo(this.model.cards(), "change:deleted", this.softDeleteCard, this);
  }

  onAttach() {
    this.render();
    this.initializeCards();
    this.initializeEmojis();
    this.initializeDraggable();
    this.initializeDroppable();
    this.initializeLocks();
    this.$(".name").trimInput(80);
    this.resize();
    this.startListening();
  }

  dispose() {
    this.remove();
    this.disposeCardViews();
  }

  initializeEmojis() {
    this.$("input.name").emojify(this.model.board());
  }

  initializeLocks() {
    this.dragLock = this.createDragLock();
    this.editLock = this.createEditLock(".name");
  }

  initializeCards() {
    this.cardViews = [];
    this.model.cards().each(this.addCard, this);
  }

  initializeDraggable() {
    this.$el.draggable({
      canvas: this.$el.closest(".sheet"),
      isTarget(target) {
        if ($(target).is(".name")) {
          return false;
        }
        if ($(target).is(".add-card")) {
          return false;
        }
        if ($(target).is(".card .content *")) {
          return false;
        }
        if ($(target).is(".card .color")) {
          return false;
        }
        if ($(target).is(".card .card__plus-one--button .btn")) {
          return false;
        }
        if ($(target).is(".card .delete-btn")) {
          return false;
        }
        return true;
      },
      isOkToDrag: () => {
        return !this.model.isBeingDragged();
      },
      isDragCanceled: () => {
        return this.model.get("state") !== "dragging";
      },
      onMouseDown: () => {},
      onMouseUp: () => {},
      onMouseMove: () => {
        this.onDrag();
      },
      startedDragging: () => {
        this.startedDragging();
      },
      stoppedDragging: () => {},
      dropped: () => {
        this.dropped();
        this.model.drop();
        this.model.bringForward();
        this.updateZIndex();
      },
    });
  }

  initializeDroppable() {
    this.$el.droppable({
      getZ: () => this.model.get("z"),
      onHover: (event, target) => {
        const location = this.dropLocation(target);
        this.model.hover(location);
      },
      onBlur: (event, target) => {
        const location = this.dropLocation(target);
        this.model.blur(location);
      },
      onDrop: (event, target) => {
        const id = $(target).attr("data-id");
        const location = this.dropLocation(target);
        if ($(target).is(".card")) {
          this.model.dropCard(id, location);
        }
        if ($(target).is(".group")) {
          this.model.dropGroup(id, location);
        }
        this.model.blur();
      },
    });
  }

  dropLocation(target) {
    let id;
    const bounds = $(target).bounds();
    const targetId = $(target).attr("data-id");
    const cards = this.$(".card");
    for (const cardDiv of Array.from(cards)) {
      id = $(cardDiv).attr("data-id");
      if (id !== targetId) {
        const cardBounds = $(cardDiv).bounds();
        const upper = cardBounds.upperHalf().extendUp(6);
        const lower = cardBounds.lowerHalf().extendDown(6);
        if (upper.contains(bounds.middle())) {
          return { id, position: "above" };
        }
        if (lower.contains(bounds.middle())) {
          return { id, position: "below" };
        }
      }
    }
    return { id: targetId };
  }

  /*
      render
  */

  render() {
    this.$el.html(this.template());
    this.$el.hammer();
    this.updateName(this.model, this.model.get("name"));
    this.updatePosition(this.model.get("x"), this.model.get("y"));
    this.updateZ(this.model, this.model.get("z"));
    this.updateGroupChrome();
    this.updateSelected();
    return this;
  }

  updateId(group, id) {
    this.$el.attr("id", `group-${id}`);
    this.$el.attr("data-id", id);
  }

  updateName(group, name, options) {
    if (this.$(".name").val() !== name) {
      this.$(".name")
        .val(name)
        .adjustWidth();
      if (options && options.rebroadcast && !options.replay) {
        const userIdentity = this.model.board().userIdentityForId(group.get("author"));
        if (userIdentity) {
          this.editLock.lock(1000, userIdentity.avatar(), `${userIdentity.displayName()} is typing...`);
        }
      }
    }
  }

  updateX(group, x, options) {
    this.updatePosition(x, group.get("y"), options);
  }

  updateY(group, y, options) {
    this.updatePosition(group.get("x"), y, options);
  }

  updatePosition(x, y, options) {
    this.moveTo({ x, y });
    if (options && options.rebroadcast && !options.replay) {
      const userIdentity = this.model.board().userIdentityForId(this.model.get("author"));
      if (userIdentity) {
        this.dragLock.lock(1000, userIdentity.avatar(), userIdentity.displayName());
      }
      this.updateZIndex();
    }
  }

  updateZ(group, z) {
    let zIndex;
    if (this.model.isDragging()) {
      zIndex = utils.zIndexManager.OBJECT_DRAGGING_Z_INDEX;
    } else if (this.model.isBeingDragged()) {
      zIndex = utils.zIndexManager.OBJECT_BEING_DRAGGED_Z_INDEX;
    } else if (this.focused) {
      zIndex = utils.zIndexManager.GROUP_FOCUSED_Z_INDEX;
    } else {
      zIndex = z * 10;
    }

    this.$el.css("z-index", zIndex);
  }

  updateZIndex() {
    this.updateZ(this.model, this.model.get("z"));
  }

  updateHover(group, hover) {
    if (hover) {
      this.$el.addClass("stackable");
      this.$el.removeClass("single-card");
    } else {
      this.$el.removeClass("stackable");
      this.updateGroupChrome();
    }
  }

  updateState(group, state) {
    const previous = group.previous("state");
    if (previous) {
      this.$el.removeClass(previous);
    }
    if (state) {
      this.$el.addClass(state);
    }
    this.updateZIndex();
  }

  updateGroupChrome() {
    if (this.model.aliveCards().length > 1) {
      const fadeComplete = () => {
        if (!this.nameDecorated) {
          this.$(".name").adjustWidth();
          this.nameDecorated = true;
        }
      };
      if (!this.$(".name-container").is(":visible")) {
        this.$(".name-container").fadeIn("slow", fadeComplete);
      }
      this.$(".add-card").show();
      this.$el.removeClass("single-card");
    } else {
      this.$(".name-container").hide();
      this.$(".add-card").hide();
      if (!this.$el.is("single-card")) {
        this.$el.addClass("single-card");
      }
    }
  }

  updateSelected() {
    this.$el.toggleClass("group__selected--self", this.model.isSelected());
  }

  addCard(card) {
    if (card.softDeleted()) {
      return;
    }
    const cardView = new Card({ model: card });
    this.displayCardView(cardView);
    cardView.trigger("attach");
    this.updateSelected();
    this.resize();
  }

  removeCard(card, _) {
    this.disposeCardView(card);
    this.updateGroupChrome();
    this.updateSelected();
    this.resize();
  }

  softDeleteCard(card, deleted, options) {
    if (!deleted) {
      return;
    }
    this.removeCard(card, undefined, options);
  }

  findCardView(card) {
    return _(this.cardViews).find(cv => cv.model === card);
  }

  displayCardView(cardView) {
    this.cardViews.push(cardView);
    this.$el.append(cardView.el);
    this.updateGroupChrome();
    this.calculateMinimalBoardSize();
  }

  disposeCardView(card) {
    const cardView = this.findCardView(card);
    if (!cardView) {
      return;
    } // may have already been disposed
    this.cardViews.splice(this.cardViews.indexOf(cardView), 1);
    cardView.dispose();
  }

  disposeCardViews() {
    _(this.cardViews).each(v => v.dispose());
    this.cardViews = [];
  }

  reorderCards(cards, options) {
    // re-ordering blurs, so let's re-focus as necessary
    const focusedCardId = this.model.sheet().focusedCardId();
    const ordered = _(this.$(".card")).sort((a, b) => {
      const cardA = this.model.findCard($(a).attr("data-id"));
      const cardB = this.model.findCard($(b).attr("data-id"));
      return this.model.cardSorter(cardA, cardB);
    });
    $(ordered).appendTo(this.$el);
    this.model.bringForward(options);
    this.model.sheet().focusCard(focusedCardId);
  }

  resize() {
    if (this.resizeTimeout) return;

    const resizeGroup = () => {
      this.model.resize(this.width(), this.height());
      this.resizeTimeout = undefined;
    };

    // we need to wait 30ms because it takes 10-20ms for the cards/groups to autoresize
    this.resizeTimeout = setTimeout(resizeGroup, 30);
  }

  /*
      human interaction event handlers
  */

  hiChangeGroupName() {
    const name = this.$(".name").val();
    this.model.set("name", name);
  }

  hiBlurGroupName(event) {
    const isEnter = event.keyCode === 13;
    if (isEnter) {
      return this.$(".name").blur();
    }
  }

  hiRequestNewCard(event) {
    event.stopPropagation();
    if (!(this.model.cards().length > 1)) {
      return;
    } // don't add new card unless there is already more than 1
    const card = this.model.createCard();
    this.model.sheet().set("focusedCardId", card.id);
  }

  hiClick(event) {
    this.hiBringForward();
    this.hiSelect(event);
    super.hiClick();
  }

  hiBringForward() {
    this.model.bringForward();
  }

  hiFocus() {
    this.focused = true;
    this.updateZIndex();
  }

  hiSelect(event) {
    if (!this.isValidClick(event)) return;

    const target = $(event.target);
    if (this.$el.is(".single-card") || !(target.is(".group") || target.is(".name-container"))) {
      return;
    }

    if (event.ctrlKey || event.metaKey) {
      this.model.toggleSelect();
    } else {
      this.model.sheet().deselectAll();
      this.model.select();
    }
  }

  hiUnfocus() {
    this.focused = false;
    this.updateZIndex();
  }
}

module.exports = Group;
