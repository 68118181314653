/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _ = require("underscore");
const Base = require("./base");

class PresenceCounter extends Base {
  get el() {
    return ".presence-bar__counter";
  }

  get views() {
    return {};
  }

  body(onlineCount, offlineCount) {
    return _.template(`\
<div class='online tooltip-parent'>
  <div class='icon'></div>
  <div class='counter'>${onlineCount}</div>
  <span class='tooltip tooltip--above-left'>Online Collaborators</span>
</div>
<div class='offline tooltip-parent'>
  <div class='icon'></div>
  <div class='counter'>${offlineCount}</div>
  <span class='tooltip tooltip--above'>Offline Collaborators</span>
</div>\
`);
  }

  initialize(attributes) {
    super.initialize(attributes);

    this.listenTo(this.model, "change", this.render, this);

    return this.render();
  }

  render() {
    const onlineCount = this.model.get("online-collaborators") || "";
    const offlineCount = this.model.get("offline-collaborators") || "";
    return this.$el.html(this.body(onlineCount, offlineCount));
  }
}

module.exports = PresenceCounter;
