/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

const utils = require("../../utils");

const { Textcomplete } = require("@textcomplete/core");
const { TextareaEditor } = require("@textcomplete/textarea");

$.fn.emojify = function(board) {
  const emojis = board.get("emojis");

  const emoji = function(unified) {
    const pieces = unified.split("-");
    const ints = _(pieces).map(piece => parseInt(piece, 16));
    return String.fromCodePoint(...Array.from(ints || []));
  };

  let editor;
  try {
    editor = new TextareaEditor(this.get(0));
  } catch (e) {
    console.warn("Textarea editor not initialized");
  }
  const spanify = (text, term) => {
    return text.replace(term, `<span class="dropdown-menu--highlight">${term}</span>`);
  };
  if (!editor) {
    return;
  }
  const tc = new Textcomplete(
    editor,
    [
      {
        match: /\B:(\*?[-+\w]*)$/,
        search: async (term, callback) => {
          const shortNames = Object.keys(emojis).sort();

          // prefix matches are first, then any match
          const filtered1 = shortNames.filter(sn => sn.indexOf(term) === 0);
          const filtered2 = shortNames.filter(sn => sn.indexOf(term) > 0);
          const filtered = filtered1.concat(filtered2);

          const mapped = filtered.map(sn => ({ shortName: sn, unified: emojis[sn], term: term }));
          return callback(mapped);
        },
        replace: value => {
          return `${emoji(value.unified)} `;
        },
        template: value => {
          return `${emoji(value.unified)} ${spanify(value.shortName, value.term)}`;
        },
      },
    ],
    {
      dropdown: {
        style: { zIndex: utils.zIndexManager.EMOJI_Z_INDEX },
        dynamicWidth: true,
      },
    }
  );
  this.on("blur click", () => {
    tc.dropdown.hide();
  });
  this.closest(".card").on("mousedown", () => {
    tc.dropdown.hide();
  });
};
