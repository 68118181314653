/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const Base = require("./base");
const Sheet = require("../models/sheet");

const EditName = require("./edit_name");

class SheetList extends Base {
  get el() {
    return "#sheet-list";
  }

  get template() {
    return _.template(`\
      <div class="sheet-toolbar">
        <div class='name'>
          <input type="text" class="sheet-name header__input header__input--editable header__editable-menu" autocomplete="off">
        </div>
        <div class="header__menu-toggle"></div>
      </div>
      <div class="menu menu--within-header-control menu--down">
        <div class="menu__content">
          <ul class="menu__items"></ul>
          <div class="new-sheet menu__item menu__item--control">
            <input class="new-sheet-name menu__input" required type="text"
                  placeholder="Enter Sheet Title" />
            <button class="button menu__button menu__button--primary">Add Sheet</button>
          </div>
        </div>
      </div>\
    `);
  }

  get _sheetChoice() {
    return _.template(`\
      <li data-id="<%- id %>" class="menu__item sheet-choice <%- classes %>"><%- name() %></li>\
    `);
  }

  get events() {
    return {
      click: "stopPropagation",
      "click .sheet-choice": "hiChangeSheet",
      "click .header__menu-toggle": "hiToggleDrawer",
      "click .new-sheet button": "hiAddSheet",
      "keypress .new-sheet input": "hiAddSheet",
    };
  }

  initialize(attributes) {
    super.initialize(attributes);

    this.currentCollaborator = this.model.currentCollaborator();

    $(document).on("click", event => this.hiCloseDrawer(event));

    this.listenTo(this.model, "change:activeSheetId", this.updateActiveSheet, this);
    this.listenTo(this.model.sheets(), "change:name add remove", this.updateChoices, this);
    this.listenTo(this.currentCollaborator, "change:role", this.updateRole, this);

    this.render();
  }

  render() {
    this.$el.html(this.template());
    this.updateChoices();
    this.updateEdit();
    this.updateRole();
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  updateActiveSheet() {
    if (!this.model.activeSheet()) {
      return;
    }

    this.updateChoices();
    this.updateEdit();
  }

  updateChoices() {
    const sheetChoices = _.chain(this.model.sheets().models)
      .filter(sheet => sheet.id != null)
      .sortBy(sheet => sheet.get("created"))
      .map(sheet => {
        const classes = [];
        const activeSheet = this.model.activeSheet();
        if (activeSheet && activeSheet.id === sheet.id) {
          classes.push("menu__item--active");
        }
        sheet.classes = classes.join(" ");
        return this._sheetChoice(sheet);
      })
      .value()
      .join("");
    this.$el.find(".menu__items").html(sheetChoices);
  }

  updateEdit() {
    const $div = this.$el.find(".name");
    const $input = $div.find("input");

    const activeSheet = this.model.activeSheet();
    $input.val(activeSheet && activeSheet.name());

    if (this.editSheetName != null) {
      this.editSheetName.remove();
    }
    this.editSheetName = new EditName({
      model: this.model.activeSheet(),
      el: $div.get(0),
    });
  }

  updateNewSheetInput() {
    this.$el.find(".new-sheet input").attr("placeholder", Sheet.defaultName());
  }

  updateRole() {
    const rawClasses = this.$el.attr("class");
    const classes = rawClasses ? rawClasses.split(" ") : [];
    for (const cls of classes) {
      if (cls.match(/^sheet-list--role-/)) {
        this.$el.removeClass(cls);
      }
    }
    this.$el.addClass(`sheet-list--role-${this.currentCollaborator.role()}`);
  }

  remove() {
    this.editSheetName.remove();
    $(document).off("click", this.hiHideSheetChoices);
    super.remove();
  }

  hiChangeSheet(event) {
    const id = $(event.currentTarget).data("id");
    this.model.setActiveSheetId(id);
    this.updateEdit();
    this.hiCloseDrawer();
  }

  hiToggleDrawer(event) {
    this.updateNewSheetInput();
    if (this.$el.find(".header__menu-toggle").attr("js-disabled") === "true") {
      return;
    }
    const isOpen = this.$el.hasClass("header__control--expanded");
    $(document).trigger(event);
    if (isOpen) {
      this.hiCloseDrawer();
    } else {
      this.hiOpenDrawer();
    }
    event.stopPropagation();
  }

  hiOpenDrawer(_event) {
    this.$el.addClass("header__control--expanded");
    this.$(".menu").addClass("menu--open");
  }

  hiCloseDrawer(_event) {
    this.$el.removeClass("header__control--expanded");
    this.$(".menu").removeClass("menu--open");
  }

  hiAddSheet(event) {
    if (event.which === 13 || event.type === "click") {
      const $nameInput = this.$el.find(".new-sheet input");

      this.model.addSheet($nameInput.val() || Sheet.defaultName());
      $nameInput.val("");
      this.choicesVisible = false;
      this.updateChoices();
      this.hiCloseDrawer();
      this.model.setActiveSheetId(this.model.lastSheetCreated().id);
    }
  }
}

module.exports = SheetList;
