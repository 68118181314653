const IScroll = require("iscroll");
const utils = require("../utils");
const ManageCollaborators = require("./manage_collaborators");
const Base = require("./base");
const Collaborator = require("./collaborator");
const PresenceModel = require("../models/presence");
const PresenceCounter = require("./presence_counter");

class Presence extends Base {
  get el() {
    return "#presence";
  }

  get events() {
    return {
      "click .presence-bar__manage-collaborators:not(.presence-bar__manage-collaborators--disabled)"() {
        const newHash = (window.location.hash || "#") + "&collaborators";
        return window.router.navigateHash(newHash);
      },
    };
  }

  get sectionFilters() {
    return {
      ".current"(collaborator) {
        return collaborator === this.model.currentCollaborator();
      },
      ".moderators"(collaborator) {
        return collaborator !== this.model.currentCollaborator() && collaborator.isModerator();
      },
      ".collaborators"(collaborator) {
        return collaborator !== this.model.currentCollaborator() && !collaborator.isModerator();
      },
    };
  }

  constructor(...args) {
    super(...args);
    this.render = this.render.bind(this);
    this.updatePresence = this.updatePresence.bind(this);
    this.updateZIndex = this.updateZIndex.bind(this);
    this.updateSections = this.updateSections.bind(this);
    this.updateScroll = this.updateScroll.bind(this);
    this.updatePlusButton = this.updatePlusButton.bind(this);
    this.getCollaboratorView = this.getCollaboratorView.bind(this);
    this.addCollaboratorView = this.addCollaboratorView.bind(this);
    this.removeCollaboratorView = this.removeCollaboratorView.bind(this);
    this.finalize = this.finalize.bind(this);
    this.scrollTimeout = undefined;
  }

  initialize(attributes) {
    super.initialize(attributes);
    this.views = {};

    this.listenTo(this.model.currentCollaborator(), "change:role", this.updatePlusButton, this);

    this.listenTo(this.model.collaborators(), "change:sheetIds", this.updateSections, this);
    this.listenTo(this.model.collaborators(), "change:role", this.updateSections, this);
    this.listenTo(this.model.collaborators(), "add", this.addCollaboratorView, this);
    this.listenTo(this.model.collaborators(), "remove", this.removeCollaboratorView, this);
    this.listenTo(this.model.collaborators(), "change:sheetIds", this.updatePresence, this);

    this.render();

    this.scroll = new IScroll(this.$el.find(".collaborator-bar").get(0), {
      scrollbars: true,
      fadeScrollbars: true,
      mouseWheel: true,
    });

    this.presenceCounter = new PresenceCounter({
      model: new PresenceModel(),
      el: this.$el.find(".presence-bar__counter").get(0),
    });

    return this.updateZIndex();
  }

  render() {
    this.model.collaborators().forEach(collaborator => {
      return this.addCollaboratorView(collaborator);
    });
    return this.updatePlusButton();
  }

  updatePresence() {
    return this.presenceCounter.model.updatePresenceFromCollaborators(this.model.collaborators().models);
  }

  updateZIndex() {
    this.$el.css("z-index", utils.zIndexManager.PRESENCE_Z_INDEX);
    this.$el.find(".presence-bar__section").css("z-index", utils.zIndexManager.PRESENCE_Z_INDEX + 2);
    this.$el.find(".presence-bar__counter").css("z-index", utils.zIndexManager.PRESENCE_Z_INDEX + 2);
    this.$el.find(".presence-bar__manage-collaborators").css("z-index", utils.zIndexManager.PRESENCE_Z_INDEX + 2);
    return this.$el.find(".collaborator-bar").css("z-index", utils.zIndexManager.PRESENCE_Z_INDEX + 1);
  }

  updateSections() {
    const all = this.model.collaborators().models;
    this.$el.find(".presence-bar__section").hide();

    for (const selector in this.sectionFilters) {
      const filter = this.sectionFilters[selector];
      const $section = this.$el.find(selector);

      const collabs = all.filter(filter, this);
      if (collabs.length > 0) {
        $section.show();
      }

      for (const collab of collabs) {
        const view = this.getCollaboratorView(collab);
        if (view) {
          const node = this.$el.find(`#${view.id()}`);
          const container = collab.onCurrentSheet() ? $section.find(".online") : $section.find(".offline");
          if (node.length > 0) {
            if (!container.find(node).length > 0) {
              container.append(node);
            }
          } else {
            container.append(view.el);
          }
        }
      }
    }
  }

  updateScroll() {
    if (this.scrollTimeout) return;

    const refreshScroll = () => {
      this.scroll.refresh();
      this.scrollTimeout = undefined;
    };

    this.scrollTimeout = setTimeout(refreshScroll, 100);
  }

  updatePlusButton() {
    const disabled = this.model.isPrivate() && !this.model.currentCollaborator().isModerator();
    this.$(".presence-bar__manage-collaborators").toggleClass("presence-bar__manage-collaborators--disabled", disabled);
  }

  getCollaboratorView(collaborator) {
    return this.views[collaborator.id];
  }

  addCollaboratorView(collaborator) {
    const view = new Collaborator({ model: collaborator });
    this.views[collaborator.id] = view;
    this.updateSections();
    this.updateScroll();
  }

  removeCollaboratorView(collaborator) {
    this.getCollaboratorView(collaborator).remove();
    delete this.views[collaborator.id];
    this.updateSections();
    this.updateScroll();
  }

  showManageCollaborators(closeAction) {
    if (closeAction == null) {
      closeAction = false;
    }
    this.manageCollaboratorsView = new ManageCollaborators(this.model, closeAction);
  }

  removeManageCollaborators() {
    if (this.manageCollaboratorsView) {
      this.manageCollaboratorsView.remove();
    }
  }

  finalize() {
    this.model.collaborators().forEach(collaborator => {
      this.removeCollaboratorView(collaborator);
    });
  }
}

module.exports = Presence;
