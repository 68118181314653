import Avatar from "avatar-initials";

const initialify = (img, initials) => {
  return Avatar.from(img, {
    useGravatar: false,
    initials,
    initial_weight: 400,
    initial_font_family: "'Helvetica Neue', Helvetica, Arial, sans-serif",
  });
};

const started = [];
const completed = [];

// So... here's the deal.  This is confusing.
// For every avatar, we make an XHR HEAD request to that image directly.
// We then handle various "error" cases by generating initials and replacing
// the image with them.
// We cache the results so that we only need to make one HEAD request per
// unique avatar image.
//
// Error cases:
//
// 1. Twitter: returns 404 but with a default image.  This causes `fail()` to be called.
// 2. Google:  returns 200 and a default image, which happens to be a PNG of various specific sizes that are always changing.  (Ugly)
// 3. Facebook: ???

const finish = function(key, img) {
  // mark this key as complete
  completed[key] = $(img).attr("src");

  if (started[key] == null) {
    return;
  }

  // call all the saves callbacks, and mark it as not started
  for (const cb of started[key]) {
    cb(key);
  }
  delete started[key];
};

const keyFor = (img, initials) => `${initials}-${$(img).attr("src")}`;

const query = function(img, initials) {
  const key = keyFor(img, initials);
  started[key] = [];

  const url = $(img).prop("src");
  $.ajax({ method: "head", url })
    .fail((_jqxhr, _status, _error) => {
      initialify(img, initials);
      finish(key, img);
    })
    .done((data, status, jqxhr) => {
      const type = jqxhr.getResponseHeader("Content-Type");
      const length = jqxhr.getResponseHeader("Content-Length");
      const fakes = ["491", "493", "680", "690", "1224", "1239", "5737"];
      if (type === "image/png" && fakes.indexOf(length) >= 0) {
        initialify(img, initials);
      }
      finish(key, img);
    });
};

const update = function(key, img) {
  if (completed[key].match(/^data:/)) {
    $(img).attr("src", completed[key]);
  }
};

$.fn.initials = function(ins) {
  this.each(function(index, img) {
    const initials = ins != null ? ins : $(img).attr("data-initials") || "?";
    const key = keyFor(img, initials);

    // if we already finished rendering these initials, just update this image
    if (completed[key] != null) {
      update(key, img);
      return;
    }

    // if we already started rendering these initials, add this image to be updated when we're done
    if (started[key] != null) {
      started[key].push(key => update(key, img));
      return;
    }

    // otherwise start the query / rendering process
    query(img, initials);
  });
};
