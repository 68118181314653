const Backbone = require("backbone");

const utils = require("../utils");
const models = require("../models");

const BORDER_BUFFER_HEIGHT = 200;
const BORDER_BUFFER_WIDTH = 400;
const BORDER_BUFFER_OFFSET = 100;

class Base extends Backbone.View {
  initialize(attributes) {
    super.initialize(attributes);
    this.logger = utils.Logger.instance;

    if (utils.touch.supported()) {
      $.extend(this.events, this.touchEvents || {});
      this.delegateEvents();
    }
  }

  createDragLock() {
    const onLock = (avatar, message) => {
      if (avatar != null && message != null) {
        this.showNotice(avatar, message);
      }
      this.model.set("state", "being-dragged", { rebroadcast: true });
    };
    const onUnlock = () => {
      this.model.unset("state", { rebroadcast: true });
      this.hideNotice();
    };
    return new models.Lock(onLock, onUnlock);
  }

  createEditLock(selector) {
    const onLock = (avatar, message) => {
      if (avatar != null && message != null) {
        this.showNotice(avatar, message);
      }
      this.lockEditing(selector);
    };
    const onUnlock = () => {
      this.hideNotice();
      this.unlockEditing(selector);
    };
    return new models.Lock(onLock, onUnlock);
  }

  unlockEditing(selector) {
    this.$(selector).removeAttr("disabled");
  }

  lockEditing(selector) {
    this.$(selector).attr("disabled", "disabled");
  }

  showNotice(avatar, message) {
    const notices = this.$(".notice");
    this.visibleNotice = notices.length === 2 ? notices.last() : notices.first(); // stupid single-card group hack
    if (!this.visibleNotice.is(":visible")) {
      const noticeHTML = `\
        <img class='avatar' src='${avatar}'/><span>${_.escape(message)}</span>\
      `;
      this.visibleNotice.html(noticeHTML).show();
    }
  }

  hideNotice() {
    if (this.visibleNotice) {
      this.visibleNotice.fadeOut(100, () => {
        this.visibleNotice.html("");
      });
    }
  }

  moveTo({ x, y }) {
    if (x == null || y == null) {
      this.$el.css({ left: "", top: "" });
    } else if (isNaN(Number(x)) || isNaN(Number(y))) {
      this.$el.css({ left: x != null ? x : "", top: y != null ? y : "" });
    } else {
      const $parent = this.$el.offsetParent();
      const parentOffset = $parent.offset();

      const style = getComputedStyle(this.$el.get(0));
      const parentStyle = getComputedStyle($parent.get(0));

      // offset is affected by borders and margins. without them, left/top
      // wouldn't be perfectly reflective of what x/y are and cause jittering

      const adjustX = parseInt(parentStyle.borderLeftWidth, 10);
      const adjustY = parseInt(parentStyle.borderTopWidth, 10) + parseInt(style.marginTop, 10);

      const left = x + parentOffset.left + adjustX;
      const top = y + parentOffset.top + adjustY;

      this.$el.offset({ left, top });
      this.calculateMinimalBoardSize();
    }
  }

  left() {
    const position = this.$el.position();
    return position ? position.left : 0;
  }

  top() {
    const position = this.$el.position();
    return position ? position.top : 0;
  }

  width() {
    return this.$el.width();
  }

  height() {
    return this.$el.height();
  }

  right() {
    return this.left() + this.width();
  }

  bottom() {
    return this.top() + this.height();
  }

  remove() {
    if (this.finalize) {
      this.finalize();
    }
    return super.remove();
  }

  needsResize() {
    const sheet = $(".sheet").get(0);
    if (!sheet) {
      return false;
    }

    // this.logger.warn(`bottom: ${this.bottom()}, right: ${this.right()}`);
    if (this.bottom() === 0 && this.right() === 0) {
      return true;
    }

    // this.logger.warn(`scrollHeight: ${sheet.scrollHeight}, scrollWidth: ${sheet.scrollWidth}`);
    const bottomBleed = this.bottom() + BORDER_BUFFER_HEIGHT - BORDER_BUFFER_OFFSET;
    const rightBleed = this.right() + BORDER_BUFFER_WIDTH - BORDER_BUFFER_OFFSET;

    // this.logger.warn(`needsResize = ${bottomBleed > sheet.scrollHeight || rightBleed > sheet.scrollWidth}`);
    return bottomBleed > sheet.scrollHeight || rightBleed > sheet.scrollWidth;
  }

  calculateMinimalBoardSize() {
    if (!this.needsResize()) {
      return;
    }
    if (!($(".group").length > 0)) {
      this.model.board().updateMinimalDimensions(0, 0);
      return;
    }

    const positions = $(".group")
      .map(function () {
        const pos = $(this).position();
        return {
          bottom: pos.top + $(this).height(),
          right: pos.left + $(this).width(),
        };
      })
      .get();

    const height = _(positions).max((p) => p.bottom).bottom + BORDER_BUFFER_HEIGHT;
    const width = _(positions).max((p) => p.right).right + BORDER_BUFFER_WIDTH;

    // this.logger.warn(`Update minimal dimentions with ${width} x ${height}`);
    this.model.board().updateMinimalDimensions(width, height);
  }
}

module.exports = Base;
