const utils = require("../utils");

class Lock {
  constructor(onLock, onUnlock) {
    this.clear = this.clear.bind(this);
    this.poll = this.poll.bind(this);
    this.lock = this.lock.bind(this);
    this.onLock = onLock;
    this.onUnlock = onUnlock;
    this.logger = utils.Logger.instance;
    this.data = undefined;
    this.interval = undefined;
  }

  clear() {
    clearInterval(this.interval);
    this.interval = undefined;
  }

  poll() {
    if (this.interval) return;

    const checkForExpiredLocks = () => {
      if (!this.data) {
        this.clear();
      }

      if (this.data) {
        const currentTime = new Date().getTime();
        const lockExpired = currentTime - this.data.locked > this.data.timeout;
        if (lockExpired) {
          this.onUnlock.apply(this, this.data.args);
          this.data = undefined;
          this.clear();
        }
      }
    };

    this.interval = setInterval(checkForExpiredLocks, 100);
  }

  lock(timeout, ...args) {
    const locked = new Date().getTime();
    this.data = { locked, timeout, args };

    if (!this.interval) {
      this.onLock.apply(this, args);
      this.poll();
    }
  }
}

module.exports = Lock;
