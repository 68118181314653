class Logger {
  constructor() {
    this.setLevel = this.setLevel.bind(this);
    this.setBoard = this.setBoard.bind(this);
    this.error = this.error.bind(this);
    this.warn = this.warn.bind(this);
    this.info = this.info.bind(this);
    this.debug = this.debug.bind(this);
    this.log = this.log.bind(this);
    this.timestamp = this.timestamp.bind(this);
    this.boardIdentity = this.boardIdentity.bind(this);
    this.serverLog = this.serverLog.bind(this);
    this.level = 1;
    this.board = null;
  }

  setLevel(level) {
    this.level = ["error", "warn", "info", "debug"].indexOf(level);
    if (this.level === -1) {
      this.level = 1;
    }
  }

  setBoard(board) {
    this.board = board;
  }

  error(msg) {
    this.log("ERROR", msg);
    this.serverLog("ERROR", msg);
  }

  warn(msg) {
    if (this.level >= 1) {
      this.log("WARN", msg);
    }
    this.serverLog("WARN", msg);
  }

  info(msg) {
    if (this.level >= 2) {
      this.log("INFO", msg);
    }
    if (this.level >= 2) {
      this.serverLog("INFO", msg);
    }
  }

  debug(msg) {
    if (this.level >= 3) {
      this.log("DEBUG", msg);
    }
  }

  log(level, msg) {
    level = level.length === 4 ? `${level} ` : level;
    if (typeof msg === "string") {
      console.log(`[${this.timestamp()} ${level}]  ${msg}`);
    } else {
      console.log(msg);
    }
  }

  timestamp() {
    const d = new Date();
    const pad = function(i) {
      if (i < 10) {
        return `0${i}`;
      } else {
        return `${i}`;
      }
    };
    return `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`;
  }

  boardIdentity() {
    return {
      id: this.board.id,
      name: this.board.get("name"),
    };
  }

  serverLog(level, msg) {
    this.socket.emit("log", {
      user: this.board
        .currentUser()
        .activeIdentity()
        .displayName(),
      board: this.boardIdentity(),
      version: this.board.version(),
      release: this.board.releaseIdentifier(),
      level,
      msg,
    });
  }
}

Logger.instance = new Logger();

module.exports = Logger;
