const ModalManager = require("../views/modal_manager");

module.exports = {
  packer: require("./packer"),
  Logger: require("./logger"),
  OfflineSocket: require("./offline_socket"),
  sanitizer: require("./sanitizer"),
  zIndexManager: require("./z_index_manager"),
  touch: require("./touch"),
  bugsnagger: require("./bugsnagger"),
  modalManager: new ModalManager(),
};
