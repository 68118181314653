const Backbone = require("backbone");
const validator = require("validator");

class MultipleEmailInput extends Backbone.Model {
  initialize() {
    const emails = new Backbone.Collection();
    this.set("emails", emails);
    this.set("emailInputValue", "");
    this.listenTo(emails, "update", this._triggerChange);
  }

  setEmailInputValue(value) {
    const delimiter = ",";
    const delimRegex = new RegExp(delimiter, "g");

    this.set("emailInputValue", value.replace(delimRegex, ""));

    if (!value.match(delimRegex)) {
      return;
    }

    this.addEmails(value.split(delimiter));
  }

  setEmailInputValueByTextBlob(value) {
    const cleanedEmails = value
      .split(/,|\s+/)
      .filter(chunk => /@/.test(chunk))
      .map(email => email.replace(/<|>/g, ""));

    this.addEmails(cleanedEmails);
  }

  addEmails(emailList) {
    emailList
      .filter(str => str !== "")
      .forEach(str => {
        this._addEmail(str.trim());
        this.set("emailInputValue", "");
      });
  }

  clearAll() {
    this.set("emailInputValue", "");
    this.emails().reset();
  }

  emails() {
    return this.get("emails");
  }

  get allEmailAddresses() {
    const addresses = this.emails().models.map(model => model.get("email"));
    return addresses.concat([this.get("emailInputValue")]).filter(address => typeof address === "string" && address !== "");
  }

  removeEmail(id) {
    this.emails().remove([id]);
  }

  validate() {
    const allAddresses = this.allEmailAddresses;
    if (!(allAddresses.length > 0 && allAddresses.every(address => validator.isEmail(address)))) {
      return "Not all e-mails provided are valid e-mail addresses";
    }
  }

  _addEmail(email) {
    this.emails().add([
      {
        email: email,
        className: validator.isEmail(email) ? "" : "invitation-email__pill--invalid",
      },
    ]);
  }

  _triggerChange() {
    this.trigger("change");
  }
}

module.exports = MultipleEmailInput;
