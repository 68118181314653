class Column {
  constructor(i, colWidth, numCols, maxX) {
    this.height = this.height.bind(this);
    this.contains = this.contains.bind(this);
    this.distanceToFit = this.distanceToFit.bind(this);
    this.fit = this.fit.bind(this);

    this.i = i;
    this.colWidth = colWidth;
    this.numCols = numCols;
    this.maxX = maxX;
    this.blocks = [];
  }

  x() {
    return this.i * this.colWidth;
  }

  height() {
    if (this.blocks.length === 0) {
      return 0;
    }
    const last = _(this.blocks).last();
    return last.fit.y + last.h;
  }

  contains(pos) {
    const mid = pos.x + pos.w / 2;
    if (this.i === 0 && mid < 0) {
      return true;
    }
    if (this.i === this.numCols && mid >= this.maxX) {
      return true;
    }
    return this.x() <= mid && mid < this.x() + this.colWidth;
  }

  distanceToFit(pos) {
    const dx = Math.abs(this.x() - pos.x);
    const dy = Math.abs(this.height() - pos.y);
    return Math.sqrt(dx * dx + dy * dy);
  }

  isEmpty() {
    return this.blocks.length === 0;
  }

  fit(block) {
    block.fit = { x: this.x(), y: this.height() };
    this.blocks.push(block);
  }

  refit() {
    for (const block of this.blocks) {
      block.fit.x = this.x();
    }
  }
}

module.exports = Column;
