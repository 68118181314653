require("../lib/jquery_extensions/hammer");

const Base = require("./base");
const Sheet = require("./sheet");
const Modal = require("./modal");

class Board extends Base {
  get el() {
    return ".board";
  }

  get className() {
    return "board";
  }

  get events() {
    return {};
  }

  get touchEvents() {
    return {};
  }

  set modal(mod) {
    this.mod = mod;
  }

  get modal() {
    return this.mod || {};
  }

  constructor(...args) {
    super(...args);
    this.updateStatus = this.updateStatus.bind(this);
    this.changeSheet = this.changeSheet.bind(this);
  }

  initialize(attributes) {
    super.initialize(attributes);
    this.render();
    this.initializeSheet();

    const debouncedUpdateViewerCoordinates = _.debounce(this.updateViewerCoordinates(this.model), 300);

    $(window).resize(() => this.calculateMinimalBoardSize());
    $(window).scroll(debouncedUpdateViewerCoordinates);

    this.model.on("change:status", this.updateStatus, this);
    this.model.on("change:activeSheetId", this.changeSheet, this);
    this.model.on("change:actualWidth", this._resizeWidth, this);
    this.model.on("change:actualHeight", this._resizeHeight, this);
  }

  updateViewerCoordinates(model) {
    return function() {
      model.set("viewerX", window.scrollX + window.innerWidth);
      model.set("viewerY", window.scrollY + window.innerHeight);
    };
  }

  initializeSheet() {
    this.changeSheet(this.model.currentUser(), this.model.activeSheet().id);
  }

  render() {
    this.$el.hammer();
    this.updateStatus(this, this.model.get("status"));
  }

  updateStatus(board, status) {
    if (status) {
      this.modal = new Modal({
        title: status,
        priority: 100,
        closeAction() {
          return false;
        },
      });
    } else if (this.modal.remove) {
      this.modal.remove();
    }
  }

  changeSheet(currentUser, sheetId) {
    if (this.sheetView) {
      this.sheetView.dispose();
      this.sheetView.model.off("rendered");
      delete this.sheetView;
    }

    const sheet = this.model.findSheetById(sheetId);
    sheet.on("rendered", () => this.model.trigger("rendered"));
    this.sheetView = new Sheet({ model: sheet });
    this.$el.append(this.sheetView.el);
    this.sheetView.trigger("attach");
    this.calculateMinimalBoardSize();
  }

  _resizeWidth() {
    $(this.$el).css("min-width", this.model.get("actualWidth"));
  }

  _resizeHeight() {
    $(this.$el).css("min-height", this.model.get("actualHeight"));
  }
}

module.exports = Board;
