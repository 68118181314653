/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _ = require("underscore");
const Backbone = require("backbone");
const modalManagerSingleton = require("../utils").modalManager;

class Modal extends Backbone.View {
  get el() {
    return "#modal";
  }

  get template() {
    return _.template(`\
      <div id="modal-container">
        <div id="modal-backdrop">
        </div>
        <div id="modal-box">
          <a class="close-button">×</a>
          <div class="title">Modal</div>
          <div class="message"></div>
          <ul class="buttons">
          </ul>
        </div>
      </div>\
    `);
  }

  get events() {
    return {
      "click .button": "hiClick",
      "click #modal-backdrop": "close",
      "click .close-button": "close",
    };
  }

  constructor(...args) {
    super(...args);
    this.initialize = this.initialize.bind(this);
    this.render = this.render.bind(this);
    this.hiClick = this.hiClick.bind(this);
    this.close = this.close.bind(this);
    this.remove = this.remove.bind(this);
  }

  initialize(attrs) {
    modalManagerSingleton.setActiveModal(this, attrs.priority);

    super.initialize(attrs);
    this.class = attrs.class;
    this.title = attrs.title;
    this.message = attrs.message;
    this.closeAction = attrs.closeAction || function() {};
    this.buttons = [];
    this.delay = attrs.delay || 0;
    this.priority = attrs.priority || 0;
    this.closeButton = attrs.closeButton || false;
    for (const button of attrs.buttons || []) {
      _.defaults(button, {
        text: "Button",
        classes: ["normal"],
        action: modal => modal.close(),
      });
      this.buttons.push(button);
    }
    if (this.delay > 0) {
      this.timeout = setTimeout(() => {
        return this.render();
      }, this.delay);
    } else {
      return this.render();
    }
  }

  render() {
    Backbone.trigger("modal-open");
    this.$el.html(this.template());
    this.$el.find("#modal-box").addClass(this.class);
    this.$el.find(".title").html(this.title);
    this.$el.find(".message").html(this.message);
    this.$el.find(".close-button").toggleClass("visible", this.closeButton);

    const buttons = [];
    for (let i = 0; i < this.buttons.length; i++) {
      const button = this.buttons[i];
      buttons.push(`\
<li><button class='button ${button.classes.join(" ")}' data-index=${i}>${button.text}</button></li>\
`);
    }

    const $buttons = this.$(".buttons");
    if (buttons.length) {
      $buttons.html(buttons);
    } else {
      $buttons.hide();
    }
    return this;
  }

  hiClick(event) {
    const index = $(event.target).data("index");
    return this.buttons[index].action(this);
  }

  close() {
    if (this.closeAction() !== false) {
      return this.remove();
    }
  }

  remove() {
    clearTimeout(this.timeout);
    this.undelegateEvents();
    this.$el.html("");
    this.$el = $();
    Backbone.trigger("modal-close");
  }
}

module.exports = Modal;
