const Backbone = require("backbone");
const Header = require("./header");
const Presence = require("./presence");
const Board = require("./board");
const Onboarding = require("./onboarding");
const UrlBar = require("./url_bar");

class Room extends Backbone.View {
  initialize() {
    this.header = new Header({ model: this.model });
    this.presence = new Presence({ model: this.model });
    this.board = new Board({ model: this.model });
    this.onboarding = new Onboarding({ model: this.model });
    this.url_bar = new UrlBar({ model: this.model });
  }
}

module.exports = Room;
