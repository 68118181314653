const { getLength, enableButton, removeAllInvalidStylingsFrom, hideMessage, showMessage, addValidationErrorToInput } = require("../shared/form");

const changePassword = () => {
  const $passwordForm = $("#password-form");
  const $currentPasswordField = $passwordForm.find("[name=current_password]");
  const $passwordField = $passwordForm.find("[name=password]");
  const $confirmPasswordField = $passwordForm.find("[name=confirm_password]");
  const $passwordSubmitButton = $passwordForm.find("#password-continue-button");
  const $passwordConfirmationMessage = $passwordForm.find("#password-confirmation-message");
  const $passwordError = $passwordForm.find("#password-error");
  const $confirmPasswordSubLabel = $passwordForm.find("[id=confirm-password-sub-label]");

  const resetForm = () => {
    hideMessage($passwordConfirmationMessage);
    hideMessage($passwordError);
    hideMessage($confirmPasswordSubLabel);
    removeAllInvalidStylingsFrom($passwordForm);
  };

  const clearInputs = () => {
    $currentPasswordField.val("");
    $passwordField.val("");
    $confirmPasswordField.val("");
  };

  const hiFieldFocus = () => {
    enableButton($passwordSubmitButton);
    resetForm();
  };

  $currentPasswordField.focus(hiFieldFocus);
  $passwordField.focus(hiFieldFocus);
  $confirmPasswordField.focus(hiFieldFocus);

  $passwordSubmitButton.click(event => {
    event.preventDefault();

    var hasErrors = false;

    if (getLength($currentPasswordField) < 1) {
      addValidationErrorToInput($currentPasswordField);
      hasErrors = true;
    }

    if (getLength($passwordField) < 8) {
      addValidationErrorToInput($passwordField);
      hasErrors = true;
    }

    if ($passwordField.val() !== $confirmPasswordField.val()) {
      addValidationErrorToInput($confirmPasswordField);
      showMessage($confirmPasswordSubLabel, "Must match the password");
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    $.ajax({
      url: "/profile",
      method: "patch",
      data: {
        current_password: $currentPasswordField.val(),
        new_password: $passwordField.val(),
      },
      error: () => {
        showMessage($passwordError, "The old password you provided is incorrect");
      },
    }).done(() => {
      clearInputs();
      showMessage($passwordConfirmationMessage, "Your password has been updated");
    });
  });
};

module.exports = changePassword;
