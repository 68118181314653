class Packer {
  constructor() {
    this.fit = this.fit.bind(this);
    this.closestBlock = this.closestBlock.bind(this);
  }

  fit(blocks) {
    const maxX = _.chain(blocks)
      .map(b => b.x + b.w)
      .max()
      .value();
    const colWidth = _.chain(blocks)
      .map(b => b.w)
      .max()
      .value();
    const numCols = Math.floor(maxX / colWidth);

    // first fit all blocks to a column
    const columns = [];
    for (let i = 0; i <= numCols; i++) {
      var block;
      const column = new stickies.utils.packer.Column(i, colWidth, numCols, maxX);
      columns.push(column);
      while ((block = this.closestBlock(blocks, column))) {
        column.fit(block);
      }
    }

    // second compact blocks to fill in empty columns
    //   1. remove empty columns
    //   2. update the column to have the corrent index
    //   3. refit each column's blocks (to update the x value)
    const nonEmpty = _(columns).reject(col => col.isEmpty());
    for (let i = 0; i < nonEmpty.length; i++) {
      nonEmpty[i].i = i;
      nonEmpty[i].refit();
    }

    return blocks;
  }

  closestBlock(blocks, column) {
    const available = _(blocks).filter(block => block.fit == null && column.contains(block));
    if (available.length === 0) {
      return undefined;
    }

    return _(available).min(block => column.distanceToFit(block));
  }
}

module.exports = Packer;
