const _ = require("underscore");
const Backbone = require("backbone");

class MultipleEmailInput extends Backbone.View {
  initialize(attributes = {}) {
    super.initialize(attributes);
    this.listenTo(this.model.emails(), "update", this.render);
    this.listenTo(this.model.emails(), "reset", this.render);
    this.listenTo(this.model, "change:emailInputValue", this.updateEmailInputValue);
  }

  get template() {
    return _.template(`\
      <div class="invitation-email__list"><% _.each(this.model.emails().models, function(email) { %>
        <div class="invitation-email__pill <%= email.get("className") %>">
          <div class="invitation-email__pill-remove" data-id="<%= email.cid %>">×</div>
          <div class="invitation-email__pill-email"><%= email.get("email") %></div>
        </div>
      <% }); %>
      </div>
      <input value="<%= this.model.get('emailInputValue') %>" class='form__control--no-border invitation-email__input' placeholder='Email address'>\
    `);
  }

  get events() {
    return {
      "input .invitation-email__input": "hiRegisterKey",
      "click .invitation-email__pill-remove": "hiRemovePill",
      "paste .invitation-email__input": "hiRegisterPaste",
    };
  }

  hiRegisterPaste(event) {
    // At the time of writing this, the clipboard data API is supported by all browsers
    // See https://developer.mozilla.org/en-US/docs/Web/API/ClipboardEvent/clipboardData#Browser_compatibility
    event.preventDefault();
    const inputValue = event.originalEvent.clipboardData.getData("Text");
    this.model.setEmailInputValueByTextBlob(inputValue);
  }

  updateEmailInputValue() {
    this.$el.find(".invitation-email__input").val(this.model.get("emailInputValue"));
  }

  render() {
    this.$el.html(this.template());
    this._focusInput();
    return this;
  }

  hiRegisterKey(event) {
    const inputValue = event.target.value;
    this.model.setEmailInputValue(inputValue);
  }

  hiRemovePill(event) {
    this.model.removeEmail(event.target.dataset.id);
  }

  _focusInput() {
    this.$el.find("input").focus();
  }
}

module.exports = MultipleEmailInput;
