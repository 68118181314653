const droppable = {
  registry: [],
  hovering: [],

  getElement(target) {
    return this.registry.find($element => $element.get(0) === target);
  },

  getElementsAt(coords, target) {
    return this.registry.filter(function($element) {
      if ($element.get(0) !== target) {
        return $element.bounds().contains(coords);
      }
    });
  },

  removeElement(target) {
    this.registry = this.registry.filter(function($element) {
      const element = $element.get(0);
      if (!document.contains) {
        return element !== target;
      }
      return element !== target && document.contains(element);
    });
  },

  onDrop(event, data) {
    if (!data) {
      return;
    }
    const $element = $(data.target);
    const elements = this.getElementsAt($element.bounds().middle(), data.target);
    elements.sort((a, b) => b.settings.getZ() - a.settings.getZ());

    this.hovering = [];

    if (!(elements.length > 0)) {
      return;
    }
    _(elements)
      .first()
      .settings.onDrop(data.mouseEvent, data.target);
    return _(elements)
      .rest()
      .map(({ settings }) => settings.onBlur(data.mouseEvent, data.target));
  },

  onDrag(event, data) {
    if (!data) {
      return;
    }
    const $element = $(data.target);
    const elements = this.getElementsAt($element.bounds().middle(), data.target);

    const blurring = _(this.hovering).difference(elements);
    blurring.map(({ settings }) => settings.onBlur(data.mouseEvent, data.target));
    this.hovering = elements;

    return elements.map(({ settings }) => settings.onHover(data.mouseEvent, data.target));
  },
};

$(window).on("drag", droppable.onDrag.bind(droppable));
$(window).on("drop", droppable.onDrop.bind(droppable));

$.fn.droppable = function(opts) {
  const $this = this;
  this.addClass("droppable");

  this.get(0).droppable = true;
  this.hovering = [];
  this.settings = $.extend(
    true,
    {
      getZ() {
        return 0;
      },
      onHover() {},
      onBlur() {},
      onDrop() {},
    },
    opts
  );

  droppable.registry.push(this);

  this.on("DOMNodeRemoved", function(event) {
    if (!event.target.droppable) {
      return;
    }
    droppable.removeElement(event.target);
  });

  return $this;
};
