const _ = require("underscore");
const Modal = require("./modal");

class BoardAccessModal extends Modal {
  get events() {
    return {
      "click #modal-backdrop": "close",
      "click .close-button": "close",

      "click .make-public"() {
        this.board.set({ access: "public" });
        this.close();
      },

      "click .make-private"() {
        this.board.set({ access: "private" });
        this.close();
      },
    };
  }

  constructor(...args) {
    super(...args);
    this.render = this.render.bind(this);
  }

  initialize(board, closeAction) {
    closeAction = closeAction || false;
    this.board = board;
    const modalOptions = {
      class: "modal-box--board-access",
      title: this.board.isPrivate() ? "Change to Public" : "Change to Private",
      message: this.board.isPrivate()
        ? "Anyone with the link will be able to join your board.<br/><br/>Are you sure?"
        : "People who aren't already on the board won't have access unless you invite them.<br/><br/>Are you sure?",
      priority: 10,
      closeButton: true,
      buttons: [],
      closeAction,
    };
    super.initialize(modalOptions);
  }

  body() {
    const [current, other] = this.board.isPrivate() ? ["Private", "Public"] : ["Public", "Private"];
    return _.template(`\
      <div class='body'>
        <div class='form__group'>
          <button class='form__button make-${current.toLowerCase()}'              >Keep ${current}</button>
          <button class='form__button make-${other.toLowerCase()} button--primary'>Make ${other}</button>
        </div>
      </div>\
    `);
  }

  render() {
    super.render();
    this.$el.find("#modal-box").append(this.body());
  }
}

module.exports = BoardAccessModal;
